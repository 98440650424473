import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { cart } from 'src/app/shared/modals/cart';
import { ViewCartComponent } from 'src/app/cart/view-cart/view-cart.component'
//import { User } from '../../modals/user';
//import { AccountService } from '../../services/account.service';
import { IndexService } from '../../services/index.service';
import { HttpClient } from '@angular/common/http';
import { CartService } from '../../services/cart.service';
import { CourseService } from 'src/app/courses/services/course.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {

  cartCourse!: cart[];
  apiURL = environment.url
  count!: number
  jQuery: any;

  searchcourse: any = [];
  name: any;
  logged_user: boolean = false;
  Id = this.cryptojsService.decryptLocalUserId()
  cartTotal!: cart;
  totalItem: number = 0;
  cartCousesArray: any = []
  cartSubtotalAmt: number = 0;

  searchCourseList: any = []
  searchInput: any = ''
  searchSubject: Subject<string> = new Subject();

  constructor(public router: Router, private toastr: ToastrService, private indexService: IndexService, private course: CourseService, private http: HttpClient, private cartService: CartService
    , private cryptojsService: CryptojsService, private formBuilder: FormBuilder
  ) {
    this.cartCourses()
  }
  ngOnInit(): void {
    this.callAPIOnSearch()
    this.checkUserLoginOrNot()
    let Id = this.cryptojsService.decryptLocalUserId()
    this.userCartCourses()
  }

  callAPIOnSearch() {
    this.searchSubject
      .pipe(
        debounceTime(400), // Wait 300ms after the last keystroke
        distinctUntilChanged(), // Only proceed if the input value changed
        switchMap((input: string) => {
          if (input) {
            return this.course.searchCourseInstructor(input).pipe(
              catchError(() => of([])) // Handle errors by returning an empty array
            );
          } else {
            return of([]); // If input is empty, return an empty array
          }
        })
      )
      .subscribe((resSearchCourse: any) => {
        if (resSearchCourse.length > 0) {
          this.searchCourseList = resSearchCourse;
        } else {
          this.searchCourseList = [];
        }
      });
  }



  userCartCourses() {
    this.cartService.getCartCourse().subscribe((cart: any) => {
      if (cart.length > 0) {
        this.cartCousesArray = cart
        this.cartSubtotalAmt = this.cartCousesArray.reduce((acc: any, curr: any) => { return acc + (curr.offer_Price * curr.quantity) }, 0)
      } else {
        this.cartCousesArray = []
        this.cartSubtotalAmt = 0;
      }
    });
  }

  checkUserLoginOrNot() {
    this.cryptojsService.userLoginSignal$.subscribe((status: any) => {
      this.logged_user = status
    })
  }

  logout() {
    localStorage.clear()
    this.router.navigate(['index'])
    this.cryptojsService.checkUserLoginOrNot()
    this.cartService.getCartCourseCount()
    this.toastr.success("Successfully Logout")
  }

  cartCourses() {
    this.cartService.getCartCourse().subscribe((resCartItems: any) => {
      if (resCartItems.length > 0) {
        this.totalItem = resCartItems.length
      } else {
        this.totalItem = 0
      }
    })
  }

  removeCourse(course: any) {
    this.cartService.removeCourse(course)
  }

  goViewCart() {
    if (this.cartService.cartCoursePresent()) {
      this.router.navigateByUrl('/view-cart')
    } else {
      this.toastr.warning("Please add at least one course to the cart!")
    }
  }


  onKeyup(event: any) {
    this.searchInput = event.target.value
    if (this.searchInput != null || this.searchInput != '' || this.searchInput != undefined) {
      this.searchSubject.next(this.searchInput);
    } else {
      this.searchCourseList = []

    }

  }


  callSearchCourse() {
    if (this.searchCourseList.length > 0) {
      this.callCourseDetails(this.searchCourseList[0])
    } else {
      this.toastr.warning("Search result not found!")
    }
  }


  callCourseDetails(course: any) {
    if (course.id) {
      this.router.navigateByUrl('/courses/course-details/' + course.id)
      document.getElementById("close")?.click()
      this.searchCourseList = []
      this.searchInput = null
    } else {
      this.toastr.warning("Course not found!")
    }
  }


  closeMobileNav() {
    document.getElementById('mobileNavId')?.click()
  }

}

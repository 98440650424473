
    <!-- Modal Form start -->
    <div class="modal fade" id="booknowModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title" id="exampleModalLabel">Secure Your Slot Now!!</h1>
                    <button type="button" class="close text-primary" data-bs-dismiss="modal"  aria-label="Close">
                        <i class="fas fa-lg fa-times"></i>
                    </button>
                </div>
                <div class="modal-body">
                    <h4>Just enter your information below to secure your spot on this workshop..</h4>
                    <form [formGroup]="webinarslotForm" (ngSubmit)="onSubmit()">
                        <div class="form-label-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name" class="form-control form-control-flush border-dark" id="name"
                                placeholder="Name">
                            <label for="cardName">Your Name</label>
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name is required</div>
                              </div>
                        </div>
                        <div class="form-label-group">
                            <input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email" class="form-control form-control-flush border-dark" id="Email"
                                placeholder="Email" email="true">
                            <label for="cardEmail">Email ID</label>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors?.email">
                                    Email address is not valid</div>
                              </div>
                               
                        </div>
                        <div class="form-label-group">
                            <input type="number" [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }" formControlName="contactNo" class="form-control form-control-flush border-dark" id="contactNo"
                                placeholder="Contact no">
                            <label for="cardEmail">Contact no</label>
                            <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                                <div *ngIf="f.contactNo.errors.required">Contact is required</div>
                                <div *ngIf="f.contactNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                              </div>
                        </div>
                        <div class="form-label-group">
                            <select [ngClass]="{ 'is-invalid': submitted && f.slotId.errors }" formControlName="slotId" name="slotId" id="slotId"
                                class="form-control form-control-flush border-dark select2-hidden-accessible"
                                 tabindex="-1" aria-hidden="true">
                                <option value="">Select Slot</option>
                                <option *ngFor="let c of webinar" value="{{c.id}}">{{c.startDate|date:'d MMMM y'}}</option>
                            </select>
                            <label for="cardEmail">Upcoming Program Schedule</label>
                            <div *ngIf="submitted && f.slotId.errors" class="invalid-feedback">
                                <div *ngIf="f.slotId.errors.required">Select Slot</div>
                              </div>
                        </div>

                        <div class="form-label-group">
                            <!-- <label for="pwd">Captcha:</label> -->

                            <!-- input captcha -->
                            <div class="captcha-code">
                                <re-captcha formControlName="eCaptcha" id="eCaptcha" name="eCaptcha" (resolved)="resolved($event)" siteKey="{{captcha}}"></re-captcha>
                                <div  class="invalid-feedback">
                                  <!-- <div>This field is required.</div> -->
                                </div>
                                <div class="captcha-reload">
                                    <i class="ion-ios-loop-strong"></i>
                                </div>
                            </div>
                            
                        </div>
                        <div class="modal-footer d-block text-center">
                            <!-- <button type="button" class="btn close1 btn-primary" data-bs-dismiss="modal" aria-label="Close"> Close </button> -->
                            <button type="submit"  class="btn btn-primary btn-sm btn-block mw-md-300p">Claim your VIP FREE
                                PASS!</button>
                        </div>

                    </form>
                </div>
                
                <div>
                    <h3 class="text-center">ACT FAST - MASTERCLASS SPOTS FILL UP! </h3>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal Form End -->

    <!-- COURSE
    ================================================== -->
    <section>
        <div class="container">
            <!-- header start -->
            <div class="col-12 text-center my-4">
                <img src="/assets/img/logo.png" alt="Udyami Maharashtra Wholesale Import and Exportl"
                    class="navbar-brand-img">
               
                <h3 class="fw-bold mt-4"> मोफत आयात निर्यात मराठी लाईव्ह वेबिनार By डॉ. ॐकार हरी माळी</h3>
            </div>
            <!-- header end -->
        </div>
    </section>

    <div class=" pb-9 pt-9 pt-md-9 jarallax overlay overlay-secondary overlay-70" data-jarallax data-speed=".8"
        style="background-image: url(assets/img/covers/cover-24.jpg)">
        <div class="container">
            <!-- vide section start -->
            <div class="row">
                <div class="col-lg-12">
                    <h1 class="text-white text-uppercase text-center fw-bold mb-8">
                        Learn How Start Your Import - Export Business and Go International!
                    </h1>
                    <div class="video-container">
                        <iframe src="https://www.youtube.com/embed/e5VDj-Lxaik" title="YouTube video player"
                            frameborder="0" class="responsive-iframe"></iframe>

                    </div>
                </div>
            </div>
            <!-- video section end -->
            <div class="row">
                <!-- other contentr start -->
                <div class="col-lg-12 mb-5 mb-lg-0 position-relative">
                    <h4 class="text-white mt-8 text-center">उद्यमी महाराष्ट्र केवळ प्रशिक्षण देत नाही तर स्वतःचा व्यवसाय
                        उभा करण्यासाठी 'सपोर्ट' देखील देते. उद्यमी महाराष्ट्रच भाग व्हा, अभ्यासपूर्ण प्रशिक्षण घ्या आणि
                        स्वतःचा व्यवसाय सुरू करा!</h4>
                   
                    <div class="text-center">
                        <a class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1  btn-block mw-md-450p  webinar launchModal1"
                            data-aos="zoom-in" data-wow-delay=".1s" data-bs-toggle="modal"
                            data-bs-target="#booknowModal">CLAIM YOUR VIP PASS FOR FREE <span> VALUED AT RS.999/- 100%
                                FREE</span></a>

                    </div>
                    <div class="text-center text-white mt-5">
                        <h3 class="text-white">Sunday 8th May, 2022 | 06:30 PM </h3>
                        <p> (Link to Participate will be sent to your registered email address) </p>
                    </div>
                </div>

                <!-- other content end -->
            </div>
        </div>
    </div>
<!-- countdonw start -->
    <section class="py-6 py-md-4 bg-light">
        <div class="container mb-8 mt-8">

            <div class="row">
                <div class="col-12">
                    <h2 class="text-center fw-bold">FREE Live Online Masterclass</h2>
                    <h5 class="text-center">FREE REGISTRATIONS CLOSING IN...</h5>
                    <div class="text-center">
                        <div id="timer"></div>
                    </div>

                </div>
            </div>
        </div>
    </section>
    <!-- countown end -->
<!-- Program Schedule start -->
    <section class="py-6 py-md-2 ">
        <div class="container mb-2 mt-1">
            <div class="row">
                <div class="col-12 col-lg-6">

                    <img class="img-fluid mb-6 mb-md-0 aos-init aos-animate" src="/assets/img/webinar/omkar.png" alt="">
                    <h3 class="text-center fw-bold mt-4">Hosted By</h3>
                    <h4 class="text-center ">Dr. Omkar Hari Mali | Entrepreneur | Import- Export Business Coach</h4>

                </div>
                <div class="col-12 col-lg-6 mt-9">
                    <h2 class="my-5 fw-bold text-center">Upcoming Program Schedule!</h2>
                    <table class="table table-striped webinar-table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Date</th>
                                <th scope="col">Time</th>
                                <th scope="col">Book Now</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let c of webinar">
                                <th scope="row">1</th>
                                <td>{{c.startDate|date:'mediumDate'}}</td>
                                <td>{{c.startDate|date:'shortTime'}} To {{c.endDate|date:'shortTime'}}</td>
                                <td><a class="launchModal1" data-bs-toggle="modal" data-bs-target="#booknowModal" style="cursor: pointer;">Book Now</a>
                                </td>
                            </tr>                         
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </section>
<!-- Program Schedule end -->


    <!-- LAST 50 SPOTS AVAILABLE
    ================================================== -->
    <section class="bg-light py-4">
        <div class="container mb-8 mt-8">
            <div class="row">
                <h1 class="text-center fw-bold">
                    What You Will Learn
                </h1>
            </div>
            <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-3 row-cols-lg-3 mb-4">
            <ng-container *ngFor="let c of courses;let i=index">
                <div class="col-md mb-6" data-aos="fade-in">
                    <!-- Card -->
                    <div class="card border shadow p-2 lift sk-fade">
                        <!-- Image -->
                        <div class="card-zoom position-relative">
                            <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="card-img d-block sk-thumbnail img-ratio-3">
                                <img class="rounded shadow-light-lg" src="{{apiURL}}/{{c.bannerImg}}" alt="..."></a>
                          
                        </div>

                        <!-- Footer -->
                        <div class="card-footer px-2 pb-0 pt-4">
                            <!-- Heading -->
                            <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="d-block">
                                <h4 class="line-clamp-2 h-48 h-lg-52">{{c.categoryName}}</h4>
                            </a>
                            <p>{{c.name}}
                            </p>
                        </div>
                    </div>
                </div>      
            </ng-container>   
            </div>

            <div class="text-center">
                <a class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1  btn-block mw-md-450p  webinar launchModal"
                    data-aos="zoom-in" data-wow-delay=".1s" data-bs-toggle="modal" data-bs-target="#booknowModal">CLAIM YOUR
                    VIP PASS FOR FREE <span> VALUED AT RS.999/- 100% FREE</span></a>
            </div>

        </div>
    </section>

    <!-- LAST 50 SPOTS AVAILABLE end
    ================================================== -->

    <!-- about us start -->
    <section class="py-6 py-md-8 ">
        <div class="container">

            <div class="row align-items-center">
                <h1 class="text-center fw-bold mb-8">Meet Your Trainer & Host</h1>
                <div class="order-2 col-lg-7 order-md-2 col-wd-8 order-lg-1 aos-init aos-animate" data-aos="fade-right">
                    <p>“जीवनात ठरवलेले ध्येय साध्य करण्यासाठी कौशल्य आणि संयम असावा लागतो. या दोहोंचा संगम करून त्याला
                        अपार मेहनतीची साथ देत उद्योग क्षेत्रात एक यशस्वी मराठी व्यावसायिक म्हणून ज्यांनी आपल्या कक्षा
                        रुंदावल्या असे प्रोत्साहनात्मक व्यक्तिमत्व म्हणजे ठाणे येथील डॉ. ओमकार हरी माळी होय.</p>
                    <p>लंडन येथील (IHMES International Business School) ‘एमबीए’ केल्यानंतर त्यांनी ‘इंटरनॅशनल बिझनेस’ या
                        विषयातून डॉक्टरेट पदवी प्राप्त केली.
                    </p>
                    <p>ते म्हणजे “आपल्या महाराष्ट्रातील नागरिकांना स्वतःचा व्यवसाय करण्यासाठी प्रोत्साहित करणं.”
                        इम्पोर्ट एक्स्पोर्ट उद्योगात आपला किर्तीदायक जम बसवलेले डॉ. ॐकार हरी माळी हे मेडिकल कोलॅबोरेटर
                        म्हणून आज जगातील दुबई, बांगलादेश, उझबेकिस्तान, कझाकिस्तान आणि युक्रेन यासारख्या इतर एकूण सात
                        देशांमध्ये उद्योग करत आहेत. तर महाराष्ट्रातील विविध शहरांमध्ये ते ‘ई-कॉमर्स’ क्षेत्रात कार्यरत
                        आहेत. </p>
                    <p> मराठी तरुण तरुणींना उद्योगासाठी आवश्यक प्रशिक्षण आणि व्यवसाय यांचे मार्गदर्शन करून
                        महाराष्ट्राच्या सर्वांगीण वाढीसाठी करण्याचे ठरवले आणि याच उद्देशाने डॉ. ॐकार हरी माळी यांनी १ मे
                        २०२० रोजी ‘उद्यमी महाराष्ट्र’ या उपक्रमाची निर्मिती केली. </p>
                    <p>
                        डॉ. ओमकार हरी माळी हे ‘मराठी माणूस व्यवसाय करू शकत नाही’ या प्रचलित वाक्याला खोटे ठरवून ‘उद्यमी
                        महाराष्ट्र’ या मोहिमेअंतर्गत सध्या अनेक यशस्वी मराठी उद्योजक घडवत आहेत.
                    </p>
                    <p>पुढच्या वर्षभरात 10,00000 उद्यमींना प्रशिक्षण देऊन त्यांना व्यवसायाच्या मुख्य प्रवाहात आणण्याचे
                        ध्येय डॉ. ओमकार हरी माळी यांनी उराशी बाळगलेले आहे</p>
                    <p>So My Friend Let's Meet Live! </p>




                </div>
                <div class="order-1 col-lg-5 order-lg-2  col-wd-4 order-md-1 mb-6 mb-lg-0 text-center">
                    <a href="assets/img/photos/omkar-mali.png" data-fancybox="" data-width="459" data-height="543">
                        <!-- Image -->
                        <img src="assets/img/photos/omkar-mali.png" class="img-fluid rounded-lg aos-init aos-animate"
                            data-aos="fade-left" alt="..."></a>
                    <h3 class="font-size-xxl fw-bold mb-4 mt-4 ">डॉ. ॐकार हरी माळी</h3>
                </div>
            </div>

            <div class="row">

                <h3 class="text-center fw-bold my-7">उद्यमी महाराष्ट्रच भाग व्हा, अभ्यासपूर्ण प्रशिक्षण घ्या आणि स्वतःचा
                    व्यवसाय सुरू करा!</h3>
                <div class="text-center">


                    <a class="btn text-white mb-4 mb-md-0 ms-md-3 flex-grow-1  btn-block mw-md-450p  webinar launchModal"
                        data-aos="zoom-in" data-wow-delay=".1s" data-bs-toggle="modal" data-bs-target="#booknowModal">CLAIM
                        YOUR VIP PASS FOR FREE <span> VALUED AT RS.999/- 100% FREE</span></a>
                </div>

            </div>
        </div>


    </section>

    <!-- about us end -->

    <!-- FOOTER
    ================================================== -->
    <footer class="pt-8 pt-md-1 bg-light">
        <div class="container">
            <div class="row" id="accordionFooter">

                <div class="col-12">
                    <div class="pb-5 pt-6 text-center ">
                        <img src="/assets/img/logo.png" alt="" class="img-fluid mb-4 h-130p">
                        <p class="text-gray-800 font-size-sm-alone px-3 px-md-0 ">
                            2021 | UDYAMII.COM | ALL RIGHTS RESERVED | <a
                                href="https://workshop.udyamii.com/privacy-policy1634710294774" target="_blank"
                                rel="noopener noreferrer"> PRIVACY POLICY </a> | <a
                                href="https://www.udyamii.com/contact-us/" target="_blank"
                                rel="noopener noreferrer">CONTACT </a>

                        </p>
                        <p class="text-gray-800 font-size-sm-alone px-3 px-md-0 ">This site is not a part of the
                            Facebook website or Facebook Inc. Additionally, This site is NOT endorsed by Facebook in any
                            way. FACEBOOK is a trademark of FACEBOOK, Inc.</p>
                        <p class="text-gray-800 font-size-sm-alone px-3 px-md-0 ">Please be advised that revenues and
                            results shown are extraordinary and are not intended to serve as guarantees. In fact, as
                            stipulated by law, we can not and do not make any guarantees about your ability to get
                            results or earn any money with our ideas, information, tools or strategies. We don’t know
                            you and, besides, your results in life are up to you. Agreed? We just want to help you by
                            giving great content, direction and strategies that worked well for us and our students and
                            that we believe can move you forward. All of our terms, privacy policies and disclaimers for
                            this program and website can be accessed via the link above. We feel transparency is
                            important and we hold ourselves (and you) to a high standard of integrity. Thanks for
                            stopping by. We hope this training and content brings you a lot of value.</p>

                    </div>
                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </footer>
 
    
  
 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Expert } from '../modals/expert';
import { userForm } from '../modals/userForm';
import { checkout } from 'src/app/cart/modal/checkout';
import { IBCPayment } from 'src/app/ibc/modals/IBCPayment';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';


@Injectable({
  providedIn: 'root'
})
export class IndexService {

  url = environment.url;
  constructor(private http: HttpClient, private cryptojsService:CryptojsService) { }

  getCourse(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/main-courses').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting course'))),
    );
  }

  getCategory(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/course-category').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting category'))),
    );
  }

  getCategoryCourse(Id: any) {
    const baseurl = `${this.url}/api/app/i-bCEquiry/category-wise-courses/${Id}`;
    return this.http.get(baseurl);
  }

  getExpertTeam(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/expert-team').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting course'))),
    );
  }

  getExpert(Id: any) {
    const baseurl = `${this.url}/api/app/expert-team/${Id}`;
    return this.http.get(baseurl);
  }

  getIbc(username: any) {
    const baseurl = `${this.url}/GetIBC?Username=${username}`;
    return this.http.get(baseurl);
  }

  IbcThrough(user: userForm) {
    return this.http.post(`${this.url}/api/app/i-bCEquiry`, user);
  }

  postAdmissionForm(user: userForm) {
    return this.http.post(`${this.url}/api/app/admission-form`, user);
  }

  admissionFormStatus(userId: userForm) {
    return this.http.post(`${this.url}/api/app/admission-form/has-submitted-admission-form/${userId}`,{});
  }

  getTestimonials(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/testimonial?sorting=&skipCount=0&maxResultCount=10').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting course'))),
    );
  }

  getApprovedTestimonials(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/testimonial/approved-list').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting course'))),
    );
  }

  getWebinarDetails(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/zoom-meet').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting webinar details'))),
    );
  }

  sendMail(sendmail: any) {

    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    let body = new URLSearchParams();
    body.set('email', sendmail.sendmail);

    return this.http.post(`${this.url}` + '/api/app/main-courses/send-email', body.toString(), options).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in sending mail'))),

    );
  }

  zoomMeet(): Observable<any> {
    return this.http.get(`${this.url}/api/app/zoom-meet`).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Zoom meeting Details'))),
    );
  }

  moveToWishlist(course_id: any, client_Id: any, Flag: any) {
    return this.http.delete(`${this.url}/api/app/add-to-cart/cart-items?Id=${course_id}&Client_Id=${client_Id}&Flag=${Flag}`).pipe(tap(res => res),
      catchError(err => throwError(new Error('Something went wrong'))),
    );
  }

  moveToCart(course_id: any, client_Id: any, Flag: any) {
    return this.http.delete(`${this.url}/api/app/add-to-cart/cart-items?Id=${course_id}&Client_Id=${client_Id}&Flag=${Flag}`).pipe(tap(res => res),
    catchError(err => throwError(new Error('Something went wrong'))),
  );
  }

  myWishlist(Id: any):Observable<any>{
    return this.http.get(`${this.url}/api/app/wish-list/wishlist/${Id}`).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Wishlist'))),
    );
  }

  removeFromCart(course_id: any, client_Id: any, Flag: any) {
    return this.http.delete(`${this.url}/api/app/add-to-cart/cart-items?Id=${course_id}&Client_Id=${client_Id}&Flag=${Flag}`,{responseType: 'text'}).pipe(tap(res => console.log(res)),
    );
  }

  removeWishlist(course_id: any, client_Id: any, Flag: any) {
    return this.http.delete(`${this.url}/api/app/add-to-cart/cart-items?Id=${course_id}&Client_Id=${client_Id}&Flag=${Flag}`,{responseType: 'text'}).pipe(tap(res => console.log(res)),
    );
  }
  
  search(name: any): Observable<any> {
    return this.http.get(`${this.url}/api/app/main-courses/search-course?name=${name}`).pipe(tap(res => res),
      catchError(err => throwError(new Error('Something went wrong'))),
    );
  }

  getExpertDetails(id: any): Observable < Expert[] >{
    return this.http.get < Expert[] > (`${this.url}/api/app/expert-team/${id}`);  
  }

  contactUs(data:any){
    return this.http.post(`${this.url}`+'/api/app/contact-form',data).pipe(tap(res => res),
      catchError(err => throwError(new Error('Something went wrong'))),
    );
  }

  WebinarRegister(name:any,email:any,contactNo:any,slotId:any){
  
    var data = {
      "name": name,
      "email": email,
      "contactNo": contactNo,
      "slotId": slotId,
      
    };
    return this.http.post(`${this.url}`+'/api/app/webinar_Registration',data).pipe(tap(res => res),
      catchError(err => throwError(new Error('Something went wrong'))),
    );
  }

  checkout(checkout:checkout){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
    };
    return this.http.post(`${this.url}`+'/api/app/payment_Data/payment',checkout, options).pipe(tap(res => res),
     
    );
  }

  Ibcpay(IbcPayment:IBCPayment){
  
    return this.http.post(`${this.url}`+'/api/app/i-bC_Pay-now',IbcPayment).pipe(tap(res => res),
     
    );
  }

  onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

}

   
    <!-- PAGE TITLE
    ================================================== -->
    <header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax
        data-speed=".8" style="background-image: url(assets/img/covers/cover-19.jpg);">
        <div class="container-fluid text-center ">
            <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">BLOG</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-scroll justify-content-center py-1 ">
                    <li class="breadcrumb-item">
                        <a class="text-white" href="#">
                            Home
                        </a>
                    </li>
                    <li class="breadcrumb-item text-white active" aria-current="page">
                        Blog
                    </li>
                </ol>
            </nav>
        </div>
        <!-- Img -->

    </header>


   <!-- BLOG GRID V1
    ================================================== -->
    <div class="container-fluid mb-8 mt-8">
        <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-4">
            <ng-container *ngFor="let b of blogs;let i=index"> 
            <div class="col-md mb-6" data-aos="fade-in">
                <!-- Card -->
                <div class="card border shadow p-2 lift sk-fade">
                    <!-- Image -->
                    <div class="card-zoom position-relative">
                        <a [routerLink]="['/blog-details/'+ b.id.toString() +'']" class="card-img d-block sk-thumbnail img-ratio-3"><img class="rounded shadow-light-lg img-fluid" src="assets/img/post/post-1.jpg" alt="..."></a>

                        <!-- <a [routerLink]="['/blog-details/'+ b.id.toString() +'']" class="badge sk-fade-bottom badge-lg badge-primary badge-pill badge-float bottom-0 left-0 mb-4 ms-4 px-5 me-4">
                            <span class="text-white fw-bold fw-normal font-size-sm">Export</span>
                        </a> -->
                    </div>

                    <!-- Footer -->
                    <div class="card-footer px-2 pb-0 pt-4">
                        <ul class="nav mx-n3 mb-3">
                            <li class="nav-item px-3">
                                <a [routerLink]="['/blog-details/'+ b.id.toString() +'']" class="d-flex align-items-center text-gray-800">
                                    <div class="me-3 d-flex">
                                        <!-- Icon -->
                                       <i class="far fa-user"></i>
                                    </div>
                                    <div class="font-size-sm">{{b.authorName}}</div>
                                </a>
                            </li>
                            <li class="nav-item px-3">
                                <a [routerLink]="['/blog-details/'+ b.id.toString() +'']" class="d-flex align-items-center text-gray-800">
                                    <div class="me-2 d-flex">
                                        <!-- Icon -->
                                       <i class="far fa-calendar-check"></i>

                                    </div>
                                    <div class="font-size-sm">{{b.creationTime | date:'mediumDate'}}</div>
                                </a>
                            </li>
                        </ul>

                        <!-- Heading -->
                        <a [routerLink]="['/blog-details/'+ b.id.toString() +'']" class="d-block"><h5 class="line-clamp-2 h-48 h-lg-52">{{b.title}}</h5></a>
                    </div>
                </div>
            </div> 
            </ng-container>          
        </div>

       <!-- PAGINATION
                ==================================================-->
                <!-- <nav class="mt-2" aria-label="Page navigationa">
                    <ul class="pagination justify-content-center">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true"><i class="fas fa-arrow-left"></i></span>
                            </a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item active"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true"><i class="fas fa-arrow-right"></i></span>
                            </a>
                        </li>
                    </ul>
                </nav> -->
    </div>
   
     
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>
  
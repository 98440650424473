  <!-- PAGE TITLE
    ================================================== -->
    <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="breadcrumb-main-wrapper">
                                <h1 class="title">Terms & Conditions</h1>
                                <div class="pagination-wrapper">
                                    <a routerLink="/index">Home</a>
                                    <i class="fa-regular fa-chevron-right"></i>
                                    <a class="active" href="Instructor-2.html">Terms & Conditions</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="breadcrumb-img-book">
                                <img src="\assets\images\breadcrumb\book.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <main class="terms-container">
        <section class="terms-header">
            <h2>Terms and Conditions</h2>
            <p class="effective-date">Effective Date: <strong>[29/10/2024]</strong></p>
            <p class="intro">These Terms and Conditions govern your use of our website and services. By accessing our site, you agree to these terms.</p>
        </section>

        <!-- Terms Sections in Rows -->
        <div class="terms-row">
            <h3>1. Acceptance of Terms</h3>
            <p>By using this website, you accept these Terms and Conditions. If you do not agree, please do not use our services.</p>
        </div>

        <div class="terms-row">
            <h3>2. Use of the Website</h3>
            <p>Our site and its content are intended for personal, educational use. Unauthorized commercial use is prohibited.</p>
        </div>

        <div class="terms-row">
            <h3>3. Intellectual Property</h3>
            <p>All content, including courses, materials, and design elements, are owned by us and protected by intellectual property laws.</p>
        </div>

        <div class="terms-row">
            <h3>4. User Conduct</h3>
            <p>Users agree not to use our site to post harmful, unlawful, or otherwise objectionable content. We reserve the right to remove such content.</p>
        </div>

        <div class="terms-row">
            <h3>5. Limitation of Liability</h3>
            <p>We are not responsible for any damages or losses resulting from your use of our website, to the extent permitted by law.</p>
        </div>

        <div class="terms-row">
            <h3>6. Termination</h3>
            <p>We reserve the right to terminate your access to our site at our discretion if you violate these Terms and Conditions.</p>
        </div>

        <div class="terms-row">
            <h3>7. Changes to Terms</h3>
            <p>We may update these Terms and Conditions periodically. Any changes will be posted on this page with an updated effective date.</p>
        </div>

        <footer class="terms-footer">
            <p>For questions, please contact us at <a href="mailto:antarkarsir1@gmail.com">antarkarsir1@gmail.com</a>.</p>
        </footer>
    </main>
import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ChatService {
  constructor() { }

  
}
<footer style="background-color: #6885ee59;" class="pt-8 pt-md-8 ">
    <div class="container">
        <div class="row" id="accordionFooter">
            <div class="col-12 col-md-4 col-lg-4">

                <!-- Brand -->
                <div class="mx-auto">
                    <img src="assets/images/Antarkar%20Sir_s%20logo%20color.png" alt="Udyami Maharashtra"
                        class="footer-brand img-fluid mb-4 h-130p">
                </div>
                <!-- Text -->
                <p class="text-dark mb-2 font-size-sm-alone">
                    We are passionate about education and dedicated to providing high- quality learning for aspiring
                    JEE, BITSAT, IAT, MHT CET students
                </p>



                <!-- Social -->
                <ul class="list-unstyled list-inline list-social mb-4 mb-md-0">
                    <li class="list-inline-item list-social-item">
                        <a href="https://www.facebook.com/activeneuronstechnology" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <!-- <a [href]="getWhatsAppLink()" target="_blank"><i class="fa-brands fa-whatsapp" style="font-size: 30px;"></i></a> -->
                    </li>
                    <li class="list-inline-item list-social-item">
                        <a href="https://x.com/i/flow/login?redirect_after_login=%2FANTLLP" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li class="list-inline-item list-social-item">
                        <a href="https://www.instagram.com/ant_llp/" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li class="list-inline-item list-social-item">
                        <a href="https://www.linkedin.com/in/active-neurons-technology-llp-270860263/" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div style="margin-top: 60px;" class="col-12 col-md-2 col-lg-2 pb-5  mb-respon">
                <div class="mb-5 mb-xl-0 footer-accordion">

                    <!-- Heading -->
                    <div id="widgetOne">
                        <h5 class="mb-5 ">
                            <h5 class="mb-0"> Explore </h5>
                        </h5>
                    </div>

                    <div id="widgetcollapseOne" class="collapse show text-dark" aria-labelledby="widgetOne"
                        data-parent="#accordionFooter">
                        <!-- List -->
                        <ul class="list-unstyled text-dark font-size-sm-alone mb-6 mb-md-8 mb-lg-0 ">
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/index']" class="text-reset ">
                                    Home
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/about-us']" class="text-reset">
                                    About Us
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/courses']" class="text-reset">
                                    Course
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/contact-us']" class="text-reset">
                                    Contact Us
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/faq']" class="text-reset">
                                    FAQ
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/why-us']" class="text-reset">
                                    Why Us
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/privacy-policy']"
                                    class="text-reset">
                                    Privacy Policy
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/terms-and-conditions']"
                                    (click)="findToaster()" class="text-reset">
                                    Terms & Conditions
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>



            <div style="margin-top: 60px;" class="col-12 col-md-4 col-lg-4 mb-respon">

                <div class="mb-5 mb-xl-0 ms-xl-6 footer-accordion">





                    <p class="mb-5 font-size-sm text-dark"><i style="color: #553cdf;"
                            class="fa-regular fa-location-dot"></i> Vile Parle, Mumbai, India</p>
                    <p class="mb-5 font-size-sm text-dark"><i style="color: #553cdf;"
                            class="fa-regular fa-phone mt-3"></i> +91 8828228330 </p>
                    <div class="mb-2">
                        <a href="mailto:support@udyami.com" class="text-dark font-size-sm-alone"> <i
                                style="color: #553cdf;" class="fa-regular fa-envelope"></i> antarkarsir1@gmail.com</a>
                    </div>

                </div>
            </div>

        </div> <!-- / .row -->
    </div> <!-- / .container -->
    <div class="copyright-area-one-border mt-5">
        <div class="container">



            <div class="row">

                <div class="col-lg-12">
                    <div class="copyright-area-one">
                        <p style="color: #000;">Copyright © 2024 All Rights Reserved by Antarkar Sir’s Educative
                            Programmes
                        </p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Modal -->
<div class="modal fade" id="appointmentModal" tabindex="-1" aria-labelledby="appointmentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 800px;">
        <div class="modal-content" style="border-radius: 12px;">
            <div class="modal-header">
                <h5 class="modal-title" id="appointmentModalLabel">Schedule Appointment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form>
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="nameInput" class="form-label">Name</label>
                            <input type="text" class="form-control" id="nameInput" placeholder="Enter your name"
                                required>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="emailInput" class="form-label">Email ID</label>
                            <input type="email" class="form-control" id="emailInput" placeholder="Enter your email"
                                required>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="mobileInput" class="form-label">Mobile No</label>
                            <input type="tel" class="form-control" id="mobileInput"
                                placeholder="Enter your mobile number" required>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="counsellingSelect" class="form-label">Counselling
                                Type</label>
                            <select class="form-select" id="counsellingSelect" required>
                                <option value="" disabled selected>Select counselling type</option>
                                <option value="individual">Preparation Counselling</option>
                                <option value="group">Admission Counselling</option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class=" rts-btn btn-border">Save changes</button>
            </div>
        </div>
    </div>
</div>

<style>
    .modal-content {
        border-radius: 12px;
        /* Rounded corners */
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        /* Subtle shadow for depth */
    }

    .modal-header {

        border-bottom: 1px solid #e9ecef;
        /* Border below header */
    }

    .modal-title {
        font-weight: bold;
        /* Bold title */
    }

    .form-label {
        font-weight: 600;
        /* Bold labels for better readability */
    }

    .form-control {
        border: 1px solid #ced4da;
        /* Custom border color */
        border-radius: 8px;
        /* Slightly rounded corners */
        transition: border-color 0.3s ease;
        /* Smooth transition on focus */
    }

    .form-control:focus {
        border-color: #80bdff;
        /* Focused border color */
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        /* Focus shadow */
    }

    .btn {
        border-radius: 20px;
        /* Rounded button corners */
    }

    input[type=text],
    input[type=password],
    input[type=email],
    input[type=number],
    input[type=tel],
    textarea {
        font-size: var(--font-size-b2);
        font-weight: 400;
        height: auto;
        line-height: 28px;
        background: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 10px 15px !important;
        outline: none;

        border-radius: var(--radius);
        /* -- Placeholder -- */
    }

    .form-select {
        font-size: 17px !important;
        padding: 11px 15px !important;
    }
</style>


<div class="modal fade" id="resetPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Reset Password</h5>
                <button id="resetPasswordID" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close" (click)="setOTPFlag()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitAdmission()">
                    <div class="mb-3">
                        <label for="emailOrPassword" class="form-label">Email or Phone Number</label>
                        <input type="text" id="emailOrPassword" class="form-control" formControlName="emailorphonenumber"
                            placeholder="Enter your email or password" />
                        <div *ngIf="resetPasswordForm.get('emailorphonenumber')?.invalid && resetPasswordForm.get('emailorphonenumber')?.touched"
                            class="text-danger">
                            Please enter a valid email or phone number
                        </div>
                    </div>
                    <button *ngIf="!showOtpInput" type="button" class="btn btn-primary"
                        (click)="showResetPasswordForm()">Send OTP</button>
                    <div class="mb-3" *ngIf="showOtpInput">
                        <label for="otp" class="form-label">OTP</label>
                        <input type="text" id="otp" class="form-control" formControlName="otp"
                            placeholder="Enter your OTP" maxlength="4" />
                        <div *ngIf="resetPasswordForm.get('otp')?.invalid && resetPasswordForm.get('otp')?.touched"
                            class="text-danger">
                            OTP is required
                        </div>
                    </div>

                    <button type="submit" *ngIf="showOtpInput" class="btn btn-primary"
                        [disabled]="resetPasswordForm.invalid">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>


<button type="button" data-bs-toggle="modal" data-bs-target="#confirmPasswordModal" style="display: none;"
    id="confirmPasswordID">Set New Password</button>
<div class="modal fade" id="confirmPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Set New Password</h5>
                <button id="resetPasswordID" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="confirmPasswordForm" (ngSubmit)="onConfirmSubmit()">
                    <div class="mb-3">
                        <label for="emailOrPassword" class="form-label">Password</label>
                        <input type="text" id="emailOrPassword" class="form-control" formControlName="password"
                            placeholder="Enter password" />
                        <div *ngIf="confirmPasswordForm.get('password')?.invalid && confirmPasswordForm.get('password')?.touched"
                            class="text-danger">
                            Please enter password
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="otp" class="form-label">Confirm Password</label>
                        <input type="text" id="otp" class="form-control" formControlName="confirmpassword"
                            placeholder="Enter your OTP" maxlength="4" />
                        <div *ngIf="confirmPasswordForm.get('confirmpassword')?.invalid && confirmPasswordForm.get('confirmpassword')?.touched"
                            class="text-danger">
                            Confirm password is required
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary"
                        [disabled]="confirmPasswordForm.invalid">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  app_name = 'videostream';
  registerForm!: FormGroup;
  loading = false;
  submitted = false;
  checkboxChecked: boolean = false;
  checkpassword:boolean=false;
  constructor(private formBuilder: FormBuilder, private accountservice: AccountService,
    private alertservice: AlertService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.createForm()
  }

  createForm() {
    this.registerForm = this.formBuilder.group({
      name: [null, Validators.required],
      surname: ['NA', Validators.required],
      username: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      emailAddress: [null,  [Validators.required,  Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      password: [null, [Validators.required]],
      appname: ['anterkarsir']
    });
  }

  onCheckboxChange(event: any) {
    this.checkboxChecked = event.target.checked
  }



  onSubmit() {
    this.submitted = true;
    this.registerForm.value.appname = this.app_name;
    this.registerForm.controls['username'].setValue(this.registerForm.value.phoneNumber.toString());
    this.registerForm.controls['surname'].setValue(this.registerForm.value.name.toString());
    if (!this.checkboxChecked) {
      this.toastr.info('Please Checked Accept the Terms and Privacy Policy');
      return
    }
    // stop here if registerForm is invalid
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched()
      return;
    }
    this.loading = true;
    this.accountservice.register(this.registerForm.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.toastr.success('Registration Successful');
          this.router.navigate(['../login'], { relativeTo: this.route });

        },
        error: error => {
          if (error) {
            this.toastr.error(error.error.error.message);
          }
        }
      });
  }

  displayPassWord(){
    this.checkpassword = !this.checkpassword
  }

}

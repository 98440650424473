import { Component, HostListener, NgZone, OnInit } from '@angular/core';
import { cart, mainCourse } from 'src/app/shared/modals/cart';
import { IndexService } from 'src/app/shared/services/index.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { CourseService } from 'src/app/courses/services/course.service';
import { checkout, courses } from '../modal/checkout';
import { AccountService } from 'src/app/shared/services/account.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { CartService } from 'src/app/shared/services/cart.service';
import { environment } from 'src/environments/environment';
declare var Razorpay: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  Id = this.cryptojsService.decryptLocalUserId()
  checkoutCourse!: cart[];
  Math: any;
  paymentId = "";
  error = "";
  checkoutForm!: FormGroup;
  submitted = false;
  options = {
    "key": "rzp_test_7yKRj2DDfmfz8e",
    "amount": "100",
    "name": "Sagar",
    "description": "",
    "image": "",
    "order_id": "",
    "handler": function (response: any) {
      var event = new CustomEvent("payment.success",
        {
          detail: response,
          bubbles: true,
          cancelable: true
        }
      );
      window.dispatchEvent(event);
    },
    "prefill": {
      "name": "",
      "email": "",
      "contact": ""
    },
    "notes": {
      "address": ""
    },
    "theme": {
      "color": "#3399cc"
    }
  };
  getCartArray: any = []
  coursesSubtotal: number = 0;
  coursesTotal: number = 0;
  gstPercentage: number = 18;
  gstCharge: number = 0;
  razorpayKey = environment.razorpaykey;
  objInsert = { id: 'vishadlasdasdsad' }
  apiURL = environment.url
  cartCousesArray: any = []


  

  constructor(private router: Router, private course: CourseService, private accountservice: AccountService,
    private indexService: IndexService, private toastr: ToastrService, private formBuilder: FormBuilder,
    private cryptojsService: CryptojsService, private cartService: CartService,private ngZone: NgZone
  ) {
    this.getCartCourses()
  }

 

  ngOnInit(): void {

    this.Math = Math;


    this.createCheckoutForm()
    // this.getCartCourses()
    this.userLoginUserData()
    this.loadRazorpayScript()
    this.userCartCourses()
  }

  loadRazorpayScript() {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }

  async paynow() {
    this.checkoutForm.controls['totalOrderAmount'].setValue(this.coursesTotal.toString())
    this.checkoutForm.controls['paymentMode'].setValue('Online')
    this.submitted = true;
    if (this.checkoutForm.invalid) {
      return;
    }
    this.paymentId = '';
    this.error = '';
    this.options.amount = (parseInt(this.coursesTotal.toString()) * 100).toString() //paise * 100 
    this.options.name = this.checkoutForm.value.firstName + ' ' + this.checkoutForm.value.lastName;
    this.options.prefill.email = this.checkoutForm.value.email;
    this.options.prefill.contact = this.checkoutForm.value.phoneNumber;
    const newArray = this.getCartArray.map((item: any) => ({
      course_Id: item.id,
      offer_Percentage: item.offer_Percentage,
      actual_Price: item.actual_Price,
      offer_Price: item.offer_Price
    }));
    const body = {
      ...this.checkoutForm.value,
      courses: newArray
    }
    this.indexService.checkout(body).subscribe(async (res: any) => {
      if (res.status) {
        this.objInsert.id = res.data.id
        let dataOrder = {
          amount: res.data.totalOrderAmount * 100,
          currency: "INR",
          receipt: "ANTARKAR SIR'S",
          notes: { "orderNumber": 'ASIR' },
        };
        try {
          const resrazorpayOrder = await this.course.razorpayOrder(dataOrder).toPromise();
          if (resrazorpayOrder.id) {
            const RozarpayOptions = {
              description: 'Thank You For Placing Order',
              currency: 'INR',
              name: 'ANTARKAR SIRS',
              key: this.razorpayKey,
              image: './assets/images/logo/favicon.png',
              order_id: resrazorpayOrder.id,
              handler: async (respon: any) => {
                try {
                  const result = await this.handleRazorpayResponse(respon, resrazorpayOrder.id, this.objInsert);
                  let getOrderRes: any = result.msg;

                  if (getOrderRes.isValid) {
                    localStorage.removeItem('cart');
                    this.cartService.getCartCourseCount();
                    this.toastr.success("Order placed successfully...");
                    // Navigate here after successful order processing
                    // this.router.navigate(['/order-success'], { replaceUrl: true });
                    this.ngZone.run(() => {
                      this.router.navigate(['/order-success'], { replaceUrl: true });
                    });
                  } else {
                    this.toastr.warning(getOrderRes);
                  }
                } catch (error) {
                  this.toastr.error("Error processing your order.");
                }
              },
              prefill: {
                name: this.checkoutForm.value.phoneNumber,
                email: this.checkoutForm.value.email,
                phone: this.checkoutForm.value.phoneNumber,
              },
              theme: {
                color: '#6466e3',
              },
              modal: {
                ondismiss: () => {
                  alert("If you have any query regarding order place, please contact us at- 8828228330 ")
                },
              },
            };

            Razorpay.open(RozarpayOptions);
            return new Promise((resolve, reject) => {
              RozarpayOptions.handler = async (respon: any) => {
                try {
                  const result: any = await this.handleRazorpayResponse(respon, resrazorpayOrder.id, this.objInsert)
                  resolve(result);
                } catch (error) {
                  reject(error);
                }
              };
            });
          } else {
            return { msg: "Order Id not created, Please try again", status: false };
          }
        } catch (error) {
          return { msg: "Error during Razorpay process, Please try again later!", status: false };
        }
      } else {
        this.toastr.error(res?.msg)
      }
    })
  };



  async handleRazorpayResponse(respon: any, orderId: string, objInsert: any): Promise<{ msg: string, status: boolean }> {


    if (objInsert.id != null && respon.razorpay_order_id != null && respon.razorpay_payment_id) {
      const updateWalletData = {
        id: objInsert.id,
        razorpayOrderId: respon.razorpay_order_id,
        paymentId: respon.razorpay_payment_id,
        isPaid: true
      };

      try {
        const resWalletUpdate: any = await this.course.razorpayUpdate(updateWalletData).toPromise();
        if (resWalletUpdate.status) {
          if (respon.razorpay_payment_id) {
            let dataSignature = {
              orderId: orderId,
              paymentId: respon.razorpay_payment_id,
              signature: respon.razorpay_signature,
            };
            objInsert.razorpayOrderId = orderId;
            objInsert.razorpayPaymentId = respon.razorpay_payment_id;


            try {
              const resrazorpayVerifySignature: any = await this.course.razorpayVerifySignature(dataSignature).toPromise();
              if (resrazorpayVerifySignature.isValid) {
                if (!objInsert.razorpayPaymentId) {
                  return { msg: "Payment Unsuccessful, Payment Id not found, Please try later!", status: false };
                } else if (!objInsert.razorpayOrderId) {
                  return { msg: "Payment Unsuccessful, Order Id not found, Please try later!", status: false };
                } else {
                  return { msg: resrazorpayVerifySignature, status: true };
                }
              } else {
                return { msg: "Signature not verified, Please try again", status: false };
              }
            } catch (error) {
              return { msg: "Error verifying signature, Please try again later!", status: false };
            }
          } else {
            return { msg: "Payment Id not created, Please try again", status: false };
          }
        } else {
          this.toastr.error('Update request failed');
          return { msg: "Update request failed", status: false };
        }
      } catch (errorWallet) {
        this.toastr.error('Update request response failed');
        return { msg: "Error during update", status: false };
      }
    } else {
      return { msg: "Order Id, Razorpay Payment Id, Razorpay Order Id not found, Please try again!", status: false };
    }
  }

  userLoginUserData() {
    let Id = this.cryptojsService.decryptLocalUserId()
    this.accountservice.getLoginUserDetails(Id).subscribe((resGetLoginDetails: any) => {
      if (resGetLoginDetails) {
        this.checkoutForm.controls['firstName'].setValue(resGetLoginDetails.name);
        this.checkoutForm.controls['lastName'].setValue(resGetLoginDetails.surname);
        this.checkoutForm.controls['email'].setValue(resGetLoginDetails.email);
        this.checkoutForm.controls['client_Id'].setValue(resGetLoginDetails.id);
        this.checkoutForm.controls['date'].setValue(this.getFormattedDate());
      }
    })
  }

  getCartCourses() {
    this.cartService.getCartCourse().subscribe((resGetCourse: any) => {
      this.getCartArray = resGetCourse
      if (this.getCartArray.length > 0) {
        this.coursesSubtotal = this.getCartArray.reduce((acc: any, crr: any) => {
          return acc + crr.offer_Price
        }, 0)
        this.gstCharge = Math.round((this.gstPercentage / 100) * this.coursesSubtotal);
        this.coursesTotal = this.coursesSubtotal + this.gstCharge
      } else {
        this.gstCharge = 0;
        this.coursesSubtotal = 0;
        this.coursesTotal = 0;
      }
    })


  }

  createCheckoutForm() {
    this.checkoutForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      country: ['IN', Validators.required],
      state: ['', Validators.required],
      address: ['', Validators.required],
      city: ['', Validators.required],
      postalCode: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(6), Validators.minLength(6)]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      additionalInfo: ['NA',],
      companyName: ['',],
      gstNo: ['',],
      paymentMode: ['',],
      totalOrderAmount: ['',],
      date: ['',],
      payment_Id: ['',],
      razorpayOrderId: ['',],
      client_Id: ['',],
      ibC_Id: ['',]
    });
  }


  get f() { return this.checkoutForm.controls; }

  onActivate() {
    this.indexService.onActivate()
  }


  userCartCourses() {
    this.cartService.getCartCourse().subscribe((cart: any) => {
      if (cart.length > 0) {
        this.cartCousesArray = cart
      } else {
        this.cartCousesArray = []
      }
    });
  }

  getFormattedDate(): string {
    const today = new Date();
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'Asia/Kolkata',
    };

    // Get the date in 'DD-MM-YYYY' format using toLocaleString with Indian timezone
    const dateParts = today.toLocaleString('en-IN', options).split('/');

    // Re-arrange to DD-MM-YYYY
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];

    return `${day}-${month}-${year}`;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { IndexComponent } from './components/index/index.component';
import { RouterModule } from '@angular/router';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { WhyUsComponent } from './components/why-us/why-us.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { TimeConvertorPipe } from './time-convertor.pipe';
import { AllCourseSectonComponent } from './components/all-course-secton/all-course-secton.component';
import { StudentsFeedbackComponent } from './components/students-feedback/students-feedback.component';
import { FaqComponent } from './components/faq/faq.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { OrderSuccessComponent } from './components/order-success/order-success.component';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { RefundPolicyComponent } from './components/refund-policy/refund-policy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';




@NgModule({
  declarations: [
    LoginComponent,
    RegisterComponent,
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    AboutUsComponent,
    WhyUsComponent,
    ContactUsComponent,
    MyProfileComponent,
    TimeConvertorPipe,
    AllCourseSectonComponent,
    StudentsFeedbackComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    OrderSuccessComponent,
    RefundPolicyComponent,
    DisclaimerComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStarRatingModule

  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    IndexComponent,
    TimeConvertorPipe,
    AllCourseSectonComponent,
    StudentsFeedbackComponent,
    OrderSuccessComponent
  ]
})
export class SharedModule { }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/shared/services/account.service';
import { CryptojsService } from '../core/services/cryptojs.service';
@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(private AccountService: AccountService, private router: Router, private cryptojsService:CryptojsService) {}
  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {  
    if (this.cryptojsService.decryptLocalAccessToken()) {
      // logged in so return true
      return true;
  }
        // not logged in so redirect to login page with the return url and return false
        this.router.navigate(['login']);
        return false;
    }
   
}  
  


<div class="course-details-breadcrumb-2 bg_image rts-section-gap">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="single-course-left-align-wrapper">
          <div class="meta-area  c-pointer">
            <a routerLink="/index">Home</a>
            <i class="fa-solid fa-chevron-right"></i>
            <a class="active">Course Details</a>
          </div>
          <h1 class="title">
            {{courseFullDetails?.name ? courseFullDetails?.name : 'NA'}}
          </h1>
          <div class="rating-area">
            <div class="stars-area">
              <span>4.5</span>
            <i class="fa-sharp fa-solid fa-star"></i>
            <i class="fa-sharp fa-solid fa-star"></i>
            <i class="fa-sharp fa-solid fa-star"></i>
            <i class="fa-sharp fa-solid fa-star"></i>
            <i class="fa-sharp fa-solid fa-star-half-stroke"></i>
              
            </div>
            <div class="students">
              <i class="fa-thin fa-users"></i>
              <span>{{courseFullDetails?.studentCount ?
                courseFullDetails?.studentCount : '54'}}
                Students</span>
            </div>
            <div class="calender-area-stars">
              <i class="fa-light fa-calendar-lines-pen"></i>
              <span>Last updated {{courseFullDetails?.lastUpdatedDateOfCourse ? courseFullDetails?.lastUpdatedDateOfCourse : 'NA'}}</span>
            </div>
          </div>
          <div id="MainVideoSection" class="author-area ">
            <div class="author">
              <img src="assets/images/rohits.png" alt="">
              <h6 class="name"><span>By Prof</span> {{courseFullDetails?.authorName ? courseFullDetails?.authorName
                :'NA'}}
              </h6>
            </div>
            <p> <span>Categories: </span> {{courseFullDetails?.courseCategory ? courseFullDetails?.courseCategory
              :'NA'}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="breadcrumb-img-book-2 h-100  d-flex justify-content-end align-items-center">
          <img src="/assets/images/breadcrumb/book.png" alt="book" class="d-none d-md-block">
      </div>
      </div>
    </div>
  </div>
</div>

<div class="course-details-wrapper-2 rts-section-gap">
  <div class="container">
    <div class="row g-5">
      <div class="col-lg-8 order-cl-1 order-lg-1 order-md-2 order-sm-2 order-2">

        <div class="thumbnail mb--30" style="position: relative;" *ngIf="courseFullDetails">
          <img *ngIf="!isVideoPlaying" [src]="apiURL+'/'+detailsOfCourse?.bannerImg" alt="Course Image" style="width: 100%;">
          <div *ngIf="isVideoPlaying" class="video-container">
            <!-- Embedding YouTube iframe -->
            <iframe id="ytPlayer"  [src]="sanitizedVideoUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen style="width: 100%; height: 100%;"></iframe>
          </div>
          <div class="vedio-icone c-pointer"  *ngIf="!isVideoPlaying">
            <a id="mainVideoSectionClick" class="video-play-button play-video popup-video" (click)="openModal(lessionFirstVideoLink,lessionFirstVideoLinkPaid)">
              <span></span>
            </a>
            <div class="video-overlay">
              <a id="mainVideoLinkId" class="video-overlay-close"  (click)="closeVideo()">×</a>
            </div>
          </div>
        </div>
        <div class="thumbnail mb--30" style="position: relative;" *ngIf="courseFullDetails.length == 0">
          <img src="../../../assets/images/course/Course-not-Found.jpg" alt="course not found">
        </div>
        <div class="course-content-wrapper">
          <h5 class="title">About Course</h5>
          <p class="disc">
            The foundation course is essentially a grade IX and X mathematics course designed   to acclimatise students to mathematical concepts and their applications in a creative manner. It might be of interest to anyone interested in elementary mathematics irrespective of his/her walk of life.
          </p>
          <h5 class="title">Description</h5>
          <p class="disc">
            {{plainTextDescription ? plainTextDescription : 'NA'}}
          </p>
          
          <div class="module-wrapper">
            <h6 class="title">What Will You Learn?</h6>
            <div class="inner-content">
                <div class="single-wrapper">
                    <div class="single-codule d-flex align-items-start mb-3">
                        <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                        <p class="mb-0">You will revise selected topics from grade IX and X Mathematics which are prerequisites for further higher professional courses of study.</p>
                    </div>
                    <div class="single-codule d-flex align-items-start mb-3">
                        <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                        <p class="mb-0">You will improve comprehension and problem-solving skills.</p>
                    </div>
                    <div class="single-codule d-flex align-items-start mb-3">
                        <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                        <p class="mb-0">You will be exposed to competition questions and hence become conversant with the nature of different All India and State competitions.</p>
                    </div>
                </div>
                <div class="single-wrapper">
                    <div class="single-codule d-flex align-items-start mb-3">
                        <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                        <p class="mb-0">You will be introduced to rigorous Mathematical thought.</p>
                    </div>
                    <div class="single-codule d-flex align-items-start mb-3">
                        <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                        <p class="mb-0">You will develop the competitive thinking so necessary for every professional programme entrance test.</p>
                    </div>
                    <div class="single-codule d-flex align-items-start mb-3">
                        <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                        <p class="mb-0">You will gain valuable insight into important concepts in elementary Mathematics.</p>
                    </div>
                    <div class="single-codule d-flex align-items-start mb-3">
                        <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                        <p class="mb-0">You can hone your basic mathematical skills further with the practice exercises and their solutions, comparable to the problems discussed in the videos.</p>
                    </div>
                </div>
            </div>
        </div>
        
        </div>
        <div class="course-content-wrapper-main mt--40" *ngIf="courseFullDetails?.lessons?.length > 0">
          <h5 class="title">Course Content</h5>
          <div class="accordion mt--30" id="accordionExample">
            <div class="accordion-item" *ngFor="let lessionItem of courseFullDetails?.lessons; let i = index">
              <h2 class="accordion-header" [id]="'heading' + i">
                <button
                  class="accordion-button"
                  type="button"
                  [attr.data-bs-toggle]="'collapse'"
                  [attr.data-bs-target]="'#collapse' + i"
                  [attr.aria-expanded]="i === 0 ? 'true' : 'false'"
                  [attr.aria-controls]="'collapse' + i"
                >
                  <span>{{ lessionItem?.lessonName ? lessionItem?.lessonName : 'NA' }}</span>
                  <span>
                    {{ lessionItem?.lectureCount ? lessionItem?.lectureCount : 'NA' }} Lectures .
                    {{ lessionItem?.lessonDuration ? lessionItem?.lessonDuration : 'NA' }} sec
                  </span>
                </button>
              </h2>
              <div
                [id]="'collapse' + i"
                class="accordion-collapse collapse"
                [class.show]="i === 0"
                [attr.aria-labelledby]="'heading' + i"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body" *ngFor="let lessionItemVideo of lessionItem?.videoRow">
                  <a class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span class="c-pointer" (click)="goToMainScreen(lessionItemVideo?.videoURL)"><a>{{ lessionItemVideo?.videoName ? lessionItemVideo?.videoName : 'NA' }}</a></span>
                    </div>
                    <div class="right">
                      <span
                        class="play c-pointer"
                        (click)="preopenModal(lessionItemVideo?.videoURL)"
                        (click)="checkPaid(lessionItemVideo)"
                      >
                        Preview
                      </span>
                      <span>{{ lessionItemVideo?.video_Duration ? lessionItemVideo?.video_Duration : 'NA' }} sec</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          

        </div>
        
      </div>
      <div class="col-lg-4 order-cl-2 order-lg-2 order-md-1 order-sm-1 order-1 rts-sticky-column-item">

        <div class="right-course-details mt--0">

          <div class="course-side-bar">
            <div class="price-area" *ngIf="!lessionFirstVideoLinkPaid">
              <h3 class="title">&#8377;{{courseFullDetails?.offer_Price ? courseFullDetails?.offer_Price :'NA'}}</h3>
              <h4 class="none text-decoration-line-through">&#8377;{{courseFullDetails?.actual_Price ? courseFullDetails?.actual_Price : 'NA'}}</h4>
              <span class="discount">{{courseFullDetails?.offer_Percentage ? courseFullDetails?.offer_Percentage : 'NA'}} %
                off</span>
            </div>
            <!-- <div class="clock-area">
              <i class="fa-light fa-clock"></i>
              <span>2 Day left at this price!</span>
            </div> -->
            <div *ngIf="!lessionFirstVideoLinkPaid">
              <a class="rts-btn btn-primary" (click)="addToCart(detailsOfCourse)" *ngIf="!alerdyAddedToCart">Add To
                Cart</a>
              <a class="rts-btn btn-primary" *ngIf="alerdyAddedToCart">Added To Cart</a>
              <a class="rts-btn btn-border" (click)="goViewCart()">Buy Now</a>
            </div>

            <div class="what-includes">
              <!-- <span class="m">30-Day Money-Back Guarantee</span> -->
              <h5 class="title">This course includes: </h5>
              <div class="single-include">
                <div class="left">
                  <i class="fa-light fa-chart-bar"></i>
                  <span>Levels</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.skill ? courseFullDetails?.skill : 'NA'}}</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-light fa-timer"></i>
                  <span>Duration</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.totalCourseDuration ? courseFullDetails?.totalCourseDuration : 'NA'}} Second</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-regular fa-floppy-disk"></i>
                  <span>Subject</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.courseCategory ? courseFullDetails?.courseCategory : 'NA'}}</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-regular fa-pen-to-square"></i>
                  <span>Update</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.lastUpdatedDateOfCourse ? courseFullDetails?.lastUpdatedDateOfCourse : 'NA'}}</span>
                </div>
              </div>
              <!-- <div class="single-include">
                <div class="left">
                  <i class="fa-sharp fa-light fa-file-certificate"></i>
                  <span>Certificate</span>
                </div>
                <div class="right">
                  <span>Certificate of completion </span>
                </div>
              </div> -->
            </div>
          </div>

        </div>

        <div class="right-course-details mt--30">

          <div class="course-side-bar">

            <div class="course-single-information">
              <h5 class="title">A course by</h5>
              <div class="body">
                <div class="author author-area">
                  <img src="assets/images/rohits.png" alt="">
                  <span>{{courseFullDetails?.authorName ? courseFullDetails?.authorName : 'NA'}}</span>
                </div>
              </div>
            </div>

            <div class="course-single-information">
              <h5 class="title">Material Includes</h5>
              <div class="body">

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Flexible Deadlines
                </div>

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Most asked questions in last Years
                </div>

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  11 downloadable resoursces
                </div>

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                 
                  Hours of live- demo
                </div>

              </div>
            </div>

            <div class="course-single-information">
              <h5 class="title">Requirements</h5>
              <div class="body">

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Access to Adobe acrobat reader.

                </div>

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Familiarity with computers and other devices
                </div>

              </div>
            </div>

            <div class="course-single-information">
              <h5 class="title">Tags</h5>
              <div class="body">
                <div class="tags-wrapper">

                  <span>Course</span>
                  <span>Mathematical</span>
                 

                </div>
              </div>
            </div>

            <div class="course-single-information">
              <h5 class="title">Share</h5>
              <div class="body">

                <div class="social-share-course-side-bar">
                  <ul>
                    <li><a href="#"><i class="fa-brands fa-whatsapp"></i></a></li>
                  
                  </ul>
                </div>

              </div>
            </div>

            <div class="course-single-information last">
              <h5 class="title">Audience</h5>
              <div class="body">

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Suitable for {{courseFullDetails?.skill ? courseFullDetails?.skill : 'NA'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="cart-bar">
  <div class="cart-header">
    <h3 class="cart-heading">MY CART (3 ITEMS)</h3>
    <div class="close-cart"><i class="fal fa-times"></i></div>
  </div>
  <div class="product-area">
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/01.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Construct Map</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">3 ×</span>
            <span class="product-price">&#8377;198.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="3">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/02.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name"> Bridge product</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">2 ×</span>
            <span class="product-price">&#8377;88.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item last-child">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/03.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Labour helmet</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">1 ×</span>
            <span class="product-price">&#8377;289.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="cart-bottom-area">
    <span class="spend-shipping"><i class="fal fa-truck"></i> SPENT <span class="amount">&#8377;199.00</span> MORE
      FOR FREE SHIPPING</span>
    <span class="total-price">TOTAL: <span class="price">&#8377;556</span></span>
    <a href="checkout.html" class="checkout-btn cart-btn">PROCEED TO CHECKOUT</a>
    <a href="cart.html" class="view-btn cart-btn">VIEW CART</a>
  </div>
</div>

<div id="side-bar" class="side-bar header-two">
  <button class="close-icon-menu"><i class="far fa-times"></i></button>

  <div class="inner-main-wrapper-desk">
    <div class="thumbnail">
      <img src="assets/images/banner/04.jpg" alt="elevate">
    </div>
    <div class="inner-content">
      <h4 class="title">We Build Building and Great Constructive Homes.</h4>
      <p class="disc">
        We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master new
        technologies.
      </p>
      <div class="footer">
        <h4 class="title">Got a project in mind?</h4>
        <a href="contact.html" class="rts-btn btn-primary">Let's talk</a>
      </div>
    </div>
  </div>

  <div class="mobile-menu-main">
    <nav class="nav-main mainmenu-nav mt--30">
      <ul class="mainmenu metismenu" id="mobile-menu-active">
        <li>
          <a href="index.html" class="main">Home</a>
        </li>
        <li>
          <a href="about.html" class="main">About Us</a>
        </li>
        <li>
          <a href="course-one.html" class="main">Course</a>

        </li>
        <li>
          <a href="contact.html" class="main">Contact Us</a>

        </li>
        <li>
          <a href="FAQ.html" class="main">FAQ</a>

        </li>
      </ul>
    </nav>

    <div class="buttons-area">
      <a href="log-in.html" class="rts-btn btn-border">Log In</a>
      <a href="registration.html" class="rts-btn btn-primary">Sign Up</a>
    </div>

    <div class="rts-social-style-one pl--20 mt--50">
      <ul>
        <li>
          <a href="#">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>

</div>
<!-- <div id="myModal-1" class="modal fade" role="dialog">
  <div class="modal-dialog bg_image">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-bs-dismiss="modal"><i class="fa-light fa-x"></i></button>
      </div>
      <div class="modal-body text-center">
        <div class="inner-content">
          <div class="title-area">
            <span class="pre">Get Our Courses Free</span>
            <h4 class="title">Wonderful for Learning</h4>
          </div>
          <form action="#">
            <input type="text" placeholder="Your Mail.." required>
            <button>Download Now</button>
            <span>Your information will never be shared with any third party</span>
          </form>
        </div>
      </div>
    </div>
  </div>
</div> -->
<div class="progress-wrap">
  <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
      style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;">
    </path>
  </svg>
</div>
<div class="search-input-area">
  <div class="container">
    <div class="search-input-inner">
      <div class="input-div">
        <input class="search-input autocomplete" type="text" placeholder="Search by keyword or #">
        <button><i class="far fa-search"></i></button>
      </div>
    </div>
  </div>
  <div id="close" class="search-close-icon"><i class="far fa-times"></i></div>
</div>




<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>

<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>



<div class="modal fade" id="videoModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="videoModalLabel">Antarkar Sir's Video Player</h5>
        <button type="button" class="btn-close" id="closeLessionModel" data-bs-dismiss="modal" aria-label="Close" (click)="onModalClose()"></button>
      </div>
      <div class="modal-body">
        <!-- Dynamically bind iframe source to embed YouTube video -->
        <div class="ratio ratio-16x9">
          <iframe id="ytPlayer" [src]="presanitizedVideoUrl" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>
   
    <!-- PAGE TITLE
    ================================================== -->
    <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="breadcrumb-main-wrapper">
                                <h1 class="title">Why Us</h1>
                                <div class="pagination-wrapper">
                                    <a routerLink="/index">Home</a>
                                    <i class="fa-regular fa-chevron-right"></i>
                                    <a class="active" href="Instructor-2.html">Why Us</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="breadcrumb-img-book">
                                <img src="\assets\images\breadcrumb\book.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- FEATURE GENERAL
    ================================================== -->
    <section id="why" class="container content-section rts-section-gap ">
        <h2 class="text-center">Why Us?</h2>
        <p class="text-center">By choosing <strong>Antarkar Sir</strong> you're gaining a partner in math education focused on building your skills, confidence, and love for mathematics.</p>
        <br>
        <div class="row">
            <div class="col-md-4">
                <div class="block block-icon-left">
                    <div class="icon">
                        <i class="fa fa-graduation-cap fa-5x"></i>
                    </div>
                    <div class="icon-content">
                        <h3>Expert-Led Courses</h3>
                        <p>Our courses are created and taught by experienced mathematicians and educators dedicated to simplifying complex concepts.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="block block-icon-left">
                    <div class="icon">
                        <i class="fa fa-lightbulb-o fa-5x"></i>
                    </div>
                    <div class="icon-content">
                        <h3>Interactive Problem Solving</h3>
                        <p>Engage with interactive exercises and simulations that make learning math both fun and effective.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="block block-icon-left">
                    <div class="icon">
                        <i class="fa fa-trophy fa-5x"></i>
                    </div>
                    <div class="icon-content">
                        <h3>Comprehensive, All-Level Support</h3>
                        <p>Whether you’re a beginner or advancing in your studies, we offer resources that cater to all skill levels and mathematical fields.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="block block-icon-left">
                    <div class="icon">
                        <i class="fa fa-user-circle fa-5x"></i>
                    </div>
                    <div class="icon-content">
                        <h3>Personalized Learning Experience</h3>
                        <p>With a flexible, self-paced approach, you can customize your learning journey based on your schedule and goals.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="block block-icon-left">
                    <div class="icon">
                        <i class="fa fa-users fa-5x"></i>
                    </div>
                    <div class="icon-content">
                        <h3>Community of Learners</h3>
                        <p>Join a thriving community where you can discuss problems, exchange insights, and collaborate on challenging math topics.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="block block-icon-left">
                    <div class="icon">
                        <i class="fa fa-globe fa-5x"></i>
                    </div>
                    <div class="icon-content">
                        <h3>Real-World Applications</h3>
                        <p>Learn how math applies to real-world scenarios, enhancing your problem-solving skills and critical thinking abilities.</p>
                    </div>
                </div>
            </div>
        </div>
        
      </section>
      
     <!-- EVENTS
    ================================================== -->
  
   
    
   
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>
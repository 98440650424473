import { Injectable,Inject, PLATFORM_ID  } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CryptojsService {
   secretKey = "Pass!0000"
   private userLogin = new BehaviorSubject<boolean>(false);
   private userLoginData = new BehaviorSubject<any>({});
   

   userLoginSignal$ = this.userLogin.asObservable();
   userLoginDataSignal$ = this.userLoginData.asObservable();

  constructor(@Inject(PLATFORM_ID) private platformId: object) {
    const user = localStorage.getItem('AccessToken');
    if (user) {
      this.userLogin.next(true);
    }else{
      this.userLogin.next(false);
    }
  }

 

  encryptLocalAccessToken(data: string) {
    if(isPlatformBrowser(this.platformId)){
      if (typeof data == typeof 'String') {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("AccessToken", encryptedData)
      } else {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("AccessToken", encryptedData)
      }
    }
  }

  decryptLocalAccessToken() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("AccessToken") == null) {
        return null
      } else {
        let localData = localStorage.getItem("AccessToken") || '';
        const decryptedBytes = CryptoJS.AES.decrypt(localData, this.secretKey)
        let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return data
      }
    }else{
      return null
    }
  }


  encryptLocalUserId(data: string) {
    if(isPlatformBrowser(this.platformId)){
      if (typeof data == typeof 'String') {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("UserId", encryptedData)
      } else {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("UserId", encryptedData)
      }
    }
  }

  decryptLocalUserId() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("UserId") == null) {
        return null
      } else {
        let localData = localStorage.getItem("UserId") || '';
        const decryptedBytes = CryptoJS.AES.decrypt(localData, this.secretKey)
        let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return data
      }
    }else{
      return null
    }
  }

  encryptLocalUserName(data: string) {
    if(isPlatformBrowser(this.platformId)){
      if (typeof data == typeof 'String') {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("userName", encryptedData)
      } else {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("userName", encryptedData)
      }
    }
  }

  decryptLocalUserName() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("userName") == null) {
        return null
      } else {
        let localData = localStorage.getItem("userName") || '';
        const decryptedBytes = CryptoJS.AES.decrypt(localData, this.secretKey)
        let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return data
      }
    }else{
      return null
    }
  }

  checkUserLoginOrNot(){
    let userToken=this.decryptLocalAccessToken()
    if(userToken){
      this.userLogin.next(true);
    }else{
      this.userLogin.next(false);
    }
  }

  updateLoginUserData(data:any){
      if(data){
        this.userLoginData.next(data);
      }else{
        this.userLoginData.next({});
      }
  }



  encryptLocalLoginEmailPassword(data: string) {
    if(isPlatformBrowser(this.platformId)){
      if (typeof data == typeof 'String') {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("loginEmailPassword", encryptedData)
      } else {
        let encryptedData = CryptoJS.AES.encrypt(data, this.secretKey).toString();
        localStorage.setItem("loginEmailPassword", encryptedData)
      }
    }
  }

  decryptLocalLoginEmailPassword() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem("loginEmailPassword") == null) {
        return null
      } else {
        let localLoginData = localStorage.getItem("loginEmailPassword") || '';
        const decryptedBytes = CryptoJS.AES.decrypt(localLoginData, this.secretKey)
        let data = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return data
      }
    }else{
      return null
    }
  }
 
}

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit,AfterViewInit {
  apiUrl: any = environment.url
  expertTeamArray: any = []
  expertData: any;

  constructor(private indexService: IndexService) { }
  ngAfterViewInit(): void {
    this.getAllExpertTeam()
    this.getExpert()
  }

  ngOnInit(): void {
   
  }

  getAllExpertTeam() {
    this.indexService.getExpertTeam().subscribe((resAllExpertTeam: any) => {
      if (resAllExpertTeam.items) {
        this.expertTeamArray = resAllExpertTeam.items
      }
    })
  }

  getExpert() {
    this.indexService.getExpert('6ce43ef1-90fd-9213-ca78-3a158638dcd8').subscribe((resExpert: any) => {
      if (resExpert) {
        this.expertData = resExpert
        console.log("get data",this.expertData);
        
      } else {
        this.expertData = undefined
      }
    })
  }

  onActivate() {
    this.indexService.onActivate()
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Authenticate } from '../modals/authenticate';
import { User, loginUser } from '../modals/user';
import { forgotPassword } from '../modals/forgotpassword';
import { resetPassword } from '../modals/resetPassword';
import { IBC } from '../modals/IBC';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';


@Injectable({
  providedIn: 'root'
})
export class AccountService {
  private userSubject: BehaviorSubject<Authenticate>;
  public user: Observable<Authenticate>;
  public forgortPassword: Observable<Authenticate>;
  public resetpassword: Observable<Authenticate>;
  url = environment.url;

  constructor(private router: Router,
    private http: HttpClient,
    private cryptojsService: CryptojsService
  ) {
    this.userSubject = new BehaviorSubject<Authenticate>(JSON.parse(localStorage.getItem('AcceessToken') || '{}'));
    this.user = this.userSubject.asObservable();
    this.forgortPassword = this.userSubject.asObservable();
    this.resetpassword = this.userSubject.asObservable();

  }
  public get userValue(): Authenticate {
    return this.userSubject.value;
  }



  login(userNameOrEmailAddress: any, password: any,): Observable<any> {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
    };

    let body = new URLSearchParams();
    body.set('grant_type', "password");
    body.set('client_id', "VideoStream_App");
    body.set('client_secret', "1q2w3e*");
    body.set('UserName', userNameOrEmailAddress);
    body.set('Password', password);

    return this.http.post(`${this.url}/connect/token`, body.toString(), options)//if rememberMe needed,pass parameter:rememberMe
      .pipe(map(user => {
        return user;
      }));
  }

  loginCall(user: loginUser) {
    return this.http.post(`${this.url}/api/account/login`, user);
  }

  register(user: User) {
    return this.http.post(`${this.url}/api/app/my-account/my-register`, user);
  }

  Ibcregister(formData: IBC) {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.url}/api/app/i-bC_Registration`, formData);
  }

  forgotPassword(forgotpassword: forgotPassword) {
    return this.http.post(`${this.url}/api/account/send-password-reset-code`, forgotpassword)
  }

  resetPassword(resetpassword: resetPassword) {
    return this.http.post(`${this.url}/api/account/reset-password`, resetpassword)
  }

  changepassword(currentPassword: any, newPassword: any) {
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    };
    var data = {
      'currentPassword': currentPassword,
      'newPassword': newPassword
    };
    console.log("get data", data)
    return this.http.post(`${this.url}/api/identity/my-profile/change-password`, data, options);
  }

  changePasswordTwo(data:any) {
    // let options = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    // };
    return this.http.post(`${this.url}/api/app/my-account/new-change-password`, data);
  }

  forgetPassword(data:any) {
    // let options = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    // };
    return this.http.post(`${this.url}/api/app/my-account/forgot-password-send-otp?emailOrPhone=${data}`, {});
  }


  forgetPasswordVerifyOTP(phoneNo:any,otpNo:any) {
    // let options = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    // };
    return this.http.post(`${this.url}/api/app/my-account/forgot-password-flow?emailOrPhone=${phoneNo}&otpNumber=${otpNo}`, {});
  }
  
  forgetChangePassword(phoneNo:any,otpNo:any,newPassword:any) {
    // let options = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json')
    //     .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    // };
    return this.http.post(`${this.url}/api/app/my-account/forgot-password-flow?emailOrPhone=${phoneNo}&otpNumber=${otpNo}&newPassword=${newPassword}`, {});
  }



  editProfile(data: any) {
    let options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken())
    };

    return this.http.put(`${this.url}/api/identity/my-profile`, data, options);
  }

  editProfileTwo(data: any) {
    // let options = {
    //   headers: new HttpHeaders()
    //     .set('Content-Type', 'application/json')
    //     .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken())
    // };

    return this.http.put(`${this.url}/api/app/my-account/profile`, data);
  }

  profileDetails(auth_token: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${auth_token}`
    })
    return this.http.get(`${this.url}/api/identity/my-profile`, { headers: headers }).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Profile Details'))),
    );
  }

  getLoginUserDetails(userId: any): Observable<any> {
    return this.http.get(`${this.url}/api/app/identity-user/${userId}`).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Login User Info Details'))),
    );
  }

  myReview(Id: any): Observable<any> {
    let options = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    };

    return this.http.get(`${this.url}/api/app/comments/client-comments/${Id}`, options).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting reviews'))),
    );
  }

  mySubscription(Id: any): Observable<any> {
    return this.http.get(`${this.url}/api/app/course-by-client/client-wise-courses/${Id}`).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting Data'))),
    );
  }


}



import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { lesson } from '../modals/lesson';

import { CourseService } from '../services/course.service';

@Component({
  selector: 'app-lesson',
  templateUrl: './lesson.component.html',
  styleUrls: ['./lesson.component.scss']
})
export class LessonComponent implements OnInit {
  viewCourse = 0;
  lessondata! : lesson[];
  @Input() lessonData : any;
  @Output() newVideoEvent = new EventEmitter();
  constructor(private courseService: CourseService,private ActivateRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.ActivateRoute.params.subscribe(data => {
      this.viewCourse = data.Id
      // console.log("data", data)


    })
    this.courseService.LessonDetails(this.viewCourse).subscribe(res => {
      this.lessondata = res;

      //if(this.lessonData.videoRow.length > 0){
        
      //}
    
      // console.log("lessondata:", this.lessondata);

    })
  }

  playVideo(id : any){
    // console.log('Lessdon',id);
    this.newVideoEvent.emit(id);
  }
 

}

import { Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private indexService:IndexService) { }

  ngOnInit(): void {
  }

  onActivate(){
    this.indexService.onActivate()
  }

}

import { Component, OnInit } from '@angular/core';
import { BlogService } from '../services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  blogs: any;
  constructor(private blogService: BlogService) { }

  ngOnInit(): void {
    this.getAllBlogs();
  }

  getAllBlogs() {
    this.blogs = [];
    this.blogService.getBlogs().subscribe(response => {
           this.blogs = response['items'];    
       
    }, err => {

    })
  }

}

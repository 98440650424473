import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
import { trigger, transition, style, animate } from '@angular/animations';
interface Testimonial {
  feedback: string;
  name: string;
  work: string;
}

@Component({
  selector: 'app-students-feedback',
  templateUrl: './students-feedback.component.html',
  styleUrls: ['./students-feedback.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('500ms ease-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class StudentsFeedbackComponent implements OnInit  {


  currentSlide = 0;
  showMoreStates: { [key: string]: boolean } = {};
  private slideInterval: any;
  
  testimonials: Testimonial[] = [
    {
      feedback: "I can't recommend The Gourmet Haven enough. It's a place for special date nights, or whenever you're in the mood for a culinary treat.",
      name: 'Rushikesh Kulkarni',
      work: 'Assistant T'
    },
    {
      feedback: "This restaurant offers an unforgettable experience, perfect for food lovers who want something extraordinary.",
      name: 'Rohit More',
      work: 'Food C'
    },
    {
      feedback: "An excellent spot for a relaxing dinner. The ambiance and food selection make it one of my top favorites!",
      name: 'Shubham Raut',
      work: 'Marketing Executive'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Sagar Sutar',
      work: 'Assistant '
    },
    {
      feedback: "An excellent spot for a relaxing dinner. The ambiance and food selection make it one of my top favorites!",
      name: 'Shailendra Gorde',
      work: 'Marketing Executive'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Vishal Pawar',
      work: 'Assistant Teacher'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Jaykishan',
      work: 'Assistant Teacher'
    },
    {
      feedback: "An excellent spot for a relaxing dinner. The ambiance and food selection make it one of my top favorites!",
      name: 'Avinash Kadam',
      work: 'Marketing Executive'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Megha Pawar',
      work: 'Assistant Teacher'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Sonali Kadam',
      work: 'Assistant Teacher'
    },
    {
      feedback: "An excellent spot for a relaxing dinner. The ambiance and food selection make it one of my top favorites!",
      name: 'Mangala Patil',
      work: 'Marketing Executive'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Sneha Patil',
      work: 'Assistant Teacher'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Omkar Bhosale',
      work: 'Assistant Teacher'
    },
    {
      feedback: "An excellent spot for a relaxing dinner. The ambiance and food selection make it one of my top favorites!",
      name: 'Omkar Sawant',
      work: 'Marketing Executive'
    },
    {
      feedback: "I can't recommend The Gourmet Haven enough. The ambiance and food selection make it one of my top. whenever you're in the mood for a culinary treat.",
      name: 'Sonali Kulkarni',
      work: 'Assistant Teacher'
    }
  ];

  chunkedTestimonials: Testimonial[][] = [];

  ngOnInit(): void {
    this.chunkTestimonials();
    this.startAutoSlide();
  }

  startAutoSlide(): void {
    this.slideInterval = setInterval(() => {
      this.nextSlide();
    }, 3000); 
  }

  chunkTestimonials(): void {
    const chunkSize = 3;
    this.chunkedTestimonials = [];
    for (let i = 0; i < this.testimonials.length; i += chunkSize) {
      this.chunkedTestimonials.push(this.testimonials.slice(i, i + chunkSize));
    }
  }

  nextSlide(): void {
    if (this.currentSlide < this.chunkedTestimonials.length - 1) {
      this.currentSlide++;
    }else{
      this.currentSlide = 0;  
    }
  }

  previousSlide(): void {
    if (this.currentSlide > 0) {
      this.currentSlide--;
    }
  }

  review(reviewString: string, maxLength: number, index: string): string {
    if (this.showMoreStates[index]) {
      return reviewString;
    }
    if (reviewString.length > maxLength) {
      return reviewString.substring(0, maxLength) + '...';
    }
    return reviewString;
  }

  toggleShowMore(index: string): void {
    this.showMoreStates[index] = !this.showMoreStates[index];
  }

  getDotArray(): number[] {
    return Array(this.chunkedTestimonials.length).fill(0).map((_, i) => i);
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }

  pauseAutoSlide(): void {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }
  
  resumeAutoSlide(): void {
    this.startAutoSlide();
  }
}

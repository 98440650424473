<!-- PAGE TITLE
    ================================================== -->
<div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-6">
                        <div class="breadcrumb-main-wrapper">
                            <h1 class="title">Terms & Conditions</h1>
                            <div class="pagination-wrapper">
                                <a routerLink="/index">Home</a>
                                <i class="fa-regular fa-chevron-right"></i>
                                <a class="active">Terms & Conditions</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="breadcrumb-img-book">
                            <img src="\assets\images\breadcrumb\book.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<main class="terms-container">
    <section class="terms-header">
        <h2>Terms and Conditions</h2>
        <!-- <p class="effective-date">Effective Date: <strong>[29/10/2024]</strong></p> -->
        <p class="intro">Welcome to Antarkar Sir’s Educative Programmes. By accessing or using our website enrolling in
            any of our educational courses, you agree to comply with and be bound by the following terms and conditions.
            Please read these terms carefully before making any purchases or using our services.</p>
        <br>
        <p class="effective-date">Overview:</p>
        <p class="intro">This website Antarkar Sir's Education Programming (antllp.com) and all subdomains of Antarkar
            Sir's Education Programming (antllp.com) (hereinafter referred to as the “site” or “website”) are operated
            by Antarkar Sir’s Educative Programmes. Throughout the site, the terms “we”, “us,” and “our” refer to
            Antarkar Sir’s Educative Programmes. We offer this website, including all information, tools, and services
            available from this site, to you (the user) conditioned upon your acceptance of all terms, conditions,
            policies, and notices stated here.</p>
        <p class="intro">
            By visiting our site and/or purchasing something from us, you engage in our "Service" and agree to be bound
            by the following terms and conditions ("Terms of Service," "Terms"), including those additional terms and
            conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all
            users of the site, including but not limited to users who are browsers, vendors, customers, and/or
            contributors of content.
            Please read these Terms of Service carefully before accessing or using our website. By accessing or using
            any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms
            and conditions of this agreement, you may not access the website or use any services. If these Terms of
            Service are considered an offer, acceptance is expressly limited to these Terms.

        </p>
        <p class="intro">Any new features or tools added to the current website shall also be subject to the Terms of
            Service.</p>
    </section>

    <!-- Terms Sections in Rows -->
    <div class="terms-row">
        <h3>1. Acceptance of Terms</h3>
        <p>By using the Antarkar Sir’s Educative Programmes platform, you acknowledge that you have read, understood,
            and agree to be bound by these Terms and Conditions, as well as our Privacy Policy, Refund Policy, and any
            other guidelines or policies mentioned here.
        </p>
    </div>

    <div class="terms-row">
        <h3>2. Course Enrollment and Access</h3>
        <p>Upon successful payment and registration, you will receive access to the selected course. Course access
            details, schedule, will be communicated via email, mobile number or through your account on our website. The
            login Id, credential, Password are set-up by or at user end by themselves.</p>
        <p>Enrollment in a course is strictly personal and cannot be transferred to any other individual.
        </p>
        <p>Access to online materials and recorded sessions is available for a limited time as specified in the course
            details. After the access period expires, you will no longer be able to access the course content unless
            otherwise mentioned.
        </p>
    </div>

    <div class="terms-row">
        <h3>3. Content Ownership and Usage</h3>
        <p>All materials, including but not limited to videos, documents, quizzes, and presentations, recorded lectures
            provided by Antarkar Sir’s Educative Programmes are the intellectual property of Antarkar Sir’s Educative
            Programmes or our partners.</p>
        <p>You are granted a limited, non-transferable, non-exclusive license to access and view the content for your
            personal, educational purposes only.
        </p>
        <p>You may not copy, distribute, modify, share, or reproduce any part of the course content without express
            written permission from Antarkar Sir’s Educative Programmes.</p>
    </div>

    <div class="terms-row">
        <h3>4. User Conduct</h3>
        <p>By using our platform, you agree not to</p>
        <p>Engage in any activity that may disrupt or interfere with the smooth operation of our services.
        </p>
        <p>Use our courses or platform for any unlawful or unauthorized purpose.</p>
        <p>Share your login credentials with others or engage in any activity that allows multiple users to access the
            course using one account.</p>
        <p>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any
            portion of the Service without express written permission by us.
        </p>
    </div>

    <div class="terms-row">
        <h3>5. Payment Terms
        </h3>
        <p>Full payment is required at the time of enrollment unless otherwise stated for specific courses. The pricing
            for courses is subject to change at the discretion of Antarkars Educative Programmes.
        </p>
        <p>Any additional taxes, fees, or charges applicable to your location will be borne by you as the user.</p>
    </div>

    <div class="terms-row">
        <h3>6. Refund and Cancellation Policy</h3>
        <p>Please refer to our <b>Refund and Cancellation Policy</b> for detailed information regarding our policies on
            refunds, cancellations, and changes to your course enrollment. By purchasing a course, you agree to abide by
            the terms set forth in the refund policy.
        </p>
    </div>

    <div class="terms-row">
        <h3>7. Modifications to Courses and Policies</h3>
        <p>Antarkar Sir’s Educative Programmes reserves the right to modify, cancel, or update any course content,
            schedule, or location as necessary. Any such changes will be communicated to students in a timely manner. We
            also reserve the right to update or modify these Terms and Conditions at any time. It is your responsibility
            to check for updates, which will be posted on our website.</p>
        <p>Career guidance sessions and one-to-one interactions with students or parents will be provided based on the
            allotted time and schedule as per the selected courses. The timing and availability of these sessions will
            be determined and communicated to you in a timely manner. Participation in these sessions is subject to the
            terms of your enrollment in the respective course, and any changes or rescheduling will be notified
            accordingly. </p>
    </div>

    <div class="terms-row">
        <h3>8. Modifications to the Service and Prices
        </h3>
        <p>Prices for our classroom courses, study material, online courses, recorded lectures and other products and
            services are subject to change without notice.
            We reserve the right to modify or discontinue the Service (or any part or content thereof) without notice.
        </p>
        <p>We shall not be liable for any modification, price change, suspension, or discontinuance of the Service.
        </p>
    </div>

    <div class="terms-row">
        <h3>9. Limitation of Liability
        </h3>
        <p>Antarkar Sir’s Educative Programmes will not be held liable for any indirect, incidental, or consequential
            damages arising out of your use of our platform or courses.
        </p>
        <p>We do not guarantee that our courses will meet your expectations or that specific results will be achieved.
            The effectiveness of the course depends on your commitment and engagement.
        </p>
    </div>

    <div class="terms-row">
        <h3>10. Force Majeure
        </h3>
        <p>Antarkar Sir’s Educative Programmes is not liable for any delay or failure in delivering courses or services
            due to causes beyond our control, such as natural disasters, government actions, or any other events deemed
            "force majeure."
        </p>
    </div>

    <div class="terms-row">
        <h3>11. Privacy
        </h3>
        <p>Your personal information will be handled in accordance with our <b>Privacy Policy</b>, which outlines how we collect, store, and use the data you provide. By using our services, you consent to the collection and use of your information as described in the Privacy Policy.
        </p>
    </div>

    <div class="terms-row">
        <h3>12. User Comments and Submissions
        </h3>
        <p>If you send us comments, suggestions, or other submissions, we may use them without restriction and are under no obligation to keep such submissions confidential or provide compensation.
        </p>
    </div>

    <div class="terms-row">
        <h3>13. Governing Law and Jurisdiction
        </h3>
        <p>These Terms and Conditions are governed by the laws of India, and any disputes arising from or related to these terms will be subject to the exclusive jurisdiction of the courts in Mumbai.
        </p>
    </div>

    <div class="terms-row">
        <h3>14. Contact Information
        </h3>
        <p>If you have any questions or concerns regarding these Terms and Conditions or any of our policies, please contact us at info@antarkarsir.com
        </p>
    </div>

    <footer class="terms-footer">
        <p>For questions, please contact us at <a href="mailto:antarkarsir1@gmail.com">antarkarsir1@gmail.com</a>.</p>
    </footer>
</main>
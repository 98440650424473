<div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-6">
            <div class="breadcrumb-main-wrapper">
              <h1 class="title">Contact Us</h1>
              <div class="pagination-wrapper  c-pointer">
                <a routerLink="/index">Home</a>
                <i class="fa-regular fa-chevron-right"></i>
                <a class="active">Contact us</a>
              </div>

            </div>

          </div>
          <div class="col-6">
            <div class="breadcrumb-img-book">
              <img src="assets/images/breadcrumb/book.png" alt="">
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="rts-contact-area rts-section mt-5 mb-5 rts-section-gap">
  <div class="container">
    <div class="row g-5">
      <div class="col-xl-5">

        <div class="contact-left-area-start">
          <div class="title-area-left-style">
            <div class="pre-title">
              <img src="assets/images/banner/bulb.png" alt="icon">
              <span>Contact Us</span>
            </div>
            <h2 class="title">Get in touch!
              <br>
              <span>Feel free to connect with us</span>
            </h2>
          </div>
          <form [formGroup]="contactUsForm" method="post" class="contact-page-form" id="contact-form"
            (ngSubmit)="contactUs()">
            <div class="single-input">
              <label for="name">Your Name<span class="text-danger">*</span></label>
              <input id="name" name="name" type="text" placeholder="Enter Full Name" formControlName="name">
              <div class="text-danger"
                *ngIf="contactUsForm.controls['name'].invalid && contactUsForm.controls['name'].touched">
                Full Name is required
              </div>
            </div>
            <div class="single-input">
              <label for="email">Enter Your Email<span class="text-danger">*</span></label>
              <input id="email" name="email" type="email" placeholder="Enter Email" formControlName="email">
              <div *ngIf="contactUsForm.get('email')?.invalid && contactUsForm.get('email')?.touched">
                <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.required">Email is
                  required.</span>
                <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.pattern">Email must
                  contain '&#64;' and end with '.com'.</span>
              </div>
            </div>
            <div class="single-input">
              <label for="mobile">Your Phone Number<span class="text-danger">*</span></label>
              <input id="mobile" name="mobile" type="text" maxlength="10" placeholder="Enter Mobile Number"
                formControlName="phone_Number">
              <div *ngIf="contactUsForm.get('phone_Number')?.invalid && contactUsForm.get('phone_Number')?.touched">
                <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.required">Number is
                  required.</span>
                <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.pattern">Only numbers are
                  allowed.</span>
                <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.maxlength">Maximum length is
                  10.</span>
                <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.minlength">Manimum length is
                  10.</span>
              </div>
            </div>
            <div class="single-input">
              <label for="message">Message<span class="text-danger">*</span></label>
              <textarea id="message" name="message" placeholder="Enter Your Message"
                formControlName="message"></textarea>
              <div class="text-danger"
                *ngIf="contactUsForm.controls['message'].invalid && contactUsForm.controls['message'].touched">
                Message is required
              </div>
            </div>


            <div class="button-container">
              <button type="submit" style="padding: 14px 40px; font-size: 17px ; margin-right: 10px;"
                class="rts-btn btn-border">Submit</button>
              <!-- <a (click)="handleClick()" [attr.data-bs-toggle]="attributesAdded ? modalToggle : null"
                [attr.data-bs-target]="attributesAdded ? modalTarget : null"
                [attr.id]="attributesAdded ? buttonId : null" class="rts-btn btn-primary">Admission Form</a> -->


            </div>

          </form>
          <div id="form-messages" class="mt--20"></div>
        </div>

      </div>
      <div class="col-xl-7 pl--50 pl_lg--15 pl_md--15 pl_sm--15 pb_md--100 pb_sm--100">
        <div class="contact-map-area-start pb--15">
          <div class="single-maptop-info">
            <div class="icon">
              <img src="assets/images/contact/02.png" alt="contact">
            </div>
            <p class="disc">
              Vile Parle, Mumbai, India
            </p>
          </div>
          <div class="single-maptop-info">
            <div class="icon">
              <img src="assets/images/contact/03.png" alt="contact">
            </div>
            <a href="tel:+4733378901">+91 8828228330 </a> <br>
            <a href="tel:+4733378901">+91 8828228330 </a>
          </div>
          <div class="single-maptop-info">
            <div class="icon">
              <img src="assets/images/contact/04.png" alt="contact">
            </div>
            <p class="disc">
              All days: 9 AM - 6PM

            </p>
          </div>
        </div>
        <div class="map-bottom-area mt--30">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30161.87589165459!2d72.84145665192014!3d19.09736640912719!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9b42151fed3%3A0xac3b84f7db9d9318!2sVile%20Parle%2C%20Mumbai%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1725019948964!5m2!1sen!2sin"
            width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>

<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>
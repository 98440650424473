    <!-- COURSE
    ================================================== -->
    <div class="container-fluid container-wd1 single-lecture">
      <div class="row pt-6 pb-10">
        <div class="col-lg-8">
          <ng-container *ngIf="lessondata">
            <ng-container *ngFor="let lesson of videoDetail[0].lessons">
              <ng-container *ngFor="let video of lesson.videoRow">
                <!-- <a href="https://youtu.be/_jlpjnXMUB8" class="d-block sk-thumbnail rounded mb-3" data-fancybox>
            <div
              class="h-90p w-90p rounded-circle bg-white size-30-all d-inline-flex align-items-center justify-content-center position-absolute center z-index-1">
              
              <i class="fas fa-play fa-2x "></i>

            </div>
            <img class="rounded shadow-light-lg" src="assets/img/lecture/lecture.png" alt="..." style="">
          </a> -->


                <video width="100%" controls class="d-block sk-thumbnail rounded mb-3">
                  <source src="https://videostream.projectnimbus.co.in/PlayVideo?Id={{video.id}}" type="video/webm">
                </video>

               
<!-- 
                <video controls preload="auto" id="video" width="640" height="365" muted>
                  <source src="http://www.sample-videos.com/video/mp4/720/big_buck_bunny_720p_2mb.mp4" type="video/mp4">
                </video> -->
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- lesson info start -->

          <!-- COURSE INFO TAB
                ================================================== -->
          <ul id="pills-tab" class="nav course-tab-v1 border-bottom h4 my-6 pt-1 mt-1" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="pills-overview-tab" data-bs-toggle="pill" href="#pills-overview" role="tab"
                aria-controls="pills-overview" aria-selected="true">Overview</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-question-tab" data-bs-toggle="pill" href="#pills-question" role="tab"
                aria-controls="pills-curriculum" aria-selected="false">Q&A</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-comments-tab" data-bs-toggle="pill" href="#pills-comments" role="tab"
                aria-controls="pills-instructor" aria-selected="false">Comments</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-documents-tab" data-bs-toggle="pill" href="#pills-documents" role="tab"
                aria-controls="pills-instructor" aria-selected="false">Documents</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-reviews-tab" data-bs-toggle="pill" href="#pills-reviews" role="tab"
                aria-controls="pills-reviews" aria-selected="false">Reviews</a>
            </li>
          </ul>

          <div class="tab-content mb-8" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-overview" role="tabpanel"
              aria-labelledby="pills-overview-tab">
              <ng-container *ngIf="lessondata">
                <ng-container *ngFor="let lesson of videoDetail[0].lessons">
                  <ng-container *ngFor="let video of lesson.videoRow">
                    <h3 class="">Description</h3>
                    <p class="mb-6 line-height-md">{{ video.description }}</p>
                  </ng-container>
                </ng-container>
              </ng-container>
              <!-- <h3 class="mb-5">What you'll learn</h3>
               <div class="row row-cols-lg-2 mb-8">
                <div class="col-md">
                  <ul class="list-style-v1 list-unstyled">
                    <li>Become a UI/UX designer.</li>
                    <li>You will be able to start earning money skills.</li>
                    <li>Build a UI project from beginning to end.</li>
                    <li>Work with colors & fonts.</li>
                    <li>You will create your own UI Kit.</li>
                  </ul>
                </div>

                <div class="col-md">
                  <ul class="list-style-v1 list-unstyled ms-xl-6">
                    <li>Build & test a complete mobile app.</li>
                    <li>Learn to design mobile apps & websites.</li>
                    <li>Design 3 different logos.</li>
                    <li>Create low-fidelity wireframe.</li>
                    <li>Downloadable exercise files.</li>
                  </ul>
                </div>
              </div> -->


            </div>

            <div class="tab-pane fade" id="pills-question" role="tabpanel" aria-labelledby="pills-question-tab">
              <h3 class="">Question & Answer</h3>
              <div class="mb-8" id="accordionCurriculum">
                <ng-container *ngIf="lessondata">
                  <ng-container *ngFor="let lesson of videoDetail[0].lessons">
                    <ng-container *ngFor="let video of lesson.videoRow">
                      <ng-container *ngFor="let faqs of video.faq let i=index">

                        <div class="border rounded shadow mb-3 overflow-hidden" data-aos="fade-up">
                          <div class="d-flex align-items-center" id="qaheading{{ i }}">
                            <h5 class="mb-0 w-100">
                              <button
                                class="d-flex align-items-center text-left p-4 min-height-60  fw-medium collapse-accordion-toggle line-height-one"

                                type="button" data-bs-toggle="collapse" attr.data-bs-target="#qacollapse{{i}}"
                                aria-expanded="false" attr.aria-controls="qacollapse{{i}}">
                                {{faqs.questions}}


                                <span class="ms-auto text-dark d-flex">
                                  <!-- Icon -->
                                  <i class="fas fa-minus"></i>
                                  <i class="fas fa-plus"></i>
                                </span>
                              </button>
                            </h5>
                          </div>

                          <div id="qacollapse{{i}}" class="collapse" attr.aria-labelledby="qaheading{{i}}"

                            data-parent="#accordionCurriculum">
                            <div class="p-3 border-top">
                              <p class="mb-0"> {{ faqs.answers }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>

            <div class="tab-pane fade" id="pills-comments" role="tabpanel" aria-labelledby="pills-comments-tab">
              <h3 class="mb-6">Comments</h3>

              <ul class="list-unstyled pt-2">
                <ng-container *ngIf="videoComments">
                  <ng-container *ngFor="let comments of videoComments">
                    <li class="media d-flex">
                      <!-- <div class="avatar avatar-xxl me-3 me-md-6 flex-shrink-0">
                          <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                        </div> -->
                      <div class="media-body flex-grow-1">
                        <div class="d-md-flex align-items-center mb-5">
                          <div class="me-auto mb-4 mb-md-0">
                            <h5 class="mb-0">{{comments.clientName}}</h5>

                          </div>
                          <div class="star-rating">
                            <div class="rating" [style.width.%]="((comments?.stars/5)*100)"></div>
                          </div>
                        </div>
                        <p class="mb-6 line-height-md" [innerHTML]="comments.reviews"></p>
                      </div>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>




            </div>

            <div class="tab-pane fade" id="pills-documents" role="tabpanel" aria-labelledby="pills-documents-tab">
              <h3 class="mb-6">Documents</h3>
              <ul class="list-inline">
                <ng-container *ngIf="videoDetail">
                  <ng-container *ngFor="let lesson of videoDetail[0].lessons">
                    <ng-container *ngFor="let video of lesson.videoRow">
                      <ng-container *ngFor="let document of video.notesPath">
                        <li>
                          <div class="border-top px-4 border-color py-2 min-height-70 d-md-flex align-items-center">
                            <div class="d-flex align-items-center me-auto mb-4 mb-md-0">
                              <div class="text-secondary d-flex">
                                {{ document.id }}
                              </div>
                              <div class="ms-4">
                                {{document.title}}
                              </div>
                            </div>

                            <div class="d-flex align-items-center overflow-auto overflow-md-visible flex-shrink-all">
                              <div class="badge btn-orange text-white me-5 font-size-sm fw-normal py-2">

                                <a href="{{ apiURL }}{{ document.path }}" class="text-dark d-flex" download target="_blank">

                                  Download PDF
                                </a></div>
                            </div>
                          </div>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ul>


            </div>
            <div class="tab-pane fade" id="pills-reviews" role="tabpanel" aria-labelledby="pills-reviews-tab">
              <h3 class="mb-3">Reviews</h3>
              <div class="row align-items-center mb-3">
                <ng-container *ngIf="videoDetail">
                  <ng-container *ngFor="let lesson of videoDetail[0].lessons">
                    <ng-container *ngFor="let video of lesson.videoRow">
                      <div class="col-md-auto mb-5 mb-md-0">
                        <!-- <div class="border rounded shadow d-flex align-items-center justify-content-center px-4 py-4">
                          <div class="m-2 text-center">
                            <h1 class="mb-0 fw-medium mb-n1">{{ video.reviews }}</h1>
                            <h5 class="mb-0">Course rating</h5>
                            <div class="star-rating">
                              <div class="rating" [style.width.%]="(video.reviews/5)*100"></div>
                            </div>
                          </div>
                        </div> -->
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
                <div class="col-md">
                  <div class="d-md-flex align-items-center my-2 my-md-2">
                    <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                      style="height: 10px;">
                      <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 90%;">
                      </div>
                    </div>

                    <div class="d-flex align-items-center">
                      <div class="star-rating star-rating-lg secondary me-4">
                        <div class="rating" style="width:90%;"></div>
                      </div>
                      <span>4132</span>
                    </div>
                  </div>

                  <div class="d-md-flex align-items-center my-2 my-md-2">
                    <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                      style="height: 10px;">
                      <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 60%;">
                      </div>
                    </div>

                    <div class="d-flex align-items-center">
                      <div class="star-rating star-rating-lg secondary me-4">
                        <div class="rating" style="width:60%;"></div>
                      </div>
                      <span>150</span>
                    </div>
                  </div>

                  <div class="d-md-flex align-items-center my-2 my-md-2">
                    <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                      style="height: 10px;">
                      <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 50%;">
                      </div>
                    </div>

                    <div class="d-flex align-items-center">
                      <div class="star-rating star-rating-lg secondary me-4">
                        <div class="rating" style="width:50%;"></div>
                      </div>
                      <span>50</span>
                    </div>
                  </div>

                  <div class="d-md-flex align-items-center my-2 my-md-2">
                    <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                      style="height: 10px;">
                      <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 35%;">
                      </div>
                    </div>

                    <div class="d-flex align-items-center">
                      <div class="star-rating star-rating-lg secondary me-4">
                        <div class="rating" style="width:35%;"></div>
                      </div>
                      <span>32</span>
                    </div>
                  </div>

                  <div class="d-md-flex align-items-center my-2 my-md-2">
                    <div class="bg-gray-200 position-relative rounded-pill flex-grow-1 me-md-5 mb-2 mb-md-0 mw-md-260p"
                      style="height: 10px;">
                      <div class="bg-teal rounded-pill position-absolute top-0 left-0 bottom-0" style="width: 15%;">
                      </div>
                    </div>

                    <div class="d-flex align-items-center">
                      <div class="star-rating star-rating-lg secondary me-4">
                        <div class="rating" style="width:15%;"></div>
                      </div>
                      <span>1</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="border shadow rounded p-6 p-md-6">
                <h3 class="mb-2">Add Reviews & Rate</h3>
                <div class="">What is it like to Course?</div>

                <form [formGroup]="addreviewsForm">


                  <!-- [formGroup]="addreviewsForm"(ngSubmit)="playVideo()"  -->


                  <div class="clearfix">
                    <fieldset class="slect-rating mb-3">
                      <input type="radio" id="star5" formControlName="rating" name="rating" value="5" />
                      <label class="full" for="star5" title="Awesome - 5 stars"></label>

                      <input type="radio" id="star4half" formControlName="rating" name="rating" value="4.5" />
                      <label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>

                      <input type="radio" id="star4" formControlName="rating" name="rating" value="4" />
                      <label class="full" for="star4" title="Pretty good - 4 stars"></label>

                      <input type="radio" id="star3half" formControlName="rating" name="rating" value="3.5" />
                      <label class="half" for="star3half" title="Meh - 3.5 stars"></label>

                      <input type="radio" id="star3" formControlName="rating" name="rating" value="3" />
                      <label class="full" for="star3" title="Meh - 3 stars"></label>

                      <input type="radio" id="star2half" nformControlName="rating" ame="rating" value="2.5" />
                      <label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>

                      <input type="radio" id="star2" formControlName="rating" name="rating" value="2" />
                      <label class="full" for="star2" title="Kinda bad - 2 stars"></label>

                      <input type="radio" id="star1half" formControlName="rating" name="rating" value="1.5" />
                      <label class="half" for="star1half" title="Meh - 1.5 stars"></label>

                      <input type="radio" id="star1" formControlName="rating" name="rating" value="1" />
                      <label class="full" for="star1" title="Sucks big time - 1 star"></label>

                      <input type="radio" id="starhalf" formControlName="rating" name="rating" value="0.5" />
                      <label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
                    </fieldset>
                  </div>
                  <!-- 
                  <div class="form-group mb-6">
                    <label for="exampleInputTitle1">Review Title</label>
                    <input type="text" class="form-control placeholder-1" id="exampleInputTitle1" placeholder="Courses">
                  </div> -->

                  <div class="form-group mb-6">
                    <label for="exampleFormControlTextarea1">Review Content</label>
                    <textarea formControlName="review" name="review" class="form-control placeholder-1"
                      id="exampleFormControlTextarea1" rows="3" placeholder="Content"></textarea>
                  </div>
                  <button type="submit" class="btn btn-primary btn-sm btn-block mw-md-300p">SUBMIT REVIEW</button>
                </form>
              </div>
            </div>
          </div>

          <!-- Lesson info End -->

          <!-- <div class="d-md-flex align-items-center justify-content-between mb-6 ">
            <a href="#"
              class="btn btn-primary btn-sm d-flex align-items-center mb-5 mb-md-0 btn-block mw-md-280p justify-content-center">
              <i class="fas fa-arrow-left font-size-xs"></i>
              <span class="ms-3">Introduction</span>
            </a>
            <a href="#"
              class="btn btn-primary btn-sm d-flex align-items-center btn-block mw-md-280p justify-content-center mt-0">
              <span class="me-3">Structure of the course</span>
              <i class="fas fa-arrow-right font-size-xs"></i>
            </a>
          </div> -->
        </div>

        <div class="col-lg-4">
          <div class="bg-gray-200 rounded p-3">
            <!-- <ul class="bg-white list-inline mb-0">

              <li>
                <div class="border-top px-4 border-color py-2 min-height-70 d-md-flex align-items-center">
                  <div class="d-flex align-items-center me-auto mb-4 mb-md-0">

                    <div>
                      <h3 class="py-4 mb-0"> Lectures Playlist </h3>
                    </div>
                  </div>
                  <div class="d-flex align-items-center overflow-auto overflow-md-visible flex-shrink-all">
                    <nav aria-label="Page navigationa">
                      <ul class="pagination justify-content-center">
                        <li class="page-item">
                          <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true"><i class="fas fa-fast-backward text-dark"></i></span>
                          </a>
                        </li>

                        <li class="page-item">
                          <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true"><i class="fas fa-fast-forward text-dark"></i></span>
                          </a>
                        </li> 
                      </ul>
                    </nav>
                  </div>
                </div>
              </li>
              <li class="lesson-box">
                <div class="border-top px-8 border-color py-2 min-height-70 d-block align-items-center">
                  <div class="d-flex align-items-center mb-4 mb-md-0">
                    <div class="text-secondary d-flex">

                    </div>
                    
                  </div>

                </div>
              </li>
            </ul> -->
            
            <ul class="bg-white list-inline border-bottom border-color mb-0 lesson-heading">
                <li>
                    <div class="border-top px-4 border-color py-2 min-height-70 d-md-flex align-items-center">
                        <div class="d-flex align-items-center me-auto mb-4 mb-md-0">
                            <div>
                                <h3 class="py-4 mb-0 text-white"> Lectures Playlist </h3>
                            </div>
                        </div>
                        <!-- <div class="d-flex align-items-center overflow-auto overflow-md-visible flex-shrink-all">
                            <nav aria-label="Page navigationa">
                                <ul class="pagination justify-content-center">
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true"><i
                                                    class="fas fa-fast-backward text-dark"></i></span>
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true"><i
                                                    class="fas fa-fast-forward text-dark"></i></span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>

                        </div> -->
                    </div>
                </li>
            </ul>

            <ul class="bg-white list-inline mb-0 lesson-playlist">
              <li>
                <app-lesson [lessonData]="lessondata" (newVideoEvent)="playVideo($event)"></app-lesson>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

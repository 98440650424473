import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit, AfterViewInit {
  expertData: any;

  constructor(private indexService: IndexService) { }
  ngAfterViewInit(): void {
    this.getExpert()
  }

  ngOnInit(): void {
  }

  getExpert() {
    this.indexService.getExpert('6ce43ef1-90fd-9213-ca78-3a158638dcd8').subscribe((resExpert: any) => {
      if (resExpert) {
        this.expertData = resExpert
      } else {
        this.expertData = undefined
      }
    })
  }

  onActivate() {
    this.indexService.onActivate()
  }

}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { lesson, video } from '../modals/lesson';
import { CourseService } from '../services/course.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
@Component({
  selector: 'app-lesson-single',
  templateUrl: './lesson-single.component.html',
  styleUrls: ['./lesson-single.component.scss']
})
export class LessonSingleComponent implements OnInit {
  viewCourse = 0;
  lessondata: any;
  video!: video;
  videoDetail: any;
  apiURL = environment.url
  videoComments: any;
  videos: any;
  addreviewsForm!: FormGroup;

  vid: any = document.getElementById("video");
  duration = 0; // will hold length of the video in seconds
  watched: any = new Array(0);
  reported80percent = false;
  @ViewChild('videoel') videoel: any;
  constructor(private courseService: CourseService, private ActivateRoute: ActivatedRoute, private toastr: ToastrService, private formBuilder: FormBuilder,
    private cryptojsService:CryptojsService
  ) { }

  ngAfterViewInit() {
    console.log(this.videoel,"videoel");
    }
  ngOnInit(): void {
    this.ActivateRoute.params.subscribe(data => {
      this.viewCourse = data.Id
      // console.log("data", data)
      this.addreviewsForm = this.formBuilder.group({
        review: ['', Validators.required],
        rating: ['', Validators.required],
      });

      this.courseService.LessonDetails(this.viewCourse).subscribe(res => {
        this.lessondata = res;
        // console.log("lessondatas:", this.lessondata);
      })

      let courseId = this.ActivateRoute.snapshot.paramMap.get('Id');
      this.courseService.VideoWiseDetails(this.viewCourse, '').subscribe(res => {
        this.videoDetail = res;
        // console.log(this.videoDetail,"this.videoDetail")

        // console.log("videoDetail:", this.videoDetail);
      })

      //Video Comments
      this.courseService.videoComments(this.viewCourse).subscribe(res => {
        this.videoComments = res;
        // console.log("videocomments:", this.videoComments);
      })

      // Add reviews to Video
      if (this.addreviewsForm.invalid) {
        return;
      }
      let client_Id = this.cryptojsService.decryptLocalUserId()
      let course_Id = this.ActivateRoute.snapshot.paramMap.get('Id');
      this.videos = this.viewCourse
      // console.log(this.videos,'video')

      this.courseService.addReviewToVideo(client_Id, course_Id, this.videos, this.addreviewsForm.value.rating, this.addreviewsForm.value.review)
        .subscribe(result => {
          //console.log(result)
          this.toastr.success("Review Added to Video.")
        });

    })

    document.addEventListener('click', (clickEvent: MouseEvent) => {
      console.log('Click Event Details: ', clickEvent)
    })


  }

  playVideo(id: any) {
    this.courseService.LessonDetails(this.viewCourse).subscribe(res => {
      this.lessondata = res;
      // console.log("lessondatas:", this.lessondata);
    })

    let courseId = this.ActivateRoute.snapshot.paramMap.get('Id');
    this.courseService.VideoWiseDetails(courseId, id).subscribe(res => {
      this.videoDetail = res;
      // console.log(this.videoDetail, "this.videoDetail")

      // console.log("videoDetail:", this.videoDetail);
    })

    //Video Comments
    this.courseService.videoComments(id).subscribe(res => {
      this.videoComments = res;
      // console.log("videocomments:", this.videoComments);
    })

    // Add reviews to Video
    if (this.addreviewsForm.invalid) {
      return;
    }
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.ActivateRoute.snapshot.paramMap.get('Id');
    this.videos = id
    // console.log(this.videos,'video')

    this.courseService.addReviewToVideo(client_Id, course_Id, this.videos, this.addreviewsForm.value.rating, this.addreviewsForm.value.review)
      .subscribe(result => {
        //console.log(result)
        this.toastr.success("Review Added to Video.")
      });

  }


  videoPercenatge(e: any, video: any) {
    // handler to let me resize the array once we know the length
    function resize(arr: any, newSize: any, defaultValue: any) {
      while (newSize > arr.length)
        arr.push(defaultValue);
      arr.length = newSize;
    }

    // function to round up a number
    function roundUp(num: number, precision: number) {
      return Math.ceil(num * precision) / precision
    }

    if (this.vid) {
      this.vid.addEventListener('loadedmetadata', this.getDuration, false);
      this.vid.addEventListener('timeupdate', this.timeupdate, false)
    }

    console.log('metadata: ', e);
    console.log('duration: ', this.duration = video.duration);

  }


  timeupdate() {
    let currentTime = parseInt(this.vid.currentTime);
    // set the current second to "1" to flag it as watched
    this.watched[currentTime] = 1;

    // when closer to the 80%...
    console.log(this.watched);

    // sum the value of the array (add up the "watched" seconds)
    var sum = this.watched.reduce(function (acc: any, val: any) { return acc + val; }, 0);
    // take your desired action on the ?80% completion
    if ((sum >= (this.duration * .8)) && !this.reported80percent) {

      // vid.removeEventListener('timeupdate',timeupdate)
      this.reported80percent = true;
      console.log("80% watched...")
      //  report progress could go here...   
    }
  }

  getDuration() {
    console.log("duration:" + this.vid.duration)
    // get the duration in seconds, rounding up, to size the array
    this.duration = Math.round(parseFloat(this.vid.duration));
    // resize the array, defaulting entries to zero
    console.log("resizing arrary to " + this.duration + " seconds.");
    this.watched.resize(this.duration, 0)
  }

}




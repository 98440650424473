import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeConvertor'
})
export class TimeConvertorPipe implements PipeTransform {
  transform(value: number): string {
    let hours = Math.floor(value / 3600);
    let minutes = Math.floor(value % 60);
    let seconds = Math.floor(value % 60);
    return hours + 'h' + ' ' + minutes + 'm' + ' ' + seconds + 's' ;
  }
  }


<div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-6">
            <div class="breadcrumb-main-wrapper">
              <h1 class="title">Our Programmes</h1>
              <div class="pagination-wrapper  c-pointer">
                <a routerLink="/index">Home</a>
                <i class="fa-regular fa-chevron-right"></i>
                <a class="active">All Course</a>
              </div>
            </div>

          </div>
          <div class="col-6">
            <div class="col-6">
              <div class="breadcrumb-img-book">
                <img src="\assets\images\breadcrumb\book.png" alt="">
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="rts-course-default-area rts-section-gap">
  <div class="container">
    <div class="row ">
      <div class="col-12">
        <div class="filter-small-top-full">
          <div class="left-filter">
            <span>Sort By</span>
            <select class="nice-select" name="price" (change)="changeCategory($event)">
              <option value="">Select</option>
              <option *ngFor="let category of courseCategory" [value]="category.categoryName">
                {{category.categoryName}}</option>
            </select>
          </div>
        </div>


        <div class="row mt--20 g-5">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center" *ngFor="let cou of courses;">

            <div class="single-course-style-three">
              <a class="thumbnail c-pointer">
                <img routerLink="/courses/course-details/{{cou.id}}" [src]="apiURL + cou?.bannerImg" alt="course">
                <div class="heart-icon" (click)="toggleHeart(cou?.favourite,cou?.id)" [ngClass]="{'active': cou?.favourite}">
                  <i class="fa fa-heart"></i>
                </div>
              </a>
              <div class="body-area">
                <div class="course-top">
                  <div class="tags">Best Seller</div>
                  <div class="price text-decoration-line-through"> {{cou?.actual_Price}}/-</div>

                  <div class="price"> &#8377; {{cou?.offer_Price}} </div>
                </div>
                <a routerLink="/course-details">
                  <h5 class="title"> {{cou?.name}}</h5>
                </a>
                <div class="teacher-stars">
                  <div class="teacher"><span style="font-size: 18px;">Prof {{cou?.authorName}}</span></div>
                  <ul class="stars">
                    <li class="span">4.5</li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star-half-stroke"></i></li>

                  </ul>
                </div>
                <div class="leasson-students">
                  <div class="lesson">
                    <i class="fa-light fa-calendar-lines-pen"></i>
                    <span>{{cou?.lessonsCount}} Lessons</span>
                  </div>
                  <div class="students">
                    <i class="fa-light fa-users"></i>
                    <span>Online Learning</span>
                  </div>
                </div>
              </div>

            </div>


          </div>
          <div class="row mt--20 g-5" style="display: flex; justify-content: center;" *ngIf="courses.length == 0">
            <div class="single-course-style-three">
              <div class="row">
                <img src="\assets\images\course\Course-not-Found.jpg" alt="">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  <div class="bubbles_wrap">
    <div class="bubble x1"></div>
    <div class="bubble x2"></div>
    <div class="bubble x3"></div>
    <div class="bubble x4"></div>
    <div class="bubble x5"></div>
    <div class="bubble x6"></div>
    <div class="bubble x7"></div>
    <div class="bubble x8"></div>
    <div class="bubble x9"></div>
    <div class="bubble x10"></div>
  </div>
  <a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
    <i class="fa fa-arrow-up" aria-hidden="true"></i>
  </a>
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/shared/services/account.service';
import { CryptojsService } from '../core/services/cryptojs.service';
import { CartService } from './services/cart.service';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class CheckCartGuard implements CanActivate {

  constructor(private AccountService: AccountService, private router: Router, private cryptojsService: CryptojsService, private cartService:CartService,
    private toastrService:ToastrService
  ) {

  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.cryptojsService.decryptLocalAccessToken() && this.cartService.cartCoursePresent()) {
      // logged in so return true
      return true;
    }
    // not logged in so redirect to login page with the return url and return false
    this.toastrService.warning("Please add at least one course to the cart!");
    this.router.navigate(['/course/courses']);
    return false;
  }

}

<header class="py-4 py-md-4 main-banner-in">
  <div class="container-fluid text-center py-xl-2">
      <h2 class="fw-semi-bold mb-0">BDs admission form</h2>
  </div>
  <!-- Img -->
</header>
<div class="container-fluid mb-8">
  <div class="row gx-0">
    <div class="col-md-12 col-xl-8 mx-auto">
      <!-- Login -->
      <!-- Form Login -->
    
      <form [formGroup]="IbcForm" (ngSubmit)="onSubmit()" enctype="multipart/form-data" class="mb-5 bg-white border border-orange p-5">
        <div class="row">
          <div class="col-md-6 form-label-group">
            <input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
              name="email" class="form-control form-control-sm" id="Email" placeholder="Email" email="true">
            <label for="cardEmail">Email</label>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
            </div>
          </div>
          <div class="col-md-6 form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name"
              name="name" class="form-control form-control-sm" id="name" placeholder="Name">
            <label for="cardName">Name</label>
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
          </div>


        </div>
        <div class="row">
          <div class="col-md-6 form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }"
              formControlName="contactNo" name="contactNo" class="form-control form-control-sm"
              id="contactNo" placeholder="Mobile no">
            <label for="cardEmail">Contact no</label>
            <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
              <div *ngIf="f.contactNo.errors.required">Phone Number is required</div>
              <div *ngIf="f.contactNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
            </div>
          </div>

          <div class="col-md-6 form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.whatsAppNo.errors }"
              formControlName="whatsAppNo" name="whatsAppNo" class="form-control form-control-sm"
              id="whatsAppNo" placeholder="Mobile no">
            <label for="cardEmail">Whatsapp no</label>
            <div *ngIf="submitted && f.whatsAppNo.errors" class="invalid-feedback">
              <div *ngIf="f.whatsAppNo.errors.required">Whatsapp Number is required</div>
              <div *ngIf="f.whatsAppNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.district.errors }"
              formControlName="district" name="district" class="form-control form-control-sm"
              id="Contact" placeholder="Mobile no">
            <label for="cardEmail">District</label>
            <div *ngIf="submitted && f.district.errors" class="invalid-feedback">
              <div *ngIf="f.district.errors.required">District is required</div>
            </div>
          </div>

          <div class="col-md-6 form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.bankName.errors }" formControlName="bankName"
              name="bankName" class="form-control form-control-sm" id="bankName" placeholder="bank name">
            <label for="cardEmail">Bank Name</label>
            <div *ngIf="submitted && f.bankName.errors" class="invalid-feedback">
              <div *ngIf="f.bankName.errors.required">Bank Name is required</div>
            </div>
          </div>
        </div>        
        <div class="row">
          <div class="col-md-6 form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.accountHolderName.errors }"
              formControlName="accountHolderName" name="accountHolderName" class="form-control form-control-sm"
              id="Contact" placeholder="Mobile no">
            <label for="cardEmail">Bank Holder Name</label>
            <div *ngIf="submitted && f.accountHolderName.errors" class="invalid-feedback">
              <div *ngIf="f.accountHolderName.errors.required">Bank Holder Name is required</div>
            </div>
          </div>

          <div class="col-md-6 form-label-group">
            <input type="number" [ngClass]="{ 'is-invalid': submitted && f.accountNo.errors }" formControlName="accountNo"
              name="accountNo" class="form-control form-control-sm" id="accountNo" placeholder="account">
            <label for="account">Account No</label>
            <div *ngIf="submitted && f.accountNo.errors" class="invalid-feedback">
              <div *ngIf="f.accountNo.errors.required">Account No. is required</div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.ifscCode.errors }"
              formControlName="ifscCode" name="ifscCode" class="form-control form-control-sm"
              id="ifscCode" placeholder="ifsc no">
            <label for="cardEmail">IFSC Code</label>
            <div *ngIf="submitted && f.ifscCode.errors" class="invalid-feedback">
              <div *ngIf="f.ifscCode.errors.required">IFSC Number is required</div>
              <div *ngIf="f.ifscCode.errors.pattern">Please, Enter Valid IFSC Code Format.</div>
            </div>
          </div>

          <div class="col-md-6 form-label-group">
            <textarea formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
              name="address" class="form-control form-control-sm form-control-flush border-dark"
              id="exampleFormControlTextarea1" rows="2" placeholder="address"></textarea>
            <label for="exampleFormControlTextarea1">Address</label>
            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
              <div *ngIf="f.address.errors.required">Address is required</div>
            </div>
          </div>
        </div>
        <!-- Submit -->
        <button class="btn btn-block btn-primary btn-sm" type="submit">
          Register 
        </button>
      </form>
      <!-- Text -->
    </div>
  </div>
</div>

  <!-- PAGE TITLE
    ================================================== -->
    <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="breadcrumb-main-wrapper">
                                <h1 class="title">Privacy Policy </h1>
                                <div class="pagination-wrapper">
                                    <a routerLink="/index">Home</a>
                                    <i class="fa-regular fa-chevron-right"></i>
                                    <a class="active" href="Instructor-2.html">Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="breadcrumb-img-book">
                                <img src="\assets\images\breadcrumb\book.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <main class="policy-container">
        <section class="policy-header">
            <h2>Privacy Policy</h2>
            <p class="effective-date">Effective Date: <strong>[29/10/2024]</strong></p>
            <p class="intro">Your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your information.</p>
        </section>

        <!-- Policy Sections in Rows -->
        <div class="policy-row">
            <h3>1. Information We Collect</h3>
            <p>We collect information that you provide directly to us, such as your name, email address, and other details required for registration and support.</p>
        </div>

        <div class="policy-row">
            <h3>2. How We Use Your Information</h3>
            <p>Your information helps us operate and improve our services, deliver course recommendations, and communicate updates.</p>
        </div>

        <div class="policy-row">
            <h3>3. Data Security</h3>
            <p>We implement security measures to protect your data, including restricted access and secure protocols for data transfer and storage.</p>
        </div>

        <div class="policy-row">
            <h3>4. Sharing with Third Parties</h3>
            <p>We may share information with third-party partners for analytics, payments, and advertising. These partners are bound to protect your data.</p>
        </div>

        <div class="policy-row">
            <h3>5. Cookies</h3>
            <p>We use cookies to enhance your experience and analyze site usage. You can manage cookie preferences through browser settings.</p>
        </div>

        <div class="policy-row">
            <h3>6. Your Rights</h3>
            <p>You have the right to access, update, or delete your data. Reach us at <a href="mailto:antarkarsir1@gmail.com">antarkarsir1@gmail.com</a> for assistance.</p>
        </div>

        <div class="policy-row">
            <h3>7. Policy Changes</h3>
            <p>We may update this Privacy Policy periodically. Any changes will be posted on this page with an updated effective date.</p>
        </div>

        <footer class="policy-footer">
            <p>For inquiries, please contact us at <a href="mailto:antarkarsir1@gmail.com">antarkarsir1@gmail.com</a>.</p>
        </footer>
    </main>
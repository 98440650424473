import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Blog } from '../modals/bolg';
import { BlogService } from '../services/blog.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
@Component({
  selector: 'app-blog-single',
  templateUrl: './blog-single.component.html',
  styleUrls: ['./blog-single.component.scss']
})
export class BlogSingleComponent implements OnInit {
  viewblog = 0;
  viewData!: Blog;
  apiURL = environment.url;
  addreviewsForm!: FormGroup;
  submitted = false;

  constructor(private blogService: BlogService, private ActivateRoute: ActivatedRoute, private toastr: ToastrService, private formBuilder: FormBuilder
    ,private cryptojsService:CryptojsService
  ) { }

  ngOnInit(): void {

    this.ActivateRoute.params.subscribe(data => {
      this.viewblog = data.Id.toString()
      // console.log(this.viewblog,'blog_id');    
      this.addreviewsForm = this.formBuilder.group({
        stars: ['', Validators.required],
        reviewTitle: ['', Validators.required],
        reviews: ['', Validators.required],
      });
    })

    this.blogService.ViewBlog(this.viewblog).subscribe(view => {
      this.viewData = view;
      // console.log(this.viewData, 'days')

    })
  }
  get f() { return this.addreviewsForm.controls; }

  // Review for blog
  addReviewToBlog() {
    this.submitted = true;
    if (this.addreviewsForm.invalid) {
      return;
    }
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let blogId = this.ActivateRoute.snapshot.paramMap.get('Id');
    //console.log(course_Id, client_Id)

    this.blogService.postBlogComment(client_Id, blogId, this.addreviewsForm.value.stars, this.addreviewsForm.value.reviewTitle, this.addreviewsForm.value.reviews)
      .subscribe(result => {
        //console.log(result)
        this.toastr.success("Review Added.")
        this.addreviewsForm.reset();
      }, err => {
        this.toastr.error("Review Not Added.")
      })
  }


}

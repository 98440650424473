import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CourseService } from 'src/app/courses/services/course.service';
import { environment } from 'src/environments/environment';
import { Course } from '../../modals/course';
import { Expert } from '../../modals/expert';
import { Testimonials } from '../../modals/testimonials';
import { IndexService } from '../../services/index.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../../services/account.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  courses: any;
  courseDisplay:any;
  experts: any;
  testimonials: any;
  users: any;
  course: Course[] = [];
  expert: Expert[] = [];
  zoom: any;
  data = [];
  apiURL = environment.url;
  exp: any;
  cards: any;
  duration: any;
  contactUsForm!: FormGroup;
  submitted = false;

  phoneNumber: string = '8828228330';  // Enter the phone number in international format
  defaultMessage: string = '“Hello, I would like to know more about the course.”';
  isHeartActive:boolean=false;

  attributesAdded: boolean = false; 
  modalToggle = 'modal'; 
  modalTarget = '#admission-form-modal'; 
  buttonId = 'admission-form-btn'; 
  userPresent: boolean = false;
  Id: any;
  subscription: any=[];
  admissionForm!:FormGroup;

  constructor(private indexService: IndexService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder,
    private toastr: ToastrService,private accountservice: AccountService,private cryptojsService:CryptojsService
  ) { }



  ngOnInit(): void {
    this.createAdmissionForm()
    this.contactUsFormFunction()
    this.getAllCourses();
    this.getAllExpert();
    this.getAllTestimonials();
    this.getZoomMeet();
    this.Id = this.cryptojsService.decryptLocalUserId()
    this.mySubscription()

  }


  createAdmissionForm(){
    this.admissionForm=this.formBuilder.group({
      name:['',Validators.required],
      fathername:['',Validators.required],
      surname:['',Validators.required],
      birthdate:['',Validators.required],
      guardianmobileno:['',[Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      yearofappearingforjee:['',Validators.required],
      studenttype:['',Validators.required],
      howdidyoucometoknowaboutthiscourse:['',Validators.required],

    })
  }

  onSubmitAdmission(){
    // console.log("get data---->",this.admissionForm);
    if (this.admissionForm.valid) {
      this.admissionForm.reset()
      document.getElementById('admissionFormCloseId')?.click()
      // handle form submission
    } else {
      this.admissionForm.markAllAsTouched(); // Mark all fields as touched to show validation messages
    }
  }

  

  contactUsFormFunction() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required,  Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      phone_Number: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
      message: ['', Validators.required],
    });
  }

  getAllCourses() {
    this.courses = [];
    this.indexService.getCourse().subscribe(response => {
      this.courses = response['items'];
      if(this.courses.length > 0){
        this.courseDisplay=this.courses[this.courses.length - 1]
      }
    }, err => {

    })

  }

  getAllExpert() {
    this.indexService.getExpertTeam().subscribe(res => {
      this.experts = res['items'];
    }, err => {

    })
  }

  displayStyle = "none";
  openDetails() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }

  getAllTestimonials() {
    this.indexService.getTestimonials().subscribe(res => {
      this.testimonials = res['items'];
    }, err => {

    })

  }

  getZoomMeet() {
    this.zoom = [];
    this.indexService.zoomMeet().subscribe(res => {
      this.zoom = res['items'];
    }, err => {
    })
  }

  onActivate() {
    this.indexService.onActivate()
  }


  contactUs() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      this.contactUsForm.markAllAsTouched()
      return;
    }

    this.indexService.contactUs(this.contactUsForm.value)
      .subscribe(result => {
        this.toastr.success("Form submitted Successfully.")
        this.contactUsForm.reset();
        this.submitted = false;
      });
  }

  getWhatsAppLink(): string {
    return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.defaultMessage)}`;
  }

  toggleHeart() {
    this.isHeartActive = !this.isHeartActive;
  }

  checkUserPresence(callback: (present: boolean) => void) {
    this.cryptojsService.userLoginSignal$.subscribe((status: any) => {
      this.userPresent = status;
      callback(this.userPresent);
    });
  }

  handleClick() {
    this.checkUserPresence((present) => {
      if (present) {
        this.attributesAdded = true; 
      } else {
        this.toastr.warning('Please login for applying admission form')
      }
    });
  }


  mySubscription() {
    this.accountservice.mySubscription(this.Id).subscribe(response => {
      this.subscription = response
      if(this.subscription.length == 1){
        document.getElementById("openAdmissionForm")?.click()
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { ToastrService } from 'ngx-toastr';
import { cart } from '../../modals/cart';
import { environment } from 'src/environments/environment';
import { IndexService } from '../../services/index.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { CourseService } from 'src/app/courses/services/course.service';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  changePasswordForm!: FormGroup;
  editProfile!: FormGroup;
  isSubmitted: boolean = false;
  loading = false;
  details: any;
  phoneNumber: any;
  surname: any;
  email: any;
  name: any;
  auth_token!: any;
  wishlist: any = []
  apiURL = environment.url
  Id: any;
  reviews!: any;
  subscription: any = [];
  submitted = false;

  getPatchResponseValue: any;
  showpassword: boolean = false;
  shownewpassword: boolean = false;

  ratingCommentForm!: FormGroup;
  isHeartActive: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private router: Router, private accountservice: AccountService, private indexservice: IndexService, private route: ActivatedRoute, private toastr: ToastrService,
    private cryptojsService: CryptojsService, private courseService: CourseService
  ) { }

  ngOnInit(): void {
    this.editFormFunction()
    this.editPasswordForm()
    this.Id = this.cryptojsService.decryptLocalUserId()
    this.userLoginUserData()
    this.mySubscription()
    this.feedbackForm()

    this.myWishlist()
  }

  feedbackForm() {
    this.ratingCommentForm = this.formBuilder.group({
      ratings: ['', Validators.required],
      comments: ['', Validators.required],
      userId: ['', Validators.required],
      courseId: ['', Validators.required]
    });
  }

  onRatingCommentForm() {
    if (this.Id) {
      this.ratingCommentForm.controls['userId'].setValue(this.Id)
    }
    if (this.ratingCommentForm.valid) {
      let data: any = {
        clientId: this.ratingCommentForm.value.userId,
        courseId: this.ratingCommentForm.value.courseId,
        review: this.ratingCommentForm.value.comments,
        star: this.ratingCommentForm.value.ratings,
        approvalFlag: false,
      }
      this.courseService.addReviewToCourse(data).subscribe((res: any) => {
        if (res) {
          document.getElementById("feedbackId")?.click()
          this.ratingCommentForm.reset()
        }
      })
    } else {
      this.ratingCommentForm.markAllAsTouched()
    }

  }

  getCourseId(course: any) {
    if (course) {
      this.ratingCommentForm.controls['courseId'].setValue(course?.courseId)
    }
  }

  closeFeedbackModal() {
    this.ratingCommentForm.reset()
  }

  editPasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
    });
  }

  userLoginUserData() {
    this.accountservice.getLoginUserDetails(this.Id).subscribe((resGetLoginDetails: any) => {
      // console.log("get user details",resGetLoginDetails);

      this.editProfile.controls['userName'].patchValue(resGetLoginDetails.userName)
      this.editProfile.controls['name'].patchValue(resGetLoginDetails.name)
      this.editProfile.controls['surname'].patchValue(resGetLoginDetails.surname)
      this.editProfile.controls['email'].patchValue(resGetLoginDetails.email)
      this.editProfile.controls['phoneNumber'].patchValue(resGetLoginDetails.phoneNumber ? resGetLoginDetails.phoneNumber : null)
    })
  }

  editFormFunction() {
    this.editProfile = this.formBuilder.group({
      userId: [''],
      userName: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(/^[^@]+@[^@]+\.[a-z]{2,}$/)]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.maxLength(10), Validators.minLength(10)]],
    })
  }

  onSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.loading = true;
    let data: any = {
      id: this.Id,
      currentPassword: this.changePasswordForm.value.currentPassword,
      newPassword: this.changePasswordForm.value.newPassword,
    }
    this.accountservice.changePasswordTwo(data)
      .subscribe(_result => {
        this.toastr.success('Password updated successfully..');
        this.changePasswordForm.reset()
      }, error => {
        if (error) {
          this.toastr.error(error.error.error.message);
        }
      });
  }

  EditProfile() {
    this.submitted = true;
    if (this.editProfile.invalid) {
      this.editProfile.markAllAsTouched()
      return;
    }
    this.editProfile.controls['userId'].setValue(this.Id)
    this.loading = true;
    this.accountservice.editProfileTwo(this.editProfile.value)
      .subscribe((result:any) => {
        this.toastr.success('Profile updated successfully.');
        // this.editProfile.reset()
        this.ngOnInit()
      }, error => {
        if (error) {
          this.toastr.error(error.error.error.message);
        }
      });
  }

  profileDetails(auth_token: any) {
    auth_token = this.cryptojsService.decryptLocalAccessToken()
    this.accountservice.profileDetails(auth_token).subscribe(res => {
      this.details = res;
      // console.log("get details",this.details);

      this.editProfile.controls.name.setValue(this.details.name);
      this.editProfile.controls.surname.setValue(this.details.surname);
      this.editProfile.controls.phoneNumber.setValue(this.details.phoneNumber);
      this.editProfile.controls.email.setValue(this.details.email);

    })

  }

  myWishlist() {
    this.indexservice.myWishlist(this.Id).subscribe(res => {
      if (res.length > 0) {
        this.wishlist = res
      } else {
        this.wishlist = []
      }
      // console.log("get wishlist",this.wishlist);

    })
  }

  myReviews() {
    this.accountservice.myReview(this.Id).subscribe(response => {
      this.reviews = response
    })
  }


  mySubscription() {
    this.accountservice.mySubscription(this.Id).subscribe(response => {
      this.subscription = response
    })
  }

  displayPassWord() {
    this.showpassword = !this.showpassword;
  }

  displayNewPassWord() {
    this.shownewpassword = !this.shownewpassword;
  }

  onActivate() {
    this.indexservice.onActivate()
  }



  toggleHeart(courseId: any) {
    // console.log("get data--->", courseId);
    this.courseService.deleteToWishlist(courseId).subscribe((res: any) => {
      this.myWishlist()
    })
  }

  maxLength(str: any, max: any) {
    if (str.length > max) {
      return str.length > max ? str.substring(0, max) + '...' : str
    } else {
      return str
    }
  }

  setDefaultImage(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '../../../../assets/defaultimg/defaultMainImg.png';
  }

}



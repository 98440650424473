// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: false,
   url:   'https://ohmadmin.activeneuronstech.com',
  // url:   'https://videostream.projectnimbus.co.in',
  //url:'https://192.168.1.22:54',

  razorpaykey: 'rzp_test_LhrG5rKNi8Pacs',
  recaptcha: {
    siteKey: '6Lc6_lsgAAAAALL_UVi1SsztdIULnCutVAj8N0l1',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Course } from '../modals/course';
import { tap, catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { lesson } from '../modals/lesson';
import { cart } from 'src/app/shared/modals/cart';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
@Injectable({
  providedIn: 'root'
})
export class CourseService {
  url = environment.url;
 // public flag: Observable<Course>;


  constructor(private http: HttpClient,  private cryptojsService:CryptojsService) {
   }

  

  //  getPhases() {
  //   return this.http.get(`${this.url}` + '/phases').pipe(tap(res => res),
  //     catchError(err => throwError(new Error('Issue in getting phases'))),
  //   );

  //}

  ViewCourse(Id:any):Observable<Course[]>{
    // const baseurl = "https://videostream.projectnimbus.co.in/api/app/main-courses/course-description/" + Id;
    const baseurl = `${this.url}/api/app/main-courses/course-description/${Id}`;
    return this.http.get<Course[]>(baseurl);

  }

  ViewMainCourse(Id:any):Observable<Course[]>{
    // const baseurl = "https://videostream.projectnimbus.co.in/api/app/main-courses/" + Id;
    const baseurl = `${this.url}/api/app/main-courses/${Id}`;
    return this.http.get<Course[]>(baseurl);

  }

  ViewMainSortCourse(course:any):Observable<Course[]>{
    const baseurl = `${this.url}/api/app/main-courses?Filter=${course}`;
    return this.http.get<Course[]>(baseurl);
  }

  ViewRelatedCourse(Id:any):Observable<Course[]>{
    const baseurl = "https://videostream.projectnimbus.co.in/api/app/main-courses/related-course/" + Id;
    return this.http.get<Course[]>(baseurl);

  }

  razorpayOrder(data: any): Observable<any> {
    return this.http.post( `${this.url}/api/app/razorpaycreateorder`, data)
  }

  razorpayUpdate(data: any): Observable<any> {
    return this.http.post(`${this.url}/api/app/payment/UpdateOnlinePaymentDetails`, data)
  }

  razorpayVerifySignature(data: any): Observable<any> {
    return this.http.post( `${this.url}/api/app/razorpayverifysignature`, data)
  }

  // getLatest
  getLatest(){
    return this.http.get(`${this.url}` + '/api/app/main-courses/latest-course').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting latest course'))),
    );
  }

  getRelatedCourse(course_id:number){
    return this.http.get(`${this.url}` + '/api/app/main-courses/related-course/'+ course_id).pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting related course'))),
    );
  }


  courseInstructor(Id:number){
    return this.http.get(`${this.url}` + '/api/app/main-courses/author-wise-students-count/'+ Id).pipe(tap(res => res),
    catchError(err => throwError(new Error('Issue in getting Instructor Details'))),
  );
  }

  
  searchCourseInstructor(name:string){
    return this.http.get(`${this.url}` + '/api/app/main-courses/search-course?name='+ name).pipe(tap(res => res),
    catchError(err => throwError(new Error('Issue in getting Course Details'))),
  );
  }

  LessonDetails(Id:any):Observable<lesson[]>{
    const baseurl = `${this.url}` + "/api/app/lesson-wise-videos-data/course-wise-videos-data/" + Id;
    return this.http.get<lesson[]>(baseurl);    

  }
  
  videoComments(Id:any){
    const baseurl = "https://videostream.projectnimbus.co.in/api/app/videos-comments/video-wise-comments/" + Id;
    return this.http.get(baseurl);    

  }

  
  CourseComments(CourseId:any){
    const baseurl = "https://videostream.projectnimbus.co.in/api/app/comments/course-wise-comments/" + CourseId;
    return this.http.get(baseurl);    
  }

  CourseTestimonial(CourseId:any){
    const baseurl = "https://videostream.projectnimbus.co.in/api/app/courses-testimonials/course-wise-testimonial/" + CourseId;
    return this.http.get(baseurl);    
  }

  VideoWiseDetails(Id:any,videoId:any):Observable<lesson[]>{
    const baseurl = `https://videostream.projectnimbus.co.in/api/app/lesson-wise-videos-data/lesson-wise-videos-data?Id=${Id}&videoId=${videoId}`;
    return this.http.get<lesson[]>(baseurl);    
  }

  CourseRelatedFaq(CourseId:any){
    const baseurl = "https://videostream.projectnimbus.co.in/api/app/main-courses/course-fAQ/" + CourseId;
    return this.http.get(baseurl);    
  }

  private refreshneeded_ = new Subject<void>();
  get refreshneeded(){
    return this.refreshneeded_
  }

  addToCart(client_Id:any,course_Id:any):Observable<cart>{
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    };

    var data = {
      'client_Id' : client_Id,
      'course_Id' : course_Id
    };

    return this.http.post<cart>(`${this.url}`+'/api/app/add-to-cart',data,options).pipe(
    tap(()=>{
      this.refreshneeded_.next();
   }))
  }
 
  cartCourse(Id: any): Observable<cart[]> {
    const baseurl = `${this.url}/api/app/add-to-cart/cart-items/${Id}`; //"https://videostream.projectnimbus.co.in/api/app/add-to-cart/cart-items/" + Id;
    return this.http.get<cart[]>(baseurl);

  }

  addReviewToCourse(data:any){
    
    return this.http.post(`${this.url}`+'/api/app/comments',data).pipe(tap(res => res),
      catchError(err => throwError(new Error('Something went wrong'))),
    );
  }

  addReviewToVideo(client_Id:any,course_Id:any,video_Id:any,star:any,review:any){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + this.cryptojsService.decryptLocalAccessToken()),
    };

    var data = {
      'clientId' : client_Id,
      'courseId' : course_Id,
      'videoId' :video_Id,
      'stars' :star,
      'reviews':review,
    };
    return this.http.post(`${this.url}`+'/api/app/videos-comments',data,options).pipe(tap(res => res),
      catchError(err => throwError(new Error('Something went wrong'))),
    );
  }


  addToWishlist(client_Id:any,course_Id:any){
    var data = {
      'client_Id' : client_Id,
      'course_Id' : course_Id
    };

    return this.http.post(`${this.url}`+'/api/app/wish-list',data).pipe(tap(),
     
    );
  }

  deleteToWishlist(course_Id:any){
    return this.http.delete(`${this.url}`+`/api/app/wish-list/${course_Id}`).pipe(tap());
  }

  GetSubscriptionData(ClientId: any,CourseId:any) {
    const baseurl = `${this.url}/api/app/course-by-client/purchased-course?ClientId=${ClientId}&CourseId=${CourseId}`; 
    return this.http.get(baseurl); 

  }

}

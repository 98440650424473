import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ChatService } from './services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'videostream';
  href: any;
  url: any;

  constructor(private router: Router,private chatService: ChatService) { }


  ngOnInit(): void {
  this.router.events.subscribe((e) => {
    if (e instanceof NavigationEnd) {
      this.url  = e.url.split('/');
    }
  });                                    
  }

  onActivate() {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}



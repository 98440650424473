import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CourseSingleComponent } from './courses/course-single/course-single.component';
import { CourseComponent } from './courses/course/course.component';
import { LessonSingleComponent } from './courses/lesson-single/lesson-single.component';
import { EventListComponent } from './events/event-list/event-list.component';
import { AboutUsComponent } from './shared/components/about-us/about-us.component';
import { ChangePasswordComponent } from './shared/components/change-password/change-password.component';
import { ForgotPasswordComponent } from './shared/components/forgot-password/forgot-password.component';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { IndexComponent } from './shared/components/index/index.component';
import { LoginComponent } from './shared/components/login/login.component';
import { RegisterComponent } from './shared/components/register/register.component';
import { WhyUsComponent } from './shared/components/why-us/why-us.component';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';
import { MyProfileComponent } from './shared/components/my-profile/my-profile.component';
import { ViewCartComponent } from './cart/view-cart/view-cart.component';
import { CheckoutComponent } from './cart/checkout/checkout.component';
import { SearchComponent } from './shared/components/search/search.component';
import { BlogSingleComponent } from './blogs/blog-single/blog-single.component';
import { BlogComponent } from './blogs/blog/blog.component';
import { AuthguardGuard } from './shared/authguard.guard';
import { AddComponent } from './ibc/add/add.component';
import { ViewComponent } from './ibc/view/view.component';
import { ViewWebinarComponent } from './webinar/ViewWebinar/ViewWebinar.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { CheckCartGuard } from './shared/check-cart.guard';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { OrderSuccessComponent } from './shared/components/order-success/order-success.component';
const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', component: IndexComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'why-us', component: WhyUsComponent },  
  { path: 'privacy-policy', component: PrivacyPolicyComponent },  
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },  
  { path: 'order-success', component: OrderSuccessComponent },  
  { path: 'contact-us', component: ContactUsComponent },
  
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'Account/ResetPassword', component: ResetPasswordComponent,canActivate:[AuthguardGuard] },
  { path: 'change-password', component: ChangePasswordComponent,canActivate:[AuthguardGuard] },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'view-cart', component: ViewCartComponent},
  { path: 'checkout', component: CheckoutComponent,canActivate:[CheckCartGuard] },
  { path: 'search', component: SearchComponent },
  // { path: ':details', component: IndexComponent },
  
  
  { path: 'my-profile', component: MyProfileComponent,canActivate:[AuthguardGuard]},
  //remove from path courses
  {
    path: '', component: CourseComponent,
    loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'courses/course-details/:id', component: CourseSingleComponent,
    // loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'blogs', component: BlogComponent,
    loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule)
  },
  {
    path: 'blog-details/:Id', component:BlogSingleComponent,
    // loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
  },
  
  {
    path: 'lesson-details/:Id', component: LessonSingleComponent,canActivate:[AuthguardGuard]
  },
  
  {
    path: 'events', component: EventListComponent,
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },
  {
    path: 'ibc/register', component: AddComponent,
    loadChildren: () => import('./ibc/ibc.module').then(m => m.IbcModule)
  },
  {
    path: 'ibc/:Username', component: ViewComponent,
    loadChildren: () => import('./ibc/ibc.module').then(m => m.IbcModule)
  },
  {
    path: 'webinar', component: ViewWebinarComponent,
    loadChildren: () => import('./webinar/webinar.module').then(m => m.WebinarModule)
  },
  { path: '**', redirectTo: 'index', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

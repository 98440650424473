

export class checkout {
client_Id!:string;
ibC_Id !: string;
payment_Id!:string;
course_Id!:courses[];
totalOrderAmount!:number;
firstName!:string;
lastName!:string;
country!:string;
state!:string;
address!:string;
city!:string;
postalCode!:string;
phoneNumber!:string;
email!:string;
additionalInfo!:string;
comanyName!:string;
gstNo!:string;
date!: string
paymentMode!:string;
}

export class courses {
    course_Id!: string;
    payment_Data_ID!: string;
    offer_Percentage!: number;
    actual_Price!: number;
    offer_Price!: number;

}


    <!-- PAGE TITLE
    ================================================== -->
    <header class="py-4 py-md-4 main-banner-in">
        <div class="container-fluid text-center py-xl-2">


            <h2 class="fw-semi-bold mb-0">Recover Password</h2>

        </div>
        <!-- Img -->

    </header>


    <!-- LOGIN
    ================================================== -->
    <div class="container-fluid mb-8">
        <div class="row gx-0">
            <div class="col-md-7 col-xl-5 mx-auto">
                <!-- Login -->

                <h5 class="mb-6 text-center">Please Enter your Email in order to receive instructions on how to reset
                    your password </h5>
                <!-- Form Login -->
                <form class="mb-5 bg-white border border-orange p-5" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <!-- Email -->
                    <div class="form-group mb-5">
                        <label for="modalSigninEmail1">
                            Email
                        </label>
                        <input type="hidden" formControlName="appname" value="Videostream" id="appname">
                        <input type="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="form-control form-control-sm" id="modalSigninEmail1"
                            placeholder="info@udyami.com">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                    </div>

                    <!-- Password -->

                    <!-- Submit -->
                    <button class="btn btn-block btn-primary btn-sm" type="submit">
                        Recover Password
                    </button>
                </form>
                <!-- Text -->
                <p class="mb-0 font-size-sm text-center">
                    Remember your password? <a class="text-underline forgot" [routerLink]="['/login']">Log in</a>
                </p>
            </div>
        </div>
    </div>

   
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>

   
    <!-- PAGE TITLE
    ================================================== -->
    <header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax
        data-speed=".8" style="background-image: url(assets/img/covers/cover-19.jpg);">
        <div class="container-fluid text-center ">
            <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">SEARCH</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-scroll justify-content-center py-1 ">
                    <li class="breadcrumb-item">
                        <a class="text-white" href="#">
                            Home
                        </a>
                    </li>
                    <li class="breadcrumb-item text-white active" aria-current="page">
                        Search
                    </li>
                </ol>
            </nav>
        </div>
        <!-- Img -->

    </header>



    <!-- CONTROL BAR
    ================================================== -->
    <div class="container-fluid my-5 mb-xl-4 z-index-2">
        <div class="d-lg-flex align-items-center mb-6 mb-xl-0">
            <p class="mb-lg-0">We found <span class="text-dark">834 courses</span> available for you</p>
            <div class="ms-lg-auto d-lg-flex flex-wrap">
                <div class="mb-4 mb-lg-0 ms-lg-6">
                    <div class="border rounded d-flex align-items-center choices-label h-50p">
                        <span class="ps-5">Sort by:</span>
                        <select class="form-select form-select-sm text-dark border-0 ps-1 bg-transparent flex-grow-1 shadow-none dropdown-menu-end" data-choices>
                            <option>Default</option>
                            <option>New Courses</option>
                            <option>Price Low to High</option>
                            <option>Price High to low</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- COURSE
    ================================================== -->
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-4 mb-5 mb-xl-0">
                <!-- SIDEBAR FILTER
                ================================================== -->
                <div class=" vertical-scroll" id="courseSidebar">
                    <div class="border rounded mb-3 ">
                        <!-- Heading -->
                        <div id="coursefilter1">
                            <h4 class="mb-0">
                                <button class="p-4 text-dark fw-medium d-flex align-items-center collapse-accordion-toggle line-height-one" type="button" data-bs-toggle="collapse" data-bs-target="#coursefiltercollapse1" aria-expanded="true" aria-controls="coursefiltercollapse1">
                                    Category
                                    <span class="ms-auto text-dark d-flex">
                                        <!-- Icon -->
                                       <i class="fas fa-plus"></i>
                                        <i class="fas fa-minus"></i>

                                    </span>
                                </button>
                            </h4>
                        </div>

                        <div id="coursefiltercollapse1" class="collapse show mt-n2 px-4 pb-4" aria-labelledby="coursefilter1" data-parent="#courseSidebar">
                            <ul class="list-unstyled list-group list-checkbox">
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="categorycustomcheckone">
                                    <label class="custom-control-label font-size-base" for="categorycustomcheckone">Art (8)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="categorycustomcheck2">
                                    <label class="custom-control-label font-size-base" for="categorycustomcheck2">Exercise (8)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="categorycustomcheck3">
                                    <label class="custom-control-label font-size-base" for="categorycustomcheck3">Material Design (7)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="categorycustomcheck4">
                                    <label class="custom-control-label font-size-base" for="categorycustomcheck4">Software Development (6)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="categorycustomcheck5">
                                    <label class="custom-control-label font-size-base" for="categorycustomcheck5">Music (6)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="categorycustomcheck6">
                                    <label class="custom-control-label font-size-base" for="categorycustomcheck6">Photography (6)</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="border rounded mb-3 ">
                        <!-- Heading -->
                        <div id="coursefilter2">
                            <h4 class="mb-0">
                                <button class="p-4 text-dark fw-medium d-flex align-items-center collapse-accordion-toggle line-height-one" type="button" data-bs-toggle="collapse" data-bs-target="#coursefiltercollapse2" aria-expanded="true" aria-controls="coursefiltercollapse2">
                                    Instructors
                                    <span class="ms-auto text-dark d-flex">
                                        <!-- Icon -->
                                       <i class="fas fa-plus"></i>
                                        <i class="fas fa-minus"></i>

                                    </span>
                                </button>
                            </h4>
                        </div>

                        <div id="coursefiltercollapse2" class="collapse show mt-n2 px-4 pb-4" aria-labelledby="coursefilter2" data-parent="#courseSidebar">
                            

                            <ul class="list-unstyled list-group list-checkbox list-checkbox-limit">
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="instructorscustomcheck1">
                                    <label class="custom-control-label font-size-base" for="instructorscustomcheck1">Chris Convrse (03)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="instructorscustomcheck2">
                                    <label class="custom-control-label font-size-base" for="instructorscustomcheck2">Morten Rand (15)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="instructorscustomcheck3">
                                    <label class="custom-control-label font-size-base" for="instructorscustomcheck3">Rayi Villalobos (125)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="instructorscustomcheck4">
                                    <label class="custom-control-label font-size-base" for="instructorscustomcheck4">James William (1.584)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="instructorscustomcheck5">
                                    <label class="custom-control-label font-size-base" for="instructorscustomcheck5">Villalobos (584)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="instructorscustomcheck6">
                                    <label class="custom-control-label font-size-base" for="instructorscustomcheck6">Rand joe (44)</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="border rounded mb-3 ">
                        <!-- Heading -->
                        <div id="coursefilter3">
                            <h4 class="mb-0">
                                <button class="p-4 text-dark fw-medium d-flex align-items-center collapse-accordion-toggle line-height-one" type="button" data-bs-toggle="collapse" data-bs-target="#coursefiltercollapse3" aria-expanded="true" aria-controls="coursefiltercollapse3">
                                    Price
                                    <span class="ms-auto text-dark d-flex">
                                        <!-- Icon -->
                                       <i class="fas fa-plus"></i>
                                        <i class="fas fa-minus"></i>

                                    </span>
                                </button>
                            </h4>
                        </div>

                        <div id="coursefiltercollapse3" class="collapse show mt-n2 px-4 pb-4" aria-labelledby="coursefilter3" data-parent="#courseSidebar">
                            <ul class="list-unstyled list-group list-checkbox">
                                <li class="custom-control custom-radio">
                                    <input type="radio" id="pricecustomradio1" name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label font-size-base" for="pricecustomradio1">All (18)</label>
                                </li>
                                <li class="custom-control custom-radio">
                                    <input type="radio" id="pricecustomradio2" name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label font-size-base" for="pricecustomradio2">Free (3)</label>
                                </li>
                                <li class="custom-control custom-radio">
                                    <input type="radio" id="pricecustomradio3" name="customRadio" class="custom-control-input">
                                    <label class="custom-control-label font-size-base" for="pricecustomradio3">Paid (15)</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="border rounded mb-3 ">
                        <!-- Heading -->
                        <div id="coursefilter4">
                            <h4 class="mb-0">
                                <button class="p-4 text-dark fw-medium d-flex align-items-center collapse-accordion-toggle line-height-one" type="button" data-bs-toggle="collapse" data-bs-target="#coursefiltercollapse4" aria-expanded="true" aria-controls="coursefiltercollapse4">
                                    Level
                                    <span class="ms-auto text-dark d-flex">
                                        <!-- Icon -->
                                       <i class="fas fa-plus"></i>
                                        <i class="fas fa-minus"></i>

                                    </span>
                                </button>
                            </h4>
                        </div>

                        <div id="coursefiltercollapse4" class="collapse show mt-n2 px-4 pb-4" aria-labelledby="coursefilter4" data-parent="#courseSidebar">
                            <ul class="list-unstyled list-group list-checkbox">
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="levelcustomcheck1">
                                    <label class="custom-control-label font-size-base" for="levelcustomcheck1">Beginner (03)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="levelcustomcheck2">
                                    <label class="custom-control-label font-size-base" for="levelcustomcheck2">Intermediate (15)</label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="levelcustomcheck3">
                                    <label class="custom-control-label font-size-base" for="levelcustomcheck3">Advanced (126)</label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="border rounded mb-3 ">
                        <!-- Heading -->
                        <div id="coursefilter5">
                            <h4 class="mb-0">
                                <button class="p-4 text-dark fw-medium d-flex align-items-center collapse-accordion-toggle line-height-one" type="button" data-bs-toggle="collapse" data-bs-target="#coursefiltercollapse5" aria-expanded="false" aria-controls="coursefiltercollapse5">
                                    Rating
                                    <span class="ms-auto text-dark d-flex">
                                        <!-- Icon -->
                                       <i class="fas fa-plus"></i>
                                        <i class="fas fa-minus"></i>

                                    </span>
                                </button>
                            </h4>
                        </div>

                        <div id="coursefiltercollapse5" class="collapse mt-n2 px-4 pb-4" aria-labelledby="coursefilter5" data-parent="#courseSidebar">
                            <ul class="list-unstyled list-group list-checkbox">
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="ratingcustomcheck1">
                                    <label class="custom-control-label font-size-base" for="ratingcustomcheck1">
                                        <span class="d-flex align-items-end">
                                            <span class="star-rating">
                                                <span class="rating" style="width:90%;"></span>
                                            </span>

                                            <span class="ms-3">
                                                <span>& up</span>
                                            </span>
                                        </span>
                                    </label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="ratingcustomcheck2">
                                    <label class="custom-control-label font-size-base" for="ratingcustomcheck2">
                                        <span class="d-flex align-items-end">
                                            <span class="star-rating">
                                                <span class="rating" style="width:70%;"></span>
                                            </span>

                                            <span class="ms-3">
                                                <span>& up</span>
                                            </span>
                                        </span>
                                    </label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="ratingcustomcheck3">
                                    <label class="custom-control-label font-size-base" for="ratingcustomcheck3">
                                        <span class="d-flex align-items-end">
                                            <span class="star-rating">
                                                <span class="rating" style="width:50%;"></span>
                                            </span>

                                            <span class="ms-3">
                                                <span>& up</span>
                                            </span>
                                        </span>
                                    </label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="ratingcustomcheck4">
                                    <label class="custom-control-label font-size-base" for="ratingcustomcheck4">
                                        <span class="d-flex align-items-end">
                                            <span class="star-rating">
                                                <span class="rating" style="width:35%;"></span>
                                            </span>

                                            <span class="ms-3">
                                                <span>& up</span>
                                            </span>
                                        </span>
                                    </label>
                                </li>
                                <li class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="ratingcustomcheck5">
                                    <label class="custom-control-label font-size-base" for="ratingcustomcheck5">
                                        <span class="d-flex align-items-end">
                                            <span class="star-rating">
                                                <span class="rating" style="width:10%;"></span>
                                            </span>

                                            <span class="ms-3">
                                                <span>& up</span>
                                            </span>
                                        </span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- <a href="#" class="btn btn-primary btn-block mb-10">FILTER RESULTS</a> -->
                </div>

            </div>

            <div class="col-xl-8">
                <!-- Card -->
                <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                    <!-- Image -->
                    <div class="col-md-4 card-zoom position-relative">
                        

                        <a href="course-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                            <img class="rounded shadow-light-lg" src="assets/img/products/product-15.jpg" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                    <!-- Footer -->
                    <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                        <!-- Preheading -->
                        <a href="course-single.html"><span class="mb-1 d-inline-block text-gray-800">Photography</span></a>

                        <!-- Heading -->
                        <div class="position-relative">
                            <a href="course-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">The Complete JavaScript Course 2020: Build Real Projects!</h4></a>
                            <ul class="nav mx-n3 mb-3">
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                            <i class="fas fa-book-reader"></i>

                                        </div>
                                        <div class="font-size-sm">5 lessons</div>
                                    </div>
                                </li>
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                           <i class="far fa-clock"></i>

                                        </div>
                                        <div class="font-size-sm">8h 12m</div>
                                    </div>
                                </li>
                            </ul>

                            <div class="row mx-n2 align-items-center">
                                <div class="col px-2">
                                    <del class="font-size-sm">$959</del>
                                    <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                                </div>

                                <div class="col-auto px-2">
                                    <div class="d-lg-flex align-items-end">
                                        <div class="star-rating mb-2 mb-lg-0">
                                            <div class="rating" style="width:100%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                    <!-- Image -->
                    <div class="col-md-4 card-zoom position-relative">
                        

                        <a href="course-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                            <img class="rounded shadow-light-lg" src="assets/img/products/product-16.jpg" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                    <!-- Footer -->
                    <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                        <!-- Preheading -->
                        <a href="course-single.html"><span class="mb-1 d-inline-block text-gray-800">Photography</span></a>

                        <!-- Heading -->
                        <div class="position-relative">
                            <a href="course-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">Learn Figma: User Interface Design Essentials - UI/UX Design</h4></a>
                            <ul class="nav mx-n3 mb-3">
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                            <i class="fas fa-book-reader"></i>

                                        </div>
                                        <div class="font-size-sm">5 lessons</div>
                                    </div>
                                </li>
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                           <i class="far fa-clock"></i>

                                        </div>
                                        <div class="font-size-sm">8h 12m</div>
                                    </div>
                                </li>
                            </ul>

                            <div class="row mx-n2 align-items-center">
                                <div class="col px-2">
                                    <del class="font-size-sm">$959</del>
                                    <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                                </div>

                                <div class="col-auto px-2">
                                    <div class="d-lg-flex align-items-end">
                                        <div class="star-rating mb-2 mb-lg-0">
                                            <div class="rating" style="width:100%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                    <!-- Image -->
                    <div class="col-md-4 card-zoom position-relative">
                        

                        <a href="course-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                            <img class="rounded shadow-light-lg" src="assets/img/products/product-23.jpg" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                    <!-- Footer -->
                    <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                        <!-- Preheading -->
                        <a href="course-single.html"><span class="mb-1 d-inline-block text-gray-800">Photography</span></a>

                        <!-- Heading -->
                        <div class="position-relative">
                            <a href="course-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">Guide to Front-End Web Development and Design</h4></a>
                            <ul class="nav mx-n3 mb-3">
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                            <i class="fas fa-book-reader"></i>

                                        </div>
                                        <div class="font-size-sm">5 lessons</div>
                                    </div>
                                </li>
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                           <i class="far fa-clock"></i>

                                        </div>
                                        <div class="font-size-sm">8h 12m</div>
                                    </div>
                                </li>
                            </ul>

                            <div class="row mx-n2 align-items-center">
                                <div class="col px-2">
                                    <del class="font-size-sm">$959</del>
                                    <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                                </div>

                                <div class="col-auto px-2">
                                    <div class="d-lg-flex align-items-end">
                                        <div class="star-rating mb-2 mb-lg-0">
                                            <div class="rating" style="width:100%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                    <!-- Image -->
                    <div class="col-md-4 card-zoom position-relative">
                        

                        <a href="course-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                            <img class="rounded shadow-light-lg" src="assets/img/products/product-15.jpg" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                    <!-- Footer -->
                    <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                        <!-- Preheading -->
                        <a href="course-single.html"><span class="mb-1 d-inline-block text-gray-800">Photography</span></a>

                        <!-- Heading -->
                        <div class="position-relative">
                            <a href="course-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">The Complete JavaScript Course 2020: Build Real Projects!</h4></a>
                            <ul class="nav mx-n3 mb-3">
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                            <i class="fas fa-book-reader"></i>

                                        </div>
                                        <div class="font-size-sm">5 lessons</div>
                                    </div>
                                </li>
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                           <i class="far fa-clock"></i>

                                        </div>
                                        <div class="font-size-sm">8h 12m</div>
                                    </div>
                                </li>
                            </ul>

                            <div class="row mx-n2 align-items-center">
                                <div class="col px-2">
                                    <del class="font-size-sm">$959</del>
                                    <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                                </div>

                                <div class="col-auto px-2">
                                    <div class="d-lg-flex align-items-end">
                                        <div class="star-rating mb-2 mb-lg-0">
                                            <div class="rating" style="width:100%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                    <!-- Image -->
                    <div class="col-md-4 card-zoom position-relative">
                        

                        <a href="course-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                            <img class="rounded shadow-light-lg" src="assets/img/products/product-16.jpg" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                    <!-- Footer -->
                    <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                        <!-- Preheading -->
                        <a href="course-single.html"><span class="mb-1 d-inline-block text-gray-800">Photography</span></a>

                        <!-- Heading -->
                        <div class="position-relative">
                            <a href="course-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">Learn Figma: User Interface Design Essentials - UI/UX Design</h4></a>
                            <ul class="nav mx-n3 mb-3">
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                            <i class="fas fa-book-reader"></i>

                                        </div>
                                        <div class="font-size-sm">5 lessons</div>
                                    </div>
                                </li>
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                           <i class="far fa-clock"></i>

                                        </div>
                                        <div class="font-size-sm">8h 12m</div>
                                    </div>
                                </li>
                            </ul>

                            <div class="row mx-n2 align-items-center">
                                <div class="col px-2">
                                    <del class="font-size-sm">$959</del>
                                    <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                                </div>

                                <div class="col-auto px-2">
                                    <div class="d-lg-flex align-items-end">
                                        <div class="star-rating mb-2 mb-lg-0">
                                            <div class="rating" style="width:100%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Card -->
                <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                    <!-- Image -->
                    <div class="col-md-4 card-zoom position-relative">
                        

                        <a href="course-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                            <img class="rounded shadow-light-lg" src="assets/img/products/product-24.jpg" alt="...">
                        </a>

                        <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                            <span class="text-white text-uppercase fw-bold font-size-xs">BEST SELLER</span>
                        </span>
                    </div>

                    <!-- Footer -->
                    <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                        <!-- Preheading -->
                        <a href="course-single.html"><span class="mb-1 d-inline-block text-gray-800">Photography</span></a>

                        <!-- Heading -->
                        <div class="position-relative">
                            <a href="course-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">Guide to Front-End Web Development and Design</h4></a>
                            <ul class="nav mx-n3 mb-3">
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                            <i class="fas fa-book-reader"></i>

                                        </div>
                                        <div class="font-size-sm">5 lessons</div>
                                    </div>
                                </li>
                                <li class="nav-item px-3">
                                    <div class="d-flex align-items-center">
                                        <div class="me-2 d-flex text-secondary icon-uxs">
                                            <!-- Icon -->
                                           <i class="far fa-clock"></i>

                                        </div>
                                        <div class="font-size-sm">8h 12m</div>
                                    </div>
                                </li>
                            </ul>

                            <div class="row mx-n2 align-items-center">
                                <div class="col px-2">
                                    <del class="font-size-sm">$959</del>
                                    <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                                </div>

                                <div class="col-auto px-2">
                                    <div class="d-lg-flex align-items-end">
                                        <div class="star-rating mb-2 mb-lg-0">
                                            <div class="rating" style="width:100%;"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- PAGINATION
                ================================================== -->
                <nav class="mt-8 mb-11" aria-label="Page navigationa">
                    <ul class="pagination justify-content-center">
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Previous">
                                <span aria-hidden="true"><i class="fas fa-arrow-left"></i></span>
                            </a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item active"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#" aria-label="Next">
                                <span aria-hidden="true"><i class="fas fa-arrow-right"></i></span>
                            </a>
                        </li>
                    </ul>
                </nav>

            </div>
        </div>
    </div>

   
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>
 
<!-- NAVBAR
    ================================================== -->

<div class="container-fluid d-none d-sm-block bg-dark top-bar">
  <div class="d-flex align-items-center border-bottom border-white-20 pt-2 pb-2">
    <ul class="nav mx-n3">
      <li class="nav-item px-4 px-xl-1">
        <span class="font-size-sm text-white">उद्यमी महाराष्ट्र | उद्योग प्रशिक्षण आणि व्यवसाय</span>
      </li>

    </ul>

    <ul class="nav ms-auto me-n0 font-size-sm">
      <li class="nav-item px-3">
        <a href="https://www.facebook.com/udyamii/" target="_blank" class="nav-link p-0 text-white">
          <i class="fab fa-facebook-f"></i>
        </a>
      </li>
      <li class="nav-item px-3">
        <a href="#" target="_blank" class="nav-link p-0 text-white">
          <i class="fab fa-twitter"></i>
        </a>
      </li>

      <li class="nav-item px-3">
        <a href="https://in.linkedin.com/company/udyami-maharashtra" target="_blank" class="nav-link p-0 text-white">
          <i class="fab fa-linkedin-in"></i>
        </a>
      </li>


    </ul>

  </div>
</div>

<header class="navbar site-header navbar-expand-xl navbar-light py-1 ">
  <div class="container">
    <div class="col-6">
      <!-- logo start -->
      <a class="navbar-brand me-0 mt-lg-0" href="index.html">
        <img src="assets/img/logo.png" class="navbar-brand-img" alt="Udyami Maharashtra Wholesale Import and Exportl">
      </a>
      <!-- logo end -->
    </div>
    <div class="col-6">
      <!-- header right start -->
      <div class="rght-icon">
        <div class="callsales">
          <a href="tel:+0000000"><i class="fas fa-phone fa-lg"></i> +91-{{ibcDetails.phoneNumber}}</a>
        </div>
        <div class="callsales">
          <a href="mailto:abc@gmail.com"><i class="fas fa-envelope fa-lg"></i> {{ibcDetails.email}}</a>
        </div>
      </div>
      <!-- header right end -->
    </div>
    <!-- Brand -->

  </div>
</header>

<!-- COURSE
    ================================================== -->


<div class="pb-9 pt-9 pt-md-9 jarallax overlay overlay-secondary overlay-70" data-jarallax data-speed=".8"
  style="background-image: url(assets/img/covers/cover-24.jpg)">
  <div class="container">
    <div class="row">

      <div class="col-lg-5">
        <!-- content start -->
        <div class="col-12  pb-4 pb-md-7 aos-init aos-animate" data-aos="fade-up" data-aos-delay="50">
          <!-- Card -->
          <div class="card border shadow p-2 sk-fade" style="background: #ffffff47;">


            <div class="lp-profile-cover">
              <div class="d-flex flex-wrap align-items-center justify-content-center mb-5 mb-md-4 mt-4">
                <!-- <div class="lp-profile-avatar border rounded-circle d-inline-block mb-4 mb-md-0 mx-lg-4 order-0">
                  <div class="p-4"><img class="rounded-circle img-fluid" alt="User Avatar"
                      src="{{apiURL}}/{{ibcDetails.photo}}"
                      height="250" width="250" loading="lazy"></div>
                </div> -->
              </div>
              <div class="bg-white pt-4 pb-4">
                <h1 class="text-center mb-1">{{ibcDetails.name}}</h1>
                <div class="text-center mb-2 ibo-text">Email Id : <a href="mailto:{{ibcDetails.email}}">
                    {{ibcDetails.email}} </a>
                </div>
                <div class="text-center mb-4 ibo-text">Contact No : <a href="tel:+91{{ibcDetails.phoneNumber}}">
                    +91-{{ibcDetails.phoneNumber}}</a></div>
              </div>
            </div>
          </div>
        </div>
        <!-- content end -->
      </div>
      <div class="col-lg-7">
        <!--form content start  -->
        <div class="card pe-6 ps-6 pt-2 pb-2">
          <h2 class="mb-2 mt-2 text-center">Register Now</h2>
          <form [formGroup]="createAccountForm" (ngSubmit)="onSubmit()">
            <div class="form-label-group mb-3">
              <input formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" type="text"
                class="form-control form-control-flush placeholder-1" id="name" placeholder="Enter Name">
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">Name is required</div>
              </div>
              <label for="exampleInputTitle1">Name</label>
            </div>

            <div class="form-label-group mb-3">
              <input formControlName="contactNo" [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }"
                type="number" class="form-control form-control-flush placeholder-1" id="contactNo"
                placeholder="contactNo no">
              <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
                <div *ngIf="f.contactNo.errors.required">Number is required</div>
                <div *ngIf="f.contactNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
              </div>
              <label for="cardEmail">Mobile no</label>

            </div>

            <div class="form-label-group mb-3">

              <input formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" type="email"
                class="form-control form-control-flush placeholder-1" id="email" placeholder="Email" email="true">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors?.email">
                  Email address is not valid</div>
              </div>
              <label for="cardEmail">Email</label>
            </div>


            <div class="form-label-group mb-3">
              <select [ngClass]="{ 'is-invalid': submitted && f.categoryId.errors }" formControlName="categoryId"
                (change)="getCategoryCourse($event)" name="category" id="categoryId"
                class="form-control form-control-flush form-select">
                <option value="">Select Course Category</option>
                <option *ngFor="let c of category;" [value]="c.id">{{c.categoryName}}</option>
              </select>
              <div *ngIf="submitted && f.categoryId.errors" class="invalid-feedback">
                <div *ngIf="f.categoryId.errors.required">Category is required</div>
              </div>
              <label for="cardEmail">Course Category</label>
            </div>

            <div class="form-label-group mb-3">
              <select formControlName="courseId" name="courseId" id="courseId"
                class="form-control form-control-flush form-select">
                <option value="">Select Course</option>
                <option *ngFor="let cc of categorycourse;" [value]="cc.id">{{cc.name}}</option>
              </select>
              <div *ngIf="submitted && f.courseId.errors" class="invalid-feedback">
                <div *ngIf="f.courseId.errors.required">Select any Course</div>
              </div>
              <label for="cardEmail">Select Product</label>
            </div>
            <div class="form-check ">
              <input class="form-check-input text-gray-800" type="checkbox" id="rememberme" name="rememberme">
              <label class="form-check-label text-gray-800" for="rememberme">By Clicking on the same
                you are accepting our <a href="#" target="_blank" class="text-primary">terms and
                  conditions</a> and <a href="#" target="_blank" class="text-primary">Privacy
                  Policy</a>.</label>
            </div>
            <div class="col-12 mt-2 mb-2 text-center">
              <button type="submit" class="btn btn-primary btn-sm btn-block mw-md-300p">Connect
              </button>
            </div>
          </form>

        </div>
        <!-- form content end -->
      </div>
    </div>
  </div>
</div>

<!-- Realted Course start -->
<div class="container">

  <div class="text-center mb-5 mb-md-8  mt-8" data-aos="fade-up">
    <h1>Courses</h1>
  </div>
  <div class="row row-cols-md-2 row-cols-xl-3 row-cols-wd-4 row-cols-lg-3 mb-6 mb-xl-3 ">
    <ng-container *ngFor="let c of courses;let i=index">
      <div class="col-12 col-md-6 col-xl-4 col-wd-4 pb-4 pb-md-7" data-aos="fade-in">
        <!-- Card -->
        <div class="card border shadow p-2 sk-fade">
          <!-- Image -->
          <div class="card-zoom position-relative">
            <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="card-img sk-thumbnail d-block">
              <img class="rounded shadow-light-lg" src="{{apiURL}}/{{c.bannerImg}}" alt="..." (error)="setDefaultImage($event)">
            </a>

          </div>
          <!-- Footer -->
          <div class="card-footer px-2 pb-2 mb-1 pt-4 position-relative">
            <!-- Preheading -->
            <a [routerLink]="['/course-details/'+ c.id.toString() +'']"><span
                class="mb-1 d-inline-block text-gray-800">{{c.categoryName}}</span></a>
            <!-- Heading -->
            <div class="position-relative">
              <a [routerLink]="['/course-details/'+ c.id.toString() +'']" class="d-block">
                <h4 class="line-clamp-2  me-md-6 me-lg-10 me-xl-4 mb-2">{{c.name}}</h4>
              </a>
              <div class="row mx-n2 align-items-end mh-50">
                <div class="col-6 px-2 ibc-btn">
                  <a [routerLink]="['/course-details/'+ c.id.toString() +'']" target="_blank"
                    rel="noopener noreferrer">View Course</a>
                </div>
                <input type="hidden" #course_id value="{{c.id}}">
                <input type="hidden" #course_price value="{{c.actual_Price}}">
                <div class="col-6 px-2 text-right ibc-paynow">
                  <a class="launchModal" data-bs-toggle="modal" data-bs-target="#exampleModal" style="cursor: pointer;"
                    (click)="courseId(course_id.value,course_price.value)">Pay Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
<!-- Realted Course End -->

<!-- queries Call start -->
<section class="pt-5 pb-5 py-md-8 bg-light1 text-center" style="background: #fa8f0b21;">
  <div class="container">
    <div class="text-center mt-4 mb-4">
      <div class="foot_box">
        &nbsp;For all queries Call &nbsp;&nbsp;&nbsp;<span>
          <a href="tel:+9820098200"> +91-9820098200</a></span>
      </div>
    </div>
  </div>
</section>

<!-- queries Call end -->

<!-- Modal Form start -->
<div class="modal fade webinarmodal" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLabel">BCP (E) + 40 PSCs + Event Only (E)</h3>
        <button type="button" class="close text-primary" data-bs-dismiss="modal" aria-label="Close">
          <i class="fas fa-lg fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="payIbcForm" (ngSubmit)="payIbc()">
          <div class="form-label-group">
            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name"
              class="form-control form-control-flush border-dark" id="name" placeholder="Name">
            <label for="cardName">Your Name</label>
            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors.required">Name is required</div>
            </div>
          </div>
          <input type="hidden" value="{{this.payIbcForm.value.course_Id}}">

          <div class="form-label-group">
            <input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
              class="form-control form-control-flush border-dark" id="Email" placeholder="Email">
            <label for="cardEmail">Email ID</label>
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors.required">Email is required</div>
              <div *ngIf="f.email.errors?.email">Email must be a valid email address</div>
            </div>
          </div>
          <div class="form-label-group">
            <input type="number" [ngClass]="{ 'is-invalid': submitted && f.contactNo.errors }"
              formControlName="contactNo" class="form-control form-control-flush border-dark" id="Contact"
              placeholder="Mobile no">
            <label for="cardEmail">Mobile no</label>
            <div *ngIf="submitted && f.contactNo.errors" class="invalid-feedback">
              <div *ngIf="f.contactNo.errors.required">Phone Number is required</div>
              <div *ngIf="f.contactNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
            </div>
          </div>
          <div class="form-check ">
            <input class="form-check-input text-gray-800" type="checkbox" id="rememberme" name="rememberme">
            <label class="form-check-label text-gray-800" for="rememberme">By Clicking on the same you
              are accepting our <a href="#" target="_blank" class="text-primary">terms and
                conditions</a> and <a href="#" target="_blank" class="text-primary">Privacy
                Policy</a>.</label>
          </div>
          <div class="modal-footer text-center d-block">
            <button type="submit" class="btn btn-primary btn-sm btn-block mw-md-300p">Proceed to Pay</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Modal Form End -->
<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>
<header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax data-speed=".8">
  <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-6">
              <div class="breadcrumb-main-wrapper">
                <h1 class="title">Checkout</h1>
                <div class="pagination-wrapper"> <a routerLink="/index">Home</a> <i
                    class="fa-regular fa-chevron-right"></i>
                  <a class="active">Checkout</a>
                </div>
              </div>

            </div>
            <div class="col-6">
              <div class="breadcrumb-img-book">
                <img src="\assets\images\breadcrumb\book.png" alt="">
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</header>

<div class="container pb-6 pt-8 pb-xl-10">
  <form name="checkout" class="checkout woocommerce-checkout" [formGroup]="checkoutForm" ng-submit="paynow()"
    novalidate="">
    <div class="col2-set" id="customer_details">
      <div class="col-1">

        <div class="woocommerce-billing-fields woocommerce-checkout-review-order-inner">
          <h3>Billing details</h3>
          <div class="woocommerce-billing-fields__field-wrapper">
            <div
              class="form-row form-row-first validate-required woocommerce-invalid woocommerce-invalid-required-field"
              id="billing_first_name_field" data-priority="10">
              <label for="billing_first_name" class="">First name<sup class="text-danger">*</sup></label>
              <input type="text" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }"
                formControlName="firstName" class="input-text" name="firstName" id="billing_first_name" placeholder=""
                value="" autocomplete="given-name" autofocus="autofocus">
              <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                <div *ngIf="f.firstName.errors.required">First Name is required</div>
              </div>
            </div>


            <div class="form-row form-row-last validate-required" id="billing_last_name_field" data-priority="20">
              <label for="billing_last_name" class="">Last name<sup class="text-danger">*</sup></label>
              <input type="text" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" formControlName="lastName"
                class="input-text " name="lastName" id="billing_last_name" placeholder="" value=""
                autocomplete="family-name">
              <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
              </div>
            </div>


            <!-- <p class="form-row form-row-wide" id="billing_company_field" data-priority="30">
              <label for="billing_company" class="">Company name</label>
              <input type="text" class="input-text " formControlName="companyName" name="companyName"
                id="billing_company" placeholder="" value="" autocomplete="organization">
            </p> -->
            <p class="form-row form-row-first address-field update_totals_on_change validate-required woocommerce-validated"
              id="billing_country_field1" data-priority="40">
              <label for="billing_country" class="">Country<sup class="text-danger">*</sup></label>
              <select name="country" formControlName="country" id="billing_country"
                class="country_to_state country_select  select2-hidden-accessible" autocomplete="country" tabindex="-1"
                aria-hidden="true">
                <option value="">Select a country…</option>
                <option value="AX">Åland Islands</option>
                <option value="AF">Afghanistan</option>
                <option value="AL">Albania</option>
                <option value="DZ">Algeria</option>
                <option value="AS">American Samoa</option>
                <option value="AD">Andorra</option>
                <option value="AO">Angola</option>
                <option value="AI">Anguilla</option>
                <option value="AQ">Antarctica</option>
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="AM">Armenia</option>
                <option value="AW">Aruba</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BS">Bahamas</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BB">Barbados</option>
                <option value="BY">Belarus</option>
                <option value="PW">Belau</option>
                <option value="BE">Belgium</option>
                <option value="BZ">Belize</option>
                <option value="BJ">Benin</option>
                <option value="BM">Bermuda</option>
                <option value="BT">Bhutan</option>
                <option value="BO">Bolivia</option>
                <option value="BQ">Bonaire, Saint Eustatius and Saba</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BW">Botswana</option>
                <option value="BV">Bouvet Island</option>
                <option value="BR">Brazil</option>
                <option value="IO">British Indian Ocean Territory</option>
                <option value="VG">British Virgin Islands</option>
                <option value="BN">Brunei</option>
                <option value="BG">Bulgaria</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="KH">Cambodia</option>
                <option value="CM">Cameroon</option>
                <option value="CA">Canada</option>
                <option value="CV">Cape Verde</option>
                <option value="KY">Cayman Islands</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CX">Christmas Island</option>
                <option value="CC">Cocos (Keeling) Islands</option>
                <option value="CO">Colombia</option>
                <option value="KM">Comoros</option>
                <option value="CG">Congo (Brazzaville)</option>
                <option value="CD">Congo (Kinshasa)</option>
                <option value="CK">Cook Islands</option>
                <option value="CR">Costa Rica</option>
                <option value="HR">Croatia</option>
                <option value="CU">Cuba</option>
                <option value="CW">Curaçao</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="DJ">Djibouti</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="EG">Egypt</option>
                <option value="SV">El Salvador</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="EE">Estonia</option>
                <option value="ET">Ethiopia</option>
                <option value="FK">Falkland Islands</option>
                <option value="FO">Faroe Islands</option>
                <option value="FJ">Fiji</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GF">French Guiana</option>
                <option value="PF">French Polynesia</option>
                <option value="TF">French Southern Territories</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GH">Ghana</option>
                <option value="GI">Gibraltar</option>
                <option value="GR">Greece</option>
                <option value="GL">Greenland</option>
                <option value="GD">Grenada</option>
                <option value="GP">Guadeloupe</option>
                <option value="GU">Guam</option>
                <option value="GT">Guatemala</option>
                <option value="GG">Guernsey</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HM">Heard Island and McDonald Islands</option>
                <option value="HN">Honduras</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran</option>
                <option value="IQ">Iraq</option>
                <option value="IE">Ireland</option>
                <option value="IM">Isle of Man</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="CI">Ivory Coast</option>
                <option value="JM">Jamaica</option>
                <option value="JP">Japan</option>
                <option value="JE">Jersey</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KE">Kenya</option>
                <option value="KI">Kiribati</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Laos</option>
                <option value="LV">Latvia</option>
                <option value="LB">Lebanon</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libya</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MO">Macao S.A.R., China</option>
                <option value="MK">Macedonia</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="ML">Mali</option>
                <option value="MT">Malta</option>
                <option value="MH">Marshall Islands</option>
                <option value="MQ">Martinique</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="YT">Mayotte</option>
                <option value="MX">Mexico</option>
                <option value="FM">Micronesia</option>
                <option value="MD">Moldova</option>
                <option value="MC">Monaco</option>
                <option value="MN">Mongolia</option>
                <option value="ME">Montenegro</option>
                <option value="MS">Montserrat</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="MM">Myanmar</option>
                <option value="NA">Namibia</option>
                <option value="NR">Nauru</option>
                <option value="NP">Nepal</option>
                <option value="NL">Netherlands</option>
                <option value="NC">New Caledonia</option>
                <option value="NZ">New Zealand</option>
                <option value="NI">Nicaragua</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="NU">Niue</option>
                <option value="NF">Norfolk Island</option>
                <option value="KP">North Korea</option>
                <option value="MP">Northern Mariana Islands</option>
                <option value="NO">Norway</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PS">Palestinian Territory</option>
                <option value="PA">Panama</option>
                <option value="PG">Papua New Guinea</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="PH">Philippines</option>
                <option value="PN">Pitcairn</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="PR">Puerto Rico</option>
                <option value="QA">Qatar</option>
                <option value="RE">Reunion</option>
                <option value="RO">Romania</option>
                <option value="RU">Russia</option>
                <option value="RW">Rwanda</option>
                <option value="ST">São Tomé and Príncipe</option>
                <option value="BL">Saint Barthélemy</option>
                <option value="SH">Saint Helena</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="SX">Saint Martin (Dutch part)</option>
                <option value="MF">Saint Martin (French part)</option>
                <option value="PM">Saint Pierre and Miquelon</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="WS">Samoa</option>
                <option value="SM">San Marino</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SN">Senegal</option>
                <option value="RS">Serbia</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SG">Singapore</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="SB">Solomon Islands</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="GS">South Georgia/Sandwich Islands</option>
                <option value="KR">South Korea</option>
                <option value="SS">South Sudan</option>
                <option value="ES">Spain</option>
                <option value="LK">Sri Lanka</option>
                <option value="SD">Sudan</option>
                <option value="SR">Suriname</option>
                <option value="SJ">Svalbard and Jan Mayen</option>
                <option value="SZ">Swaziland</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="SY">Syria</option>
                <option value="TW">Taiwan</option>
                <option value="TJ">Tajikistan</option>
                <option value="TZ">Tanzania</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TG">Togo</option>
                <option value="TK">Tokelau</option>
                <option value="TO">Tonga</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="TN">Tunisia</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="TC">Turks and Caicos Islands</option>
                <option value="TV">Tuvalu</option>
                <option value="UG">Uganda</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB" selected="selected">United Kingdom (UK)</option>
                <option value="US">United States (US)</option>
                <option value="UM">United States (US) Minor Outlying Islands</option>
                <option value="VI">United States (US) Virgin Islands</option>
                <option value="UY">Uruguay</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VU">Vanuatu</option>
                <option value="VA">Vatican</option>
                <option value="VE">Venezuela</option>
                <option value="VN">Vietnam</option>
                <option value="WF">Wallis and Futuna</option>
                <option value="EH">Western Sahara</option>
                <option value="YE">Yemen</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </select>
              <span *ngIf="submitted && f.country.errors" class="invalid-feedback">
                <span *ngIf="f.country.errors.required">Country is required.</span>
              </span>
            </p>

            <p class="form-row form-row-last validate-required" id="billing_last_name_field" data-priority="20">
              <label for="billing_last_name" class="">State<sup class="text-danger">*</sup></label>
              <input type="text" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }"
                class="input-text " name="state" id="sate" placeholder="" value="" autocomplete="family-name">
              <span *ngIf="submitted && f.state.errors" class="invalid-feedback">
                <span *ngIf="f.state.errors.required">State is required.</span>
              </span>
            </p>


            <p class="form-row form-row-wide address-field validate-required" id="billing_address_1_field"
              data-priority="50">
              <label for="billing_address_1" class="">Street address<sup class="text-danger">*</sup></label>
              <input type="text" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                class="input-text " name="address_1" id="billing_address_1" placeholder="House number and street name"
                value="" autocomplete="address-line1">
              <span *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <span *ngIf="f.address.errors.required">Address is required.</span>
              </span>
            </p>
            <p class="form-row form-row-first address-field validate-required" id="billing_city_field"
              data-priority="70" data-o_class="form-row form-row-wide address-field validate-required">
              <label for="billing_city" class="">Town / City<sup class="text-danger">*</sup></label>
              <input type="text" formControlName="city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                class="input-text " name="city" id="billing_city" placeholder="" value="" autocomplete="address-level2">
              <span *ngIf="submitted && f.city.errors" class="invalid-feedback">
                <span *ngIf="f.city.errors.required">City is required.</span>
              </span>
            </p>

            <div class="form-row form-row-last address-field validate-postcode validate-required"
              id="billing_postcode_field" data-priority="90"
              data-o_class="form-row form-row-wide address-field validate-required validate-postcode">
              <label for="billing_postcode" class="">Postcode<sup class="text-danger">*</sup></label>
              <input type="tel" formControlName="postalCode"
                [ngClass]="{ 'is-invalid': submitted && f.postalCode.errors }" class="input-text " name="postcode"
                id="billing_postcode" placeholder="" value="" autocomplete="postal-code">
              <div *ngIf="submitted && f.postalCode.errors" class="invalid-feedback">
                <div *ngIf="f.postalCode.errors.required">Postcode is required.</div>
                <div *ngIf="f.postalCode.errors.pattern">Only numbers are allowed.</div>
                <div *ngIf="f.postalCode.errors.maxlength">Maximum length is 6.</div>
                <div *ngIf="f.postalCode.errors.minlength">Manimum length is 6.</div>
              </div>

            </div>

            <div class="form-row form-row-first validate-required validate-phone" id="billing_phone_field"
              data-priority="100">
              <label for="billing_phone" class="">Phone<sup class="text-danger">*</sup></label>
              <input type="tel" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }"
                formControlName="phoneNumber" class="input-text " name="phoneNumber" id="billing_phone" placeholder=""
                value="" autocomplete="tel">

              <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                <div *ngIf="f.phoneNumber.errors.required">Phone Number is required.</div>
                <div *ngIf="f.phoneNumber.errors.pattern">Only numbers are allowed.</div>
                <div *ngIf="f.phoneNumber.errors.maxlength">Maximum length is 10.</div>
                <div *ngIf="f.phoneNumber.errors.minlength">Manimum length is 10.</div>
              </div>
            </div>

            <div class="form-row form-row-last validate-required validate-email" id="billing_email_field1"
              data-priority="110">
              <label for="billing_email" class="">Email address<sup class="text-danger">*</sup></label>
              <input type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
                class="input-text " name="email" id="billing_email" placeholder="" value="" autocomplete="email"
                email="true">
              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required.</div>
                <div *ngIf="f.email.errors.pattern">Email must contain '&#64;' and end with '.com'.</div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="order_review" class="woocommerce-checkout-review-order">
      <div class="woocommerce-checkout-review-order-inner">
        <h3 id="order_review_heading">Order Courses</h3>
        <div class="row" *ngFor="let getCourse of cartCousesArray;let i=index">
          <div class="col-12 col-md-3">

            <img class="bg-gray-200  rounded-circle overflow-hidden" style="width: 75%;height: auto; aspect-ratio: 1/1;"
              src="{{apiURL}}/{{getCourse?.image}}" alt="..." (error)="setDefaultImage($event)">
          </div>
          <div class="col-12 col-md-6">
            <p><b>{{getCourse?.name}}</b></p>

          </div>
          <div class="col-12 col-md-3">
            <p><b>{{getCourse?.offer_Price}}</b></p>
          </div>
        </div>
      </div>
      <div class="woocommerce-checkout-review-order-inner">
        <h3 id="order_review_heading">Order Summary</h3>
        <table class="shop_table woocommerce-checkout-review-order-table">
          <thead>
            <tr>
              <th class="product-name">Course</th>
              <th class="product-total">Total</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="checkoutCourse">
              <ng-container *ngFor="let checkout of checkoutCourse;let i=index">
                <ng-container *ngFor="let getCourse of checkout.mainCourse;let i=index">
                  <tr class="cart_item">
                    <td class="product-name">
                      {{getCourse.name}}
                    </td>
                    <td class="product-total">
                      <span class="woocommerce-Price-amount amount">
                        <span class="woocommerce-Price-currencySymbol">₹</span>
                        {{Math.round(getCourse.offer_Price)}}
                      </span>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>

          </tbody>
          <tfoot>

            <tr class="cart-subtotal">
              <th>Subtotal</th>
              <td><span class="woocommerce-Price-amount amount"><span
                    class="woocommerce-Price-currencySymbol">₹</span>{{coursesSubtotal}}</span></td>
            </tr>
            <tr class="cart-gst">
              <th>GST (18%)</th>
              <td><span class="woocommerce-Price-amount amount"><span
                    class="woocommerce-Price-currencySymbol"></span>{{gstCharge}}</span></td>
            </tr>
            <tr class="order-total">
              <th>Total</th>
              <td><strong><span class="woocommerce-Price-amount amount">
                    <input type="hidden" [ngClass]="{ 'is-invalid': submitted && f.totalOrderAmount.errors }"
                      formControlName="totalOrderAmount" #total>
                    <span class="woocommerce-Price-currencySymbol">₹</span>{{coursesTotal}}</span></strong>
              </td>
            </tr>
          </tfoot>
        </table>
        <input id="payment_method_cheque" type="hidden" formControlName="paymentMode" class="input-radio"
          name="payment_method" value="cheque" data-order_button_text="">
        <div class="form-row place-order d-flex">
          <div class="d-grid gap-2">
            <a (click)="paynow()" class="btn btn-primary btn-lg rts-button" type="submit">
              Pay Now
            </a>

          </div>
        </div>
      </div>
    </div>

    <div id="order_review" class="woocommerce-checkout-review-order">
      <div id="payment" class="woocommerce-checkout-payment">
      </div>
    </div>
  </form>
</div>
<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>

    <!-- PAGE TITLE
    ================================================== -->
    <header class="py-4 py-md-4 main-banner-in">
        <div class="container-fluid text-center py-xl-2">


            <h2 class="fw-semi-bold mb-0">Reset Password</h2>

        </div>
        <!-- Img -->

    </header>


    <!-- LOGIN
    ================================================== -->
    <div class="container-fluid mb-8">
        <div class="row gx-0">
            <div class="col-md-7 col-xl-5 mx-auto">
                <!-- Login -->

                <h5 class="mb-6 text-center"></h5>
                <!-- Form Login -->
                <form class="mb-5 bg-white border border-orange p-5" [formGroup]="resetform" (ngSubmit)="onSubmit()">
                    <!-- Email -->
                    <div class="form-group mb-5">
                        <label for="newpassword">
                            New Password
                        </label>
                        <input type="hidden" formControlName="resetToken" id="resetToken">
                        <input type="hidden" formControlName="UserId" id="UserId">
                        <input type="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" class="form-control form-control-sm" id="modalSigninEmail1"
                            placeholder="New Password">
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">New Password is required</div>
                            </div>
                    </div>
                    <div class="form-group mb-5">
                        <label for="confirmpassword">
                            Confirm Password
                        </label>
                        
                        <input type="password" formControlName="confirmpassword" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" class="form-control form-control-sm" id="modalSigninEmail1"
                            placeholder="Confirm Paswword">
                            <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                                <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
                            </div>
                    </div>

                    <!-- Password -->

                    <!-- Submit -->
                    <button class="btn btn-block btn-primary btn-sm" type="submit">
                        Reset Password
                    </button>
                </form>

                <!-- Text -->
                <p class="mb-0 font-size-sm text-center">
                    Remember your password? <a class="text-underline forgot" [routerLink]="['/login']">Log in</a>
                </p>
            </div>
        </div>
    </div>

   
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>
import { Component,NgZone, DoCheck, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DatePipe, formatDate, } from '@angular/common';
import { CourseService } from 'src/app/courses/services/course.service'
import { IndexService } from 'src/app/shared/services/index.service'
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Course } from 'src/app/courses/modals/course';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CartService } from 'src/app/shared/services/cart.service';
import { TimeConvertorPipe } from 'src/app/shared/time-convertor.pipe'
import { cart } from 'src/app/shared/modals/cart';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AccountService } from 'src/app/shared/services/account.service';
declare var YT: any; 

declare var bootstrap: any;
@Component({
  selector: 'app-couse-single',
  templateUrl: './course-single.component.html',
  styleUrls: ['./course-single.component.scss']
})

export class CourseSingleComponent implements OnInit {
  Latestcourses: any;
  viewCourse = 0;
  relatedcourse: any;
  Id: any;
  apiURL = environment.url
  viewData!: Course;
  instructor!: any;
  lessondata!: any;
  viewRelateData: any;

  current_Date!: any;
  course_Date!: any;
  Days!: any;
  addreviewsForm!: FormGroup;
  courseComments: any;
  CourseTestimonials: any;
  CourseFaqs!: any;
  productList: any;
  client: any;
  course: any;
  storeId: any;
  duration: any;
  videoDetail: any;
  cartCourse!: cart[];
  cartTotal!: cart;
  logged_user = this.cryptojsService.decryptLocalAccessToken();
  userid = this.cryptojsService.decryptLocalUserId()
  btnname: any;

  isShown: boolean = true;


  detailsOfCourse: any;
  detailsOfCourseInfo: any;
  detailsOfCourseAuthorWiseStudentsCount: any;
  detailsOfCourseExpert: any;
  alerdyAddedToCart:boolean = false;

  phoneNumber: string = '8828228330';  // Enter the phone number in international format
  defaultMessage: string = '“Hello, I would like to know more about the course.”';

  videoUrl: string = '';
  sanitizedVideoUrl: SafeResourceUrl = '';
  isVideoPlaying: boolean = false;

  prevideoUrl: string = '';
  presanitizedVideoUrl: SafeResourceUrl = '';
  
  playStartTime!: number;
  player: any;
  watchDuration = 0;

  courseFullDetails:any=[];
  lessionFirstVideoLink:any;
  lessionFirstVideoLinkPaid:boolean=false;
  myCourses:any=[]
  plainTextDescription:any; 

  constructor(private courseService: CourseService,
    private indexService: IndexService, private activateRoute: ActivatedRoute,
    private toastr: ToastrService, private formBuilder: FormBuilder, private router: Router,
    private cartService: CartService,
    private cryptojsService: CryptojsService,
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private accountService:AccountService
  ) { }

  ngOnInit(): void {
    this.getUserBuyCourse()
    this.getCourseId()
    // this.cartService.coursePresent.next(false)
    this.checkCoursePresentOrNot()

    //stop playlist when video is end
    window['onYouTubeIframeAPIReady'] = () => {
      this.createPlayer();
    };

    if (!window['YT']) {
      window['onYouTubeIframeAPIReady'] = () => this.initializePlayer();
    } else {
      this.initializePlayer();
    }

  }

  getUserBuyCourse(){
    let user_info = this.cryptojsService.decryptLocalUserId()
    this.accountService.mySubscription(user_info).subscribe((resMyCourses:any)=>{
      if(resMyCourses){
        this.myCourses=resMyCourses
      }else{
        this.myCourses=[]
      }
    })
  }

  //stop playlist when video is end
  createPlayer() {
    this.player = new YT.Player('ytPlayer', {
      events: {
        onStateChange: (event: any) => this.onPlayerStateChange(event),
      },
    });
  }

  private initializePlayer() {
    this.player = new YT.Player('ytPlayer', {
      events: {
        onStateChange: this.onPlayerStateChange.bind(this),
      },
    });
    // Placeholder function if additional setup is needed in the future
  }

  closeVideo() {
    // console.log("get data 1",this.player);
    
    if (this.player) {
      this.player.stopVideo();
    }
    this.isVideoPlaying = false;
    this.calculateWatchDuration();
  }

  onPlayerStateChange(event: any) {

    //14-11-2024 stop video when video is end
    if (event.data === YT.PlayerState.ENDED) {
      this.isVideoPlaying = false; 
    }
    //end

    this.ngZone.run(() => {
      switch (event.data) {
        case YT.PlayerState.PLAYING:
          this.playStartTime = Date.now();
          // console.log('Video started');
          break;
        case YT.PlayerState.PAUSED:
          this.calculateWatchDuration();
          // console.log('Video paused');
          break;
        case YT.PlayerState.ENDED:
          this.calculateWatchDuration();
          // console.log(`Video ended, watched for ${this.watchDuration / 1000} seconds`);
          break;
      }
    });
  }

  calculateWatchDuration() {
    if (this.playStartTime) {
      this.watchDuration += Date.now() - this.playStartTime;
      this.playStartTime = 0;
      // console.log(`Total watch duration: ${this.watchDuration / 1000} seconds`);
    }
  }


  getCourseId() {
    this.activateRoute.params.subscribe(params => {
      const id: any = params['id'];
      if (id) {
        this.getCourseDetails(id);
        this.getCourseFullDetails(id);
      }
    });
  }

  getCourseFullDetails(id:any){
    this.courseService.LessonDetails(id).subscribe((resLessonDetails: any) => {
      if (resLessonDetails && resLessonDetails.length > 0) {
        this.courseFullDetails = resLessonDetails[0];
        this.plainTextDescription= this.removeHtmlTags(this.courseFullDetails?.description);
        // Safely access video URL and assign it
        const firstLesson = this.courseFullDetails?.lessons?.[0];
        const firstVideoRow = firstLesson?.videoRow?.[0];
        this.lessionFirstVideoLink = firstVideoRow?.videoURL || '';
      
        // Iterate over lessons and video rows with safety checks
        for (let i = 0; i < (this.courseFullDetails.lessons?.length || 0); i++) {
          let videoRowArray: any = this.courseFullDetails.lessons[i]?.videoRow;
      
          if (videoRowArray && Array.isArray(videoRowArray)) {
            for (let j = 0; j < videoRowArray.length; j++) {
              if (this.myCourses.length > 0) {
                const isIdPresent = this.myCourses.some(
                  (obj: any) =>
                    obj?.courseId?.toLowerCase() ===
                    this.courseFullDetails?.id?.toLowerCase()
                );
                videoRowArray[j].paid = isIdPresent;
              } else {
                videoRowArray[j].paid = false;
              }
            }
          }
        }
      
        // Safely set the paid status of the first video link
        this.lessionFirstVideoLinkPaid = firstVideoRow?.paid || false;
      } else {
        this.courseFullDetails = [];
        this.plainTextDescription="Description not found"
      }

      // console.log("get data =====>",this.courseFullDetails,this.lessionFirstVideoLinkPaid,this.plainTextDescription);
      
    })
  }

  removeHtmlTags(htmlString: string): string {
    // Create a temporary element to use the browser's built-in parsing
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlString;
  
    // Return only the text content of the element, stripping away the tags
    return tempElement.textContent || tempElement.innerText || '';
  }


  goToMainScreen(selectedLink:any){
    // console.log("get video",selectedLink);
    
    if(selectedLink){
      this.lessionFirstVideoLink=selectedLink
      const element = document.getElementById('MainVideoSection');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        document.getElementById('mainVideoSectionClick')?.click()
      }
    }
  }
  getCourseDetails(id: any) {
    // console.log("get course id",id);
    
    this.cartService.checkCourseInsideCart({ id: id })
    // console.log("get course id",id);
    
    this.courseService.ViewMainCourse(id).subscribe((resViewMainCourse: any) => {
      // console.log("get data--->",resViewMainCourse);
      
      if (resViewMainCourse) {
        resViewMainCourse.quantity = 1;
        this.detailsOfCourse = resViewMainCourse


        let authorId = this.detailsOfCourse?.authorId
        this.indexService.getExpert(authorId).subscribe((resExpert: any) => {
          if (resExpert) {
            this.detailsOfCourseExpert = resExpert
          }
        })

      }
    })

    this.courseService.ViewCourse(id).subscribe((resViewCourse: any) => {
      if (resViewCourse.length > 0) {
        this.detailsOfCourseInfo = resViewCourse[0]
      }
    })

    this.courseService.courseInstructor(id).subscribe((resInstructor: any) => {
      if (resInstructor.length > 0) {
        this.detailsOfCourseAuthorWiseStudentsCount = resInstructor[0]
      }
    })


  }


  checkCoursePresentOrNot() {
    this.cartService.coursePresentSignal$.subscribe((resCoursePresentOrNot: any) => { 
      this.alerdyAddedToCart = resCoursePresentOrNot
    })
  }



  buynow() {
    let user_info = this.cryptojsService.decryptLocalUserId()
    let access_token = this.cryptojsService.decryptLocalAccessToken()
    if (user_info && access_token) {
      this.router.navigate(['/checkout']);
    } else {
      this.router.navigate(['/login']);
    }

  }

  playVideo(id: any) {
    this.courseService.LessonDetails(this.viewCourse).subscribe(res => {
      this.lessondata = res;
    })
  }

  getLatestCourses() {
    this.Latestcourses = [];
    this.courseService.getLatest().subscribe(response => {
      this.Latestcourses = response
    }, err => {

    })
  }


  getRelatedCourses(course_id: any) {
    this.Latestcourses = [];
    this.courseService.getRelatedCourse(this.viewCourse).subscribe(response => {
      this.Latestcourses = response
    }, err => {

    })
  }


  addToCart(course: any) {
    this.cartService.cartService(course)
  }




  addReviewToCourse() {
    if (this.addreviewsForm.invalid) {
      return;
    }
    let approvalFlag = true
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.activateRoute.snapshot.paramMap.get('Id');

    // this.courseService.addReviewToCourse(client_Id, course_Id, this.addreviewsForm.value.review, this.addreviewsForm.value.star, approvalFlag)
    //   .subscribe(result => {
    //     this.toastr.success("Review Added.")
    //   });
  }


  addToWishlist() {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.activateRoute.snapshot.paramMap.get('Id');

    this.courseService.addToWishlist(client_Id, course_Id)
      .subscribe(results => {
        this.toastr.success("Course added successfully to Wishlist")
      }, err => {
        if (err.status === 409) {
          this.toastr.error("Course already in Wishlist.");
        }
      });
  }


  CourseWiseComments() {
    let Courseid = this.activateRoute.snapshot.paramMap.get('Id');
    this.courseService.CourseComments(Courseid).subscribe(res => {
      this.courseComments = res;
    })
  }

  CourseTestimonial() {
    let Courseid = this.activateRoute.snapshot.paramMap.get('Id');
    this.courseService.CourseTestimonial(Courseid).subscribe(res => {
      this.CourseTestimonials = res;
    })
  }


  CourseFaq() {
    let Courseid = this.activateRoute.snapshot.paramMap.get('Id');
    this.courseService.CourseRelatedFaq(Courseid).subscribe(res => {
      this.CourseFaqs = res;
    })

  }


  goViewCart() {
    this.cryptojsService.userLoginSignal$.subscribe((status: any) => {
      if (status && this.cartService.cartCoursePresent()) {
        this.router.navigateByUrl('/view-cart')
      } else {
        this.toastr.warning("Please add at least one course to the cart!")
      }
    })

  }

  onActivate() {
    this.indexService.onActivate()
  }

  getWhatsAppLink(): string {
    return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.defaultMessage)}`;
  }


  openModal(url: string,paid:any) {
    // console.log("get data url",url);
    
    if (paid) {
      // If paid, show the video as usual
      const youtubeEmbedUrl = this.getEmbedUrl(url);
      this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeEmbedUrl);
      this.isVideoPlaying = true;
      setTimeout(() => this.createPlayer(), 0);
    } else {
      // If not paid, start the video for 15 seconds
      const youtubeEmbedUrl = this.getEmbedUrl(url);
      this.sanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeEmbedUrl);
      this.isVideoPlaying = true;
  
      setTimeout(() => {
        this.stopVideoAndShowImage();
        this.toastr.warning('This is a paid video. Please buy the course to access this lesson');
      }, 15000);  // 15 seconds (15000 milliseconds)
  
      setTimeout(() => this.createPlayer(), 0); // Create the player immediately
    }
  }

  stopVideoAndShowImage() {
    this.isVideoPlaying = false;
    if (this.player) {
      this.player.stopVideo(); // Stop the video after 15 seconds
    }
  }

  getEmbedUrl(url: string): string {
    const videoId = url.split('v=')[1];
    // return `https://www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0&modestbranding=1&showinfo=0`;
    return `https://www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0&modestbranding=1&showinfo=0&controls=1&autoplay=1`;
  }


  preopenModal(url: string) {
    // console.log("get url---->",url);
    
    const youtubeEmbedUrl = this.getEmbedUrl(url);
    this.presanitizedVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(youtubeEmbedUrl);
    const modalElement = document.getElementById('videoModal');
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.show();

       // Create the YouTube player
    setTimeout(() => {
      const player = new YT.Player('ytPlayer', {
        events: {
          'onStateChange': (event:any) => {
            if (event.data === YT.PlayerState.ENDED) {
              // Close the modal when the video ends
              modal.hide();
            }
          }
        }
      });
    }, 0);

      const cleanUpListener = () => {
        this.onModalClose();
        modalElement.removeEventListener('hide.bs.modal', cleanUpListener);
      };

      modalElement.addEventListener('hide.bs.modal', cleanUpListener);

    }
  }

  checkPaid(checkPaid:any){
    if(!checkPaid.paid){
      setTimeout(()=>{
        document.getElementById('closeLessionModel')?.click()
        this.toastr.warning("This is a paid video. Please buy the course to access this lesson")
      },15000)
    }
  }

  onModalClose() {
    
    this.presanitizedVideoUrl = '';
    const iframe = document.querySelector('#videoModal iframe') as HTMLIFrameElement;
    if (iframe) {
      iframe.src = ''; 
    }
  }

  downloadPdf(){
    const pdfUrl = 'https://nlsblog.org/wp-content/uploads/2020/06/true-pdf-sample-1.pdf';
    const pdfName = 'sample.pdf';

    // Fetch the PDF
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        // Create a link element, set the href to the blob URL, and trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = pdfName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a); // Clean up the link
        window.URL.revokeObjectURL(url); // Release the memory
      })
      .catch(err => console.error('Error fetching PDF:', err));
  }




 

  
}

import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MessageDto } from './Dto/MessageDto';
import { ChatService } from './services/chat.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'videostream';
  href: any;
  url: any;

  constructor(private router: Router,private chatService: ChatService) { }


  ngOnInit(): void {
  this.router.events.subscribe((e) => {
    if (e instanceof NavigationEnd) {
      
      this.url  = e.url.split('/');
      // console.log(this.url[1],'uuu');
    }
  });
 // this.chatService.retrieveMappedObject().subscribe( (receivedObj: MessageDto) => { this.addToInbox(receivedObj);});  // calls the service method to get the new messages sent                                    
  }

  onActivate() {

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

 

  // msgDto: MessageDto = new MessageDto();
  // msgInboxArray: MessageDto[] = [];

  // send(): void {
  //   if(this.msgDto) {
  //     if(this.msgDto.user.length == 0 || this.msgDto.user.length == 0){
  //       window.alert("Both fields are required.");
  //       return;
  //     } else {
  //       this.chatService.broadcastMessage(this.msgDto);                   // Send the message via a service
  //     }
  //   }
  // }

  // addToInbox(obj: MessageDto) {
  //   let newObj = new MessageDto();
  //   newObj.user = obj.user;
  //   newObj.msgText = obj.msgText;
  //   this.msgInboxArray.push(newObj);

  // }
}



<footer style="background-color: #6885ee59;" class="footer-gap  pt-md-8 ">
    <div class="container ">
        <div class="row pt-5">

            <div class="col-12 col-md-3 col-lg-3  footer-gp-icon">

                <!-- Brand -->
                <div class="mx-auto">
                    <img src="assets/images/Antarkar%20Sir_s%20logo%20color.png" alt="Udyami Maharashtra"
                        class="footer-brand img-fluid mb-4 h-130p">
                </div>
                <!-- Text -->
                <p class="text-dark mb-2 fsf ">
                    We are passionate about education and dedicated to providing high- quality learning for aspiring
                    JEE, BITSAT, IAT, MHT CET students
                </p>
                <!-- Social -->
                <ul class="list-unstyled list-inline list-social  pb--20 mb-md-0">
                    <li class="list-inline-item list-social-item">
                        <a href="https://www.facebook.com/activeneuronstechnology" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                        <!-- <a [href]="getWhatsAppLink()" target="_blank"><i class="fa-brands fa-whatsapp" style="font-size: 30px;"></i></a> -->
                    </li>
                    <li class="list-inline-item list-social-item">
                        <a href="https://x.com/i/flow/login?redirect_after_login=%2FANTLLP" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li class="list-inline-item list-social-item">
                        <a href="https://www.instagram.com/ant_llp/" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-instagram"></i>
                        </a>
                    </li>
                    <li class="list-inline-item list-social-item">
                        <a href="https://www.linkedin.com/in/active-neurons-technology-llp-270860263/" target="_blank"
                            class="text-dark font-size-sm w-36 h-36 shadow-dark-hover d-flex align-items-center justify-content-center rounded-circle border-hover">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-6 col-md-3 col-lg-3  mb-respon">
                <div class="mb-5 mb-xl-0 footer-accordion">

                    <!-- Heading -->
                    <div id="widgetOne">
                        <h5 class="mb-2 ">
                            <h5 class="mb-0"> Explore </h5>
                        </h5>
                    </div>

                    <div id="widgetcollapseOne" class="collapse show text-dark fsf " aria-labelledby="widgetOne"
                        data-parent="#accordionFooter">
                        <!-- List -->
                        <ul class="list-unstyled text-dark mb-6 mb-md-8 mb-lg-0 ">
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/index']" class="text-reset fsf ">
                                    Home
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/about-us']" class="text-reset fsf ">
                                    About Us
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/courses']" class="text-reset fsf ">
                                    Course
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/contact-us']" class="text-reset fsf ">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 col-lg-3  mb-respon">
                <div class="mb-5 mb-xl-0 footer-accordion">

                    <!-- Heading -->
                    <div id="widgetOne">
                        <h5 class="mb-2 ">
                            <h5 class="mb-0"> Quick Links </h5>
                        </h5>
                    </div>

                    <div id="widgetcollapseOne" class="collapse show text-dark" aria-labelledby="widgetOne"
                        data-parent="#accordionFooter">
                        <!-- List -->
                        <ul class="list-unstyled text-dark mb-6 mb-md-8 mb-lg-0 ">

                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/faq']" class="text-reset fsf">
                                    FAQ
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/why-us']" class="text-reset fsf">
                                    Why Us
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/privacy-policy'] "
                                    class="text-reset fsf">
                                    Privacy Policy
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/terms-and-conditions']"
                                    (click)="findToaster()" class="text-reset fsf">
                                    Terms & Conditions
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/refund-policy']" class="text-reset fsf">
                                    Refund Policy
                                </a>
                            </li>
                            <li class="mb-3">
                                <a style="color: #000 !important;" [routerLink]="['/disclaimer']" class="text-reset fsf">
                                    Our Disclaimer
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-3 col-lg-3 mb-respon">

                <div class="mb-5 mb-xl-0 ms-xl-6 footer-accordion">

                    <!-- Heading -->
                    <div id="widgetOne">
                        <h5 class="mb-2 ">
                            <h5 class="mb-0"> Contacts </h5>
                        </h5>
                    </div>
                    <p class="mb-2 fsf text-dark "><i style="color: #553cdf;"
                            class="fa-regular fa-location-dot"></i> Vile Parle, Mumbai, India</p>
                    <!-- <p class="mb-5 font-size-sm text-dark"><i style="color: #553cdf;"
                            class="fa-regular fa-phone mt-3"></i> +91 8828228330 </p> -->
                    <div class="mb-2">
                        <a href="mailto:support@udyami.com" class="text-dark fsf"> <i
                                style="color: #553cdf;" class="fa-regular fa-envelope fsf"></i> antarkarsir1@gmail.com</a>
                    </div>

                </div>
            </div>

        </div> <!-- / .row -->
    </div> <!-- / .container -->
    <div class="copyright-area-one-border mt-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright-area-one fsf">
                        <p style="color: #000;">Copyright © 2024 All Rights Reserved by Antarkar Sir’s Educative
                            Programmes
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Modal -->
<div class="modal fade" id="appointmentModal" tabindex="-1" aria-labelledby="appointmentModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 800px;">
        <div class="modal-content" style="border-radius: 12px;">
            <div class="modal-header">
                <h5 class="modal-title" id="appointmentModalLabel">Schedule Appointment</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="scheduleAppointmentClose"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="bookAppointmentForm" (ngSubmit)="submitBookAppointmentForm()">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="nameInput" class="form-label">Name</label>
                            <input type="text" class="form-control" id="nameInput" placeholder="Enter your name"
                                formControlName="name">
                            <div class="error-code"
                                *ngIf="bookAppointmentForm.get('name')?.touched && bookAppointmentForm.get('name')?.invalid">
                                Name is required.
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="emailInput" class="form-label">Email ID</label>
                            <input type="email" class="form-control" id="emailInput" placeholder="Enter your email"
                                formControlName="email">
                            <div class="error-code"
                                *ngIf="bookAppointmentForm.get('email')?.touched && bookAppointmentForm.get('email')?.invalid">
                                Email is required.
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="mobileInput" class="form-label">Mobile No</label>
                            <input type="tel" class="form-control" id="mobileInput"
                                placeholder="Enter your mobile number" formControlName="mobileno">
                            <div class="error-code"
                                *ngIf="bookAppointmentForm.get('mobileno')?.touched && bookAppointmentForm.get('mobileno')?.invalid">
                                Mobile No is required.
                            </div>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="counsellingSelect" class="form-label">Counselling
                                Type</label>
                            <select class="form-select" id="counsellingSelect" formControlName="counsellingtype">
                                <option value="" disabled selected>Select counselling type</option>
                                <option value="Preparation Counselling"> Preparation Counselling</option>
                                <option value="Admission Counselling">Admission Counselling</option>
                            </select>
                            <div class="error-code"
                                *ngIf="bookAppointmentForm.get('counsellingtype')?.touched && bookAppointmentForm.get('counsellingtype')?.invalid">
                                Counselling Type is required.
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class=" rts-btn btn-border">Submit</button>
                        <button type="button" class=" rts-btn btn-border mr-2" data-bs-dismiss="modal"
                            aria-label="Close" (click)="resetBookAppointment()">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="resetPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Reset Password</h5>
                <button id="resetPasswordID" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                    (click)="setOTPFlag()"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitAdmission()">
                    <div class="mb-3">
                        <label for="emailOrPassword" class="form-label">Email or Phone Number</label>
                        <input type="text" id="emailOrPassword" class="form-control"
                            formControlName="emailorphonenumber" placeholder="Enter your email or password" />
                        <div *ngIf="resetPasswordForm.get('emailorphonenumber')?.invalid && resetPasswordForm.get('emailorphonenumber')?.touched"
                            class="text-danger">
                            Please enter a valid email or phone number
                        </div>
                    </div>
                    <button *ngIf="!showOtpInput" type="button" class="btn btn-primary color-code"
                        (click)="showResetPasswordForm()">Send OTP</button>
                    <button type="submit" *ngIf="showOtpInput" class="btn btn-primary color-code"
                        [disabled]="resetPasswordForm.invalid">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>


<button type="button" data-bs-toggle="modal" data-bs-target="#confirmPasswordModal" style="display: none;"
    id="confirmPasswordID">Set New Password</button>
<div class="modal fade" id="confirmPasswordModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Set New Password</h5>
                <button id="resetPasswordID" type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="confirmPasswordForm" (ngSubmit)="onConfirmSubmit()">
                    <div class="mb-3">
                        <label for="emailOrPassword" class="form-label">Mobile Number</label>
                        <input type="text" id="emailOrPassword" class="form-control" formControlName="mobile"
                            placeholder="Enter mobile number" readonly />
                        <div *ngIf="confirmPasswordForm.get('mobile')?.invalid && confirmPasswordForm.get('mobile')?.touched"
                            class="text-danger">
                            Please enter mobile number
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="otp" class="form-label">OTP</label>
                        <input type="text" id="otp" class="form-control" formControlName="otp"
                            placeholder="Enter your OTP" maxlength="6" />
                        <div *ngIf="confirmPasswordForm.get('otp')?.invalid && confirmPasswordForm.get('otp')?.touched"
                            class="text-danger">
                            OTP is required
                        </div>
                    </div>
                    <button *ngIf="!showConfimPassword" type="button" class="btn btn-primary color-code"
                        (click)="verifyOTP()">Verify OTP</button>

                    <div class="mb-3" *ngIf="showConfimPassword">
                        <label for="confirmPassword" class="form-label">Confirm Password</label>
                        <div class="position-relative">
                            <input [type]="showPassword ? 'text' : 'password'" id="confirmPassword"
                                class="form-control pr-4" formControlName="confirmpassword"
                                placeholder="Enter your password" maxlength="15" />
                            <i [class]="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'" class="eye-icon"
                                (click)="togglePasswordVisibility()">
                            </i>
                        </div>
                        <div *ngIf="confirmPasswordForm.get('confirmpassword')?.invalid && confirmPasswordForm.get('confirmpassword')?.touched"
                            class="text-danger">
                            Confirm password is required
                        </div>
                    </div>

                    <button type="submit" class="btn btn-primary color-code" [disabled]="confirmPasswordForm.invalid"
                        *ngIf="showConfimPassword">Submit</button>
                </form>
            </div>
        </div>
    </div>
</div>



<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop"
    id="bookAppointmentDateTime" style="display: none;">
    Date Time
</button>

<!-- Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Book Appoitment Date & Timeslot</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="bookAppintmentDateTimeClose"></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="bookAppointmentDateTimeForm" (ngSubmit)="submitBookAppointmentDateTimeForm()">
                    <div class="row">
                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="nameInput" class="form-label">Date</label>
                            <input type="date" class="form-control" id="nameInput" formControlName="date">
                            <div class="error-code"
                                *ngIf="bookAppointmentDateTimeForm.get('date')?.touched && bookAppointmentDateTimeForm.get('date')?.invalid">
                                Date is required.
                            </div>
                        </div>

                        <div class="col-md-6 mb-3">
                            <label style="color: #000;" for="counsellingSelect" class="form-label">Time Slot</label>
                            <select class="form-select" id="counsellingSelect" formControlName="timeslot">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let option of bookAppointmentTimeSlotArray" [value]="option?.name">
                                    {{option.name}}</option>
                            </select>
                            <div class="error-code"
                                *ngIf="bookAppointmentDateTimeForm.get('timeslot')?.touched && bookAppointmentDateTimeForm.get('timeslot')?.invalid">
                                Time Slot is required.
                            </div>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="submit" class=" rts-btn btn-border">Submit</button>
                        <button type="button" class=" rts-btn btn-border mr-2" data-bs-dismiss="modal"
                            aria-label="Close" (click)="resetBookAppointmentDateTime()">Cancel</button>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>
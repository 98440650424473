import { NgModule } from '@angular/core';
import { CommonModule,DatePipe } from '@angular/common';
import { CartRoutingModule } from './cart-routing.module';
import { CheckoutComponent } from './checkout/checkout.component';
import { ViewCartComponent } from './view-cart/view-cart.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    CheckoutComponent,
    ViewCartComponent,  
  ],
  providers: [DatePipe],
  imports: [
    CommonModule,
    CartRoutingModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class CartModule { }

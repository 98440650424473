  <!-- PAGE TITLE
    ================================================== -->
    <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="breadcrumb-main-wrapper">
                                <h1 class="title">Privacy Policy </h1>
                                <div class="pagination-wrapper">
                                    <a routerLink="/index">Home</a>
                                    <i class="fa-regular fa-chevron-right"></i>
                                    <a class="active">Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="breadcrumb-img-book">
                                <img src="\assets\images\breadcrumb\book.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <main class="policy-container">
        <section class="policy-header">
            <h2>Privacy Policy</h2>
            <!-- <p class="effective-date">Effective Date: <strong>[29/10/2024]</strong></p> -->
            <p class="intro">At Antarkar Sir’s Educative Programmes ("we," "us," or "our"), we are committed to respecting your privacy and ensuring the protection of your personal information, in compliance with applicable data protection laws and regulations.
            </p>
            <p>This privacy policy describes the information we (“Antarkar Sir's Education Programming (antllp.com)”, “site”, “website”, “we” or “us”) may collect from you or that you may provide to us when you visit ‘Antarkar Sir's Education Programming (antllp.com)’. This privacy policy information covers what information is collected, what we do with it, and what you can do about it. You can use this information to make your decisions about your privacy.</p>
            <p>By using the site, services or courses you consent to the collection, use and disclosure of your personal identifiable site information (any information related to your use of site or service, without limitation, such as enrolment)
                And course information (any information related to taking the Courses, including, without limitation, interchanges with the course tutors, pre-recorded lectures, other students (“Users”), other items submitted to satisfy course requirements) as applicable in accordance with the privacy policy.
            </p>
            <p>
                This Privacy Policy applies to those who use our platform ("Platform") or whose information we collect in connection with our educational services, including students, partner institutions, and third-party providers.
            </p>
        </section>

        <!-- Policy Sections in Rows -->
        <div class="policy-row">
            <h3>1. Personal Information</h3>
            <p>
               <b>Personal Information (PI)</b> refers to any information that can be used to identify an individual, such as name, contact information, identification numbers, or other personal details as defined by relevant laws.
            </p>
        </div>

        <div class="policy-row">
            <h3>2. Information Collection and Use</h3>
            <p>For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy. The app does use third party services that may collect information used to identify you.
            </p>
        </div>

        <div class="policy-row">
            <h3>3. Information We Collect</h3>
            <p>We collect the following types of personal information:
            </p>
            <p><b>Registration Information:</b> When you register with us, we may collect your name, email address, phone number, and educational background to create/update your account, parents email-id, Number.</p>
            <p><b>Usage Information:</b> We collect data on how you use our Platform, including IP addresses, device information, and location data.</p>
            <p><b>Profile Information:</b> This includes details like your educational qualifications, preferences to personalize our services.</p>
            <p><b>Automatically Collected Information:</b> In the course of your usage, we may automatically procure certain details about your device, encompassing its type, operating system, unique identifiers, IP address, and mobile network particulars.</p>
        </div>

        <div class="policy-row">
            <h3>4. How We Use Your Information</h3>
            <p>We may process your personal information for the following purposes:
            </p>
            <p>To provide and improve our educational programs and services.
            </p>
            <p>To communicate with you regarding your account, updates, or support queries.</p>
            <p>To process payments for services.
            </p>
            <p>To send you alerts, or promotional content (if subscribed).
            </p>
            <p>To comply with legal obligations and resolve disputes.
            </p>
            <p>To detect and prevent fraud or unauthorized access to the Platform.</p>
        </div>

        <div class="policy-row">
            <h3>5. Utilization of Information</h3>
            <p>We may employ the gathered information for various purposes, including but not limited to:</p>
            <p>Provision and maintenance of the Website.</p>
            <p>Enhancement and personalization of the Website.</p>
            <p>Communication regarding website related matters.</p>
            <p>Analysis of Website usage patterns.</p>
            <p>Safeguarding the security and integrity of the website.</p>
            <p>Adherence to legal obligations.</p>
        </div>

        <div class="policy-row">
            <h3>6. Data	Retention</h3>
            <p>We will retain your information for the duration necessary to fulfill the objectives outlined in this Privacy Policy, unless a longer retention period is mandated or permissible by law. <b>(for 6 months)</b>
            </p>
        </div>

        <div class="policy-row">
            <h3>7. Cookies and Tracking Technologies</h3>
            <p>We use cookies and similar tracking technologies to enhance your user experience and analyze site usage. Cookies may store information about your preferences and browsing patterns. You may control cookies through your browser settings, though this may affect certain features of the Platform.</p>
        </div>

        <div class="policy-row">
            <h3>8. Data Security</h3>
            <p>We implement physical, electronic, and procedural safeguards to protect your personal information. However, while we strive to protect your data, no method of data transmission or storage is completely secure.
            </p>
        </div>

        <div class="policy-row">
            <h3>9. Security</h3>
            <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable.
            </p>
            <p>We implement reasonable measures to safeguard your personal information from unauthorized access, alteration, disclosure, or destruction.
            </p>
        </div>

        <div class="policy-row">
            <h3>10. Children’s Privacy
            </h3>
            <p>Our services are intended for users above the age of 12. If you are under 12, you must use the Platform under the supervision of a parent, legal guardian, or responsible adult.
            </p>
            <p>"For users under age, consent from a parent or guardian is required for processing their personal information."
            </p>
        </div>

        <div class="policy-row">
            <h3>11. Retention of Personal Information
            </h3>
            <p>We will retain your personal data only as long as necessary to fulfill the purposes outlined in this policy or as required by law. After the relevant period, your information will be securely deleted or archived.
            </p>
        </div>

        <div class="policy-row">
            <h3>12. Your Rights
            </h3>
            <p>You have the right to access, correct, or delete your personal information at any time. You can also opt-out of marketing communications. To exercise these rights, contact us at [info@antarkarsir.com].
            </p>
        </div>

        <div class="policy-row">
            <h3>13. Changes to This Privacy Policy
            </h3>
            <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated "Effective Date." We encourage you to review the policy periodically.
            </p>
        </div>

        <div class="policy-row">
            <h3>14. Contact Us
            </h3>
            <p>If you have any questions or concerns about this Privacy Policy or the handling of your personal data, please contact us at: <b>Antarkar Sir’s Educative sProgrammes
            </b>
            <b>Email: [info@antarkarsir.com]
            </b>
            </p>
        </div>

        <footer class="policy-footer">
            <p>For inquiries, please contact us at <a href="mailto:antarkarsir1@gmail.com">antarkarsir1@gmail.com</a>.</p>
        </footer>
    </main>
<div class="login-bg pt-5 pb-5">
  <div class="container mb-8">
    <div class="row ">
      <div class="col-12">
        <div class="container mb-8">
          <div class="row ">
            <div class=" col-12 d-flex justify-content-center">
              <div class="login-page-form-area">
                <h4 class="title">Login to Your Account👋</h4>
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                  <div class="single-input-wrapper">
                    <label for="email">Your Email or Mobile Number</label>
                    <input id="email" type="email" placeholder="Enter Your Email"
                      formControlName="userNameOrEmailAddress">
                    <div class="text-danger"
                      *ngIf="loginForm.controls['userNameOrEmailAddress'].invalid && loginForm.controls['userNameOrEmailAddress'].touched">
                      Email or Number is required
                    </div>
                  </div>
                  <!-- <div class="single-input-wrapper">
              <label for="mob">Your Mobile Number</label>
              <input id="mob" type="number" placeholder="Enter Your Mobile Number">
            </div> -->

                  <div class="single-input-wrapper">
                    <label for="password">Your Password</label>
                    <div class="input-icon-wrapper">
                      <input id="password" [type]="showPassword ? 'text' : 'password'" placeholder="Password"
                        formControlName="password">
                      <i class="fa fa-look" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'" aria-hidden="true"
                        (click)="displayPassWord()"></i>
                    </div>
                    <div class="text-danger"
                      *ngIf="loginForm.controls['password'].invalid && loginForm.controls['password'].touched">
                      Password is required
                    </div>
                  </div>

                  <div class="single-checkbox-filter">
                    <div class="check-box">
                      <input type="checkbox" id="type-1">
                      <label for="type-1">Remember Me</label><br>
                    </div>
                  </div>
                  <button type="submit" class="rts-btn btn-primary">Login</button>
                  <p>If you forgot your password, <a class="c-pointer" data-bs-toggle="modal" data-bs-target="#resetPasswordModal">you can reset it here.</a></p>
                  <div class="google-apple-wrapper">
                    <div class="google">
                      <img src="assets/images/contact/06.png" alt="contact" onclick="handleGoogleLogin()">
                    </div>
                    <div class="google">
                      <img src="assets/images/contact/07.png" alt="contact" onclick="handleAppleLogin()">
                    </div>

                    <!-- <div id="g_id_onload"
                data-client_id="1035941459256-9t7fmuelviq37bd28vu64e8i7afq03iu.apps.googleusercontent.com"
                data-context="signin" data-ux_mode="popup"
                data-login_uri="https://ohmadmin.activeneuronstech.com/api/account/login" data-auto_prompt="false">
              </div>

              <div class="g_id_signin" data-type="standard" data-shape="rectangular" data-theme="outline"
                data-text="signin_with" data-size="large" data-logo_alignment="left">
              </div>  -->

                  </div>
                  <p>Don't Have an account? <a routerLink="/register">Registration</a></p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="bubbles_wrap">
    <div class="bubble x1"></div>
    <div class="bubble x2"></div>
    <div class="bubble x3"></div>
    <div class="bubble x4"></div>
    <div class="bubble x5"></div>
    <div class="bubble x6"></div>
    <div class="bubble x7"></div>
    <div class="bubble x8"></div>
    <div class="bubble x9"></div>
    <div class="bubble x10"></div>
  </div>

  <!-- Scroll To Top Start -->
  <a href="#main-banner" class="scroll-top" id="scroll-to-top">
    <i class="fa fa-arrow-up" aria-hidden="true"></i>
  </a>
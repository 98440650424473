    <!-- MODALS
    ================================================== -->
    <!-- Modal Sidebar account -->
    <div class="modal fade" id="modalExample" tabindex="-1" role="dialog" aria-labelledby="modalExampleTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">

                    <!-- Close -->
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>

                    <!-- Heading -->
                    <h2 class="fw-bold text-center mb-1" id="modalExampleTitle">
                        Schedule a demo with us
                    </h2>

                    <!-- Text -->
                    <p class="font-size-lg text-center text-muted mb-6 mb-md-8">
                        We can help you solve company communication.
                    </p>

                    <!-- Form -->
                    <form>
                        <div class="row">
                            <div class="col-12 col-md-6">

                                <!-- First name -->
                                <div class="form-label-group">
                                    <input type="text" class="form-control form-control-flush"
                                        id="registrationFirstNameModal" placeholder="First name">
                                    <label for="registrationFirstNameModal">First name</label>
                                </div>

                            </div>
                            <div class="col-12 col-md-6">

                                <!-- Last name -->
                                <div class="form-label-group">
                                    <input type="text" class="form-control form-control-flush"
                                        id="registrationLastNameModal" placeholder="Last name">
                                    <label for="registrationLastNameModal">Last name</label>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">

                                <!-- Email -->
                                <div class="form-label-group">
                                    <input type="email" class="form-control form-control-flush"
                                        id="registrationEmailModal" placeholder="Email">
                                    <label for="registrationEmailModal">Email</label>
                                </div>

                            </div>
                            <div class="col-12 col-md-6">

                                <!-- Password -->
                                <div class="form-label-group">
                                    <input type="password" class="form-control form-control-flush"
                                        id="registrationPasswordModal" placeholder="Password">
                                    <label for="registrationPasswordModal">Password</label>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">

                                <!-- Submit -->
                                <button class="btn btn-block btn-primary mt-3 lift">
                                    Request a demo
                                </button>

                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
    <!-- Modal Sidebar cart -->
    <div class="modal modal-sidebar left fade-left fade" id="cartModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header mb-4">
                    <h5 class="modal-title">Your Shopping Cart 1</h5>
                    <button type="button" class="close text-primary" data-bs-dismiss="modal" aria-label="Close">
                        <!-- Icon -->
                        <i class="fas fa-lg fa-times"></i>

                    </button>
                </div>

                <div class="modal-body">
                    <ul class="list-group list-group-flush mb-5">
                        <li class="list-group-item border-bottom py-0">
                            <div class="d-flex py-5">
                                <div class="bg-gray-200 w-60p h-60p rounded-circle overflow-hidden"></div>

                                <div class="flex-grow-1 mt-1 ms-4">
                                    <h6 class="fw-normal mb-0">Basic of Nature</h6>
                                    <div class="font-size-sm">₹18.00</div>
                                </div>

                                <a href="#" class="d-inline-flex text-secondary">
                                    <!-- Icon -->
                                    <i class="far fa-sm fa-trash-alt"></i>

                                </a>
                            </div>
                        </li>

                        <li class="list-group-item border-bottom py-0">
                            <div class="d-flex py-5">
                                <div class="bg-gray-200 w-60p h-60p rounded-circle overflow-hidden"></div>

                                <div class="flex-grow-1 mt-1 ms-4">
                                    <h6 class="fw-normal mb-0">Color Harriet Tubman</h6>
                                    <div class="font-size-sm">₹18.00</div>
                                </div>

                                <a href="#" class="d-inline-flex text-secondary">
                                    <!-- Icon -->
                                    <i class="far fa-sm fa-trash-alt"></i>

                                </a>
                            </div>
                        </li>

                        <li class="list-group-item border-bottom py-0">
                            <div class="d-flex py-5">
                                <div class="bg-gray-200 w-60p h-60p rounded-circle overflow-hidden"></div>

                                <div class="flex-grow-1 mt-1 ms-4">
                                    <h6 class="fw-normal mb-0">Digital Photography</h6>
                                    <div class="font-size-sm">₹18.00</div>
                                </div>

                                <a href="#" class="d-inline-flex text-secondary">
                                    <!-- Icon -->
                                    <i class="far fa-sm fa-trash-alt"></i>

                                </a>
                            </div>
                        </li>
                    </ul>

                    <div class="d-flex mb-5">
                        <h5 class="mb-0 me-auto">Order Subtotal</h5>
                        <h5 class="mb-0">₹121.87</h5>
                    </div>

                    <div class="d-md-flex justify-content-between">
                        <a href="cart.html"
                            class="d-block d-md-inline-block mb-4 mb-md-0 btn btn-primary btn-sm-wide">VIEW
                            CART</a>
                        <a href="checkout.html"
                            class="d-block d-md-inline-block btn btn-primary btn-sm-wide text-white">CHECKOUT</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


   <!-- PAGE TITLE
    ================================================== -->
    <header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax
        data-speed=".8" style="background-image: url(assets/img/covers/cover-19.jpg);">
        <div class="container-fluid text-center ">
            <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">EVENTS</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-scroll justify-content-center py-1 ">
                    <li class="breadcrumb-item">
                        <a class="text-white" href="#">
                            Home
                        </a>
                    </li>
                    <li class="breadcrumb-item text-white active" aria-current="page">
                        Events
                    </li>
                </ol>
            </nav>
        </div>
        <!-- Img -->

    </header>





    <!-- EVENT
    ================================================== -->
    <div class="container-fluid py-8 pb-xl-7">
        <div class="row  mb-4">

             <!-- Card -->
             <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                <!-- Image -->
                <div class="col-md-4 card-zoom position-relative">
                   

                    <a href="event-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                        <img class="rounded shadow-light-lg" src="assets/img/products/product-15.jpg" alt="...">
                    </a>

                    <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                        <span class="text-white text-uppercase fw-bold font-size-xs">Dr. Omkar Mali</span>
                    </span>
                </div>

                <!-- Footer -->
                <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                    <!-- Preheading -->
                    <a href="event-single.html"><span class="mb-1 d-inline-block text-gray-800">06 April 2022</span></a>

                    <!-- Heading -->
                    <div class="position-relative">
                        <a href="event-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">Import Export Event</h4></a>
                        <p>Do you want to become a UI/UX designer but you don't know where to start? This course will allow you to develop your user interface design skills and you can add UI designer to your CV and start getting clients for your skills.</p>
                        <ul class="nav mx-n3 mb-3">
                           
                            <li class="nav-item px-3">
                                <div class="d-flex align-items-center">
                                    <div class="me-2 d-flex text-secondary icon-uxs">
                                        <!-- Icon -->
                                        <i class="far fa-clock"></i>

                                    </div>
                                    <div class="font-size-sm"> 8:00 am - 5:00 pm</div>
                                </div>
                            </li>
                            <li class="nav-item px-3 mb-3 mb-md-0">
                                <div class="d-flex align-items-center">
                                    <div class="me-2 d-flex text-secondary icon-uxs">
                                        <!-- Icon -->
                                       <i class="far fa-user-circle"></i>
    
                                    </div>
                                    <div class="font-size-sm">Dr. Omkar Mali</div>
                                </div>
                            </li>
                            <li class="nav-item px-3 mb-3 mb-md-0">
                                <div class="d-flex align-items-center">
                                    <div class="me-2 d-flex text-secondary icon-uxs">
                                        <!-- Icon -->
                                       <i class="fas fa-mobile-alt"></i>
    
                                    </div>
                                    <div class="font-size-sm">+91 92979 09090</div>
                                </div>
                            </li>
                        </ul>

                        <div class="row mx-n2 align-items-center">
                            <div class="col px-2">
                                <del class="font-size-sm">$959</del>
                                <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                            </div>

                           
                        </div>
                        <a href="event-single.html" class="stretched-link mt-3 btn btn-sm btn-wide text-white mw-md-280p">Read More</a>
                    </div>
                </div>
            </div>
          
              <!-- Card -->
              <div class="card border shadow p-2 lift sk-fade mb-6 flex-md-row align-items-center row gx-0">
                <!-- Image -->
                <div class="col-md-4 card-zoom position-relative">
                   

                    <a href="event-single.html" class="card-img sk-thumbnail img-ratio-2 d-block">
                        <img class="rounded shadow-light-lg" src="assets/img/products/product-15.jpg" alt="...">
                    </a>

                    <span class="badge sk-fade-bottom badge-lg badge-orange badge-pill badge-float bottom-0 left-0 mb-4 ms-4">
                        <span class="text-white text-uppercase fw-bold font-size-xs">Dr. Omkar Mali</span>
                    </span>
                </div>

                <!-- Footer -->
                <div class="col-md-8 card-footer px-2 px-md-5 py-4 py-md-0 position-relative">
                    <!-- Preheading -->
                    <a href="event-single.html"><span class="mb-1 d-inline-block text-gray-800">06 April 2022</span></a>

                    <!-- Heading -->
                    <div class="position-relative">
                        <a href="event-single.html" class="d-block stretched-link"><h4 class="line-clamp-2 me-md-6 me-lg-10 me-xl-4 mb-3">Import Export Event</h4></a>
                        <p>Do you want to become a UI/UX designer but you don't know where to start? This course will allow you to develop your user interface design skills and you can add UI designer to your CV and start getting clients for your skills.</p>
                        <ul class="nav mx-n3 mb-3">
                           
                            <li class="nav-item px-3">
                                <div class="d-flex align-items-center">
                                    <div class="me-2 d-flex text-secondary icon-uxs">
                                        <!-- Icon -->
                                        <i class="far fa-clock"></i>

                                    </div>
                                    <div class="font-size-sm"> 8:00 am - 5:00 pm</div>
                                </div>
                            </li>
                            <li class="nav-item px-3 mb-3 mb-md-0">
                                <div class="d-flex align-items-center">
                                    <div class="me-2 d-flex text-secondary icon-uxs">
                                        <!-- Icon -->
                                       <i class="far fa-user-circle"></i>
    
                                    </div>
                                    <div class="font-size-sm">Dr. Omkar Mali</div>
                                </div>
                            </li>
                            <li class="nav-item px-3 mb-3 mb-md-0">
                                <div class="d-flex align-items-center">
                                    <div class="me-2 d-flex text-secondary icon-uxs">
                                        <!-- Icon -->
                                       <i class="fas fa-mobile-alt"></i>
    
                                    </div>
                                    <div class="font-size-sm">+91 92979 09090</div>
                                </div>
                            </li>
                        </ul>

                        <div class="row mx-n2 align-items-center">
                            <div class="col px-2">
                                <del class="font-size-sm">$959</del>
                                <ins class="h4 mb-0 mb-lg-n1 ms-1">$415.99</ins>
                            </div>

                           
                        </div>
                        <a href="event-single.html" class="stretched-link mt-3 btn btn-sm btn-wide text-white mw-md-280p">Read More</a>
                    </div>
                </div>
            </div>
          

         
         

        </div>

      

    </div>

    
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>

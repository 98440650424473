import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { CourseService } from 'src/app/courses/services/course.service';

import { IndexService } from 'src/app/shared/services/index.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view',
  templateUrl: './ViewWebinar.component.html',
  styleUrls: ['./ViewWebinar.component.scss'],
 
})
export class ViewWebinarComponent implements OnInit {

  webinar: any;
  courses: any;
  apiURL = environment.url;
  captcha = environment.recaptcha.siteKey;
  hideModal: boolean = false;
  webinarslotForm!: FormGroup;
  submitted = false;
  loading = false;

  constructor(private renderer: Renderer2,private formBuilder: FormBuilder,private router: Router,private indexService: IndexService,
    private courseService: CourseService,private route: ActivatedRoute,private toastr:ToastrService) {
   }

  ngOnInit(): void {
   // this.getDetailsWebinar;
  
    this.indexService.getWebinarDetails().subscribe(response => {
      this.webinar = response['items'];
      // console.log(this.webinar,'ygjtghf');  
    }, err => {

    })
    this.indexService.getCourse().subscribe(response => {      
      this.courses = response['items'];    
      console.log(this.courses,'lkjkhk');    
    }, err => {

    })
    this.webinarslotForm = this.formBuilder.group({
          
      name: ['', Validators.required],
      email: ['', Validators.required], 
      contactNo: ['',[Validators.required,Validators.pattern(/^[0-9]{10}$/)]], 
      slotId: ['', Validators.required], 
      eCaptcha: [''], 
    });

  }



  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }
  get f() { return this.webinarslotForm.controls; }


  onSubmit(){
    this.submitted = true;
   // this.IbcForm.value.isApproved = true;  
    // stop here if form is invalid
    if (this.webinarslotForm.invalid) {
        return;
    }
    this.loading = true;
    this.indexService.WebinarRegister(this.webinarslotForm.value.name,this.webinarslotForm.value.email,
      this.webinarslotForm.value.contactNo,this.webinarslotForm.value.slotId)    
    .pipe(first())
    .subscribe({
        next: () => {
         
            this.toastr.success('Registration Successful for IBC');            
            this.router.navigate(['/login'])
            .then(() => {
              window.location.reload();
            });           
            this.loading = false;         
        },
        error: error => {
            this.toastr.error("Something Went Wrong.");
            this.loading = false;
        }
    });

  }



}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Alert } from '../modals/alert';


@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';
    
    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    // convenience methods
    success(message: string, options?: any) {
        Swal.fire({
            icon:'success',
            title:'Status',
            text:message,
            timer:2000,
            showConfirmButton:false
        })
    }

    error(message: string, options?: any) {
        Swal.fire({
            icon:'error',
            title:'Error',
            text:message,
            showConfirmButton:true
        })
    }

    info(message: string, options?: any) {
        Swal.fire({
            icon:'info',
            title:'Status',
            text:message,
            timer:2000,
            showConfirmButton:false
        })
    }

    warn(message: string, options?: any) {
        Swal.fire({
            icon:'warning',
            title:'Status',
            text:message,
            timer:2000,
            showConfirmButton:false
        })
    }

    notification(title: string, message: string){
        Swal.fire({
            position: 'top-end',
            title: title,
            text: message,
            backdrop: false,
            showConfirmButton: false,
            timer: 10000
          })
    }

    // confirm(callback: ICallback) {
    //     Swal.fire({
    //         title:'Are you sure?',
    //         text:"You won't be able to revet this!",
    //         icon:'warning',
    //         showCancelButton:true,
    //         confirmButtonColor:'#3085d6',
    //         cancelButtonColor:'#d333',
    //         confirmButtonText:'Yes'
    //     }).then((result) => {
    //         callback(result.isConfirmed);
    //     });
    // }

    // main alert method    
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}

interface ICallback {
	(status: boolean) : void;
}
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Blog } from '../modals/bolg';

@Injectable({
  providedIn: 'root'
})
export class BlogService {
  url = environment.url;
  constructor(private http: HttpClient) { }

  getBlogs(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/blog').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting blog'))),
    );
  }
  ViewBlog(Id:any):Observable<Blog>{
    const baseurl = "https://videostream.projectnimbus.co.in/api/app/blog/" + Id;
    return this.http.get<Blog>(baseurl);
  }
  getBlogsComment(): Observable<any> {
    return this.http.get(`${this.url}` + '/api/app/comment-box-blog').pipe(tap(res => res),
      catchError(err => throwError(new Error('Issue in getting blogcomment'))),
    );
  }

  // postBlogComment(){
  //   return this.http.post(`${this.url}` + '/api/app/comment-box-blog').pipe(tap(res => res),
  //     catchError(err => throwError(new Error('Issue in getting blogcomment'))),
  //   );
  // }


  postBlogComment(client_Id:any,blogId:any,stars:any,reviewTitle:any,reviews:any){
    let options = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };

    var data = {
      'clientId': client_Id,
      'blogId': blogId,
      'stars': stars,
      'reviewTitle': reviewTitle,
      'reviews': reviews,
    };
    return this.http.post(`${this.url}`+'/api/app/comment-box-blog',data,options).pipe(tap(res => res),
      catchError(err => throwError(new Error('Something went wrong'))),
    );
  }

}

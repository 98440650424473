import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from 'jwt-decode';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm!: FormGroup;
  isSubmitted: boolean = false;
  guiArray: any;
  loading = false;
  //rememberMe = false;
  returnUrl!: string;
  showPassword = false;

  constructor(private formBuilder: FormBuilder, private alertservice: AlertService,
    private router: Router, private accountservice: AccountService, private route: ActivatedRoute, private toastr: ToastrService,
    private cryptojsService:CryptojsService
  ) {

  }

  ngOnInit(): void {
    
    this.loginForm = this.formBuilder.group({
      userNameOrEmailAddress: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false,

    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.isSubmitted = true;
    // reset alerts on submit
    this.alertservice.clear();

    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched()
      return;
    }

    this.loading = true;

    this.accountservice.loginCall(this.loginForm.value).subscribe((resLoginCall:any)=>{
      console.log("get form value",resLoginCall);

    })

    //

    this.accountservice.login(this.loginForm.value.userNameOrEmailAddress, this.loginForm.value.password)// ,this.loginForm.value.rememberMe
      .subscribe((result:any) => {
        if (result.access_token !== null) {
          this.cryptojsService.encryptLocalUserName(this.loginForm.value.userNameOrEmailAddress)
          this.cryptojsService.encryptLocalAccessToken(result.access_token)
          let token = this.cryptojsService.decryptLocalAccessToken() 
          if (!token) {
            token = '{}';  // Default value if there's no token
          }
          console.log("Decrypted Token: ", token);
          let decoded_token = jwt_decode(token);
         
          this.cryptojsService.encryptLocalUserId(Object(decoded_token)["sub"])
          this.router.navigateByUrl(this.returnUrl)
            .then(() => {
              this.router.navigateByUrl('index')
              this.toastr.success('Login successful');
              this.cryptojsService.checkUserLoginOrNot()
            });
        }
      },(err) => this.toastr.error('Invalid Credentials'));

  }


  displayPassWord(){
    this.showPassword = !this.showPassword; 
  }



}


  <!-- PAGE TITLE
    ================================================== -->
    <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="breadcrumb-main-wrapper">
                                <h1 class="title">Refund Policy & Cancellation Policy</h1>
                                <div class="pagination-wrapper">
                                    <a routerLink="/index">Home</a>
                                    <i class="fa-regular fa-chevron-right"></i>
                                    <a class="active">Refund Policy</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="breadcrumb-img-book">
                                <img src="\assets\images\breadcrumb\book.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <main class="policy-container">
        <section class="policy-header">
            <h2>Refund Policy</h2>
            <!-- <p class="effective-date">Effective Date: <strong>[29/10/2024]</strong></p> -->
            <p class="intro">Thank you for choosing Antarkar sir’s Educative Programmes for your learning needs. We are committed to ensuring a rewarding experience as you discover, assess, and purchase our training courses.
            </p>
            <p>Please note that our refund policy is governed by the following terms, which you agree to when purchasing any course from our platform.
            </p>
        </section>

        <br>
        <div class="policy-row">
            <p><b>Refund and Cancellation Policy</b></p>
        </div>
      
        <!-- Policy Sections in Rows -->
        <div class="policy-row">
            <h3>1. No Changes or Cancellations After Purchase</h3>
            <p>
                Once a course has been sold, the product cannot be changed or canceled. We do not offer refunds for any course enrollment, except under specific circumstances as outlined below.
            </p>
        </div>

        <div class="policy-row">
            <h3>2. Event Cancellations by Antarkar Sir’s Educative Programmes</h3>
            <p>Antarkar Sir’s Educative Programmes reserves the right to postpone or cancel an event, due to insufficient enrollment, instructor illness, or events beyond our control (such as natural disasters, political instability, etc.).
            </p>
        </div>

        <div class="policy-row">
            <h3>3. Special Offers and Promotions</h3>
            <p>No cancellations or refunds will be provided for courses that have been purchased as part of special promotional offers, including but not limited to seasonal offers and specific exam-related promotions. These are one-time offers, and cancellations are not possible.
            </p>
        </div>

        <div class="policy-row">
            <h3>4. Refund Policy</h3>
            <p>Refunds are not applicable unless you have made multiple payments for the same course. In such cases, a refund will be issued for the duplicate payment only.
            </p>
          
        </div>

        <div class="policy-row">
            <h3>5. Course and Series Details</h3>
            <p>Before making a purchase, please ensure you carefully review the course details, terms, and conditions provided on our website. We are not liable for dissatisfaction with the course content after purchase, and no refunds will be issued on such grounds.
            </p>
          
        </div>

        <div class="policy-row">
            <h3>6. Customer Care
            </h3>
            <p>We strive to provide our students with the best possible learning experience. If you encounter any issues, please contact our customer care team at info@antarkarsir.com, and we will make all reasonable efforts to resolve your concerns.
            </p>
        </div>

        <footer class="policy-footer">
            <p>For inquiries, please contact us at <a href="mailto:antarkarsir1@gmail.com">antarkarsir1@gmail.com</a>.</p>
        </footer>
    </main>
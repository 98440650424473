import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import { AccountService } from 'src/app/shared/services/account.service';
import { saveAs } from 'file-saver';
import * as FileSaver from 'file-saver';




@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  IbcForm!: FormGroup;
  submitted = false;
  loading = false;
  imageSrc?: string;
  saveAs: any;
 
  constructor(private formBuilder: FormBuilder,private router: Router,private accountservice : AccountService,private route: ActivatedRoute,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.IbcForm = this.formBuilder.group({
          
      email: ['', Validators.required,Validators.email],
      name: ['', Validators.required], 
      contactNo: ['',[Validators.required,Validators.pattern(/^[0-9]{10}$/)]],
      whatsAppNo: ['',[Validators.required,Validators.pattern(/^[0-9]{10}$/)]], 
      district: ['', Validators.required],
      bankName: ['', Validators.required], 
      accountHolderName: ['', Validators.required],
      accountNo: ['', Validators.required], 
      ifscCode: ['',[Validators.required,Validators.pattern(/^[A-Za-z]{4}[0-9]{6,7}$/)]],
      // photo: ['', Validators.required],
      address: ['', Validators.required], 
      isApproved:[''] 
    });
  }
  get f() { return this.IbcForm.controls; }


  onSubmit(){
    this.submitted = true;
    this.IbcForm.value.isApproved = true;  
    // stop here if form is invalid
    if (this.IbcForm.invalid) {
        return;
    }
    this.loading = true;    
    // let formData: any = new FormData();        
    // Object.keys(this.IbcForm.controls).forEach(formControlName => 
    //   {          
    //   formData.append(formControlName,  
    //     this.IbcForm.get(formControlName)?.value);    
    // });
    // var file = this.IbcForm.value.photo;
    // formData.append('image', file);
   
    this.accountservice.Ibcregister(this.IbcForm.value)    
    .pipe(first())
    .subscribe({
        next: () => {
            this.toastr.success('Registration Successful for IBC');
            this.router.navigate(['/login'], { relativeTo: this.route });           
        },
        error: error => {
            this.toastr.error("Something Went Wrong.");
            this.loading = false;
        }
    });

  }

  onFileChanged(e:any) {
    if(e.target.files){
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload=(e:any)=>{
        console.log(e.target.result);
        
        //this.IbcForm.value.photo = e.target.result;
      }
    }
    
  }

}

<div class="course-details-breadcrumb-2 bg_image rts-section-gap">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="single-course-left-align-wrapper">
          <div class="meta-area  c-pointer">
            <a routerLink="/index">Home</a>
            <i class="fa-solid fa-chevron-right"></i>
            <a class="active">Course Details</a>
          </div>
          <h1 class="title">
            {{courseFullDetails?.name ? courseFullDetails?.name : 'NA'}}
          </h1>
          <div class="rating-area">
          <ul class="stars list-unstyled">
            <div class="stars-area">
              <span class="mt-3" *ngIf="detailsOfCourseInfo.star">{{detailsOfCourseInfo.star? detailsOfCourseInfo.star :'NA'}}</span>
              <ng-container [ngSwitch]="detailsOfCourseInfo?.star">
                <li *ngSwitchCase="1">
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                </li>
                <li *ngSwitchCase="2">
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                </li>  
                <li *ngSwitchCase="3">
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                </li>
                <li *ngSwitchCase="4">
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                </li>
                <li *ngSwitchCase="5">
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                  <i class="fa-sharp fa-solid fa-star"></i>
                </li>
                <!-- <li *ngSwitchDefault>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                  <i class="fa-regular fa-star"></i>
                </li> -->
              </ng-container>

            </div>
          </ul>
            <div class="students">
              <i class="fa-thin fa-users"></i>
              <span>{{courseFullDetails?.studentCount ?
                courseFullDetails?.studentCount : '54'}}
                Students</span>
            </div>
            <div class="calender-area-stars">
              <i class="fa-light fa-calendar-lines-pen"></i>
              <span>Last updated {{courseFullDetails?.lastUpdatedDateOfCourse ?
                courseFullDetails?.lastUpdatedDateOfCourse : 'NA'}}</span>
            </div>
          </div>
          <div id="MainVideoSection" class="author-area gap-3">
            <div class="author zoom-container">
              <img class="zoom-image" [src]="apiURL+'/'+courseFullDetails?.authorImage" alt="authimg" style="height: 70px;border-radius: 50%;width: 70px;">
              <!-- <img src="assets/images/rohits.png" alt=""> -->
             
            </div>
            <span>By Prof</span><b style="color: black;">{{courseFullDetails?.authorName ? courseFullDetails?.authorName
                :'NA'}}</b>
            <p> <span>Categories: </span> {{courseFullDetails?.courseCategory ? courseFullDetails?.courseCategory
              :'NA'}}
            </p>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="breadcrumb-img-book-2 h-100  d-flex justify-content-end align-items-center">
          <img src="/assets/images/breadcrumb/book.png" alt="book" class="d-none d-md-block">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="course-details-wrapper-2 rts-section-gap">
  <div class="container">
    <div class="row g-5">
      <div class="col-lg-8 order-cl-1 order-lg-1 order-md-2 order-sm-2 order-2">

        <div class="thumbnail mb--30" style="position: relative;" *ngIf="courseFullDetails">
          <img *ngIf="!isVideoPlaying" [src]="apiURL+'/'+detailsOfCourse?.bannerImg" alt="Course Image"
            style="width: 100%;" (error)="setDefaultImage($event)">
          <div *ngIf="isVideoPlaying" class="video-container">
            <!-- Embedding YouTube iframe -->
            <iframe id="ytPlayer" [src]="sanitizedVideoUrl" frameborder="0" allow="autoplay; encrypted-media"
              allowfullscreen style="width: 100%; height: 100%;"></iframe>
          </div>
          <div class="vedio-icone c-pointer" *ngIf="!isVideoPlaying">
            <a id="mainVideoSectionClick" class="video-play-button play-video popup-video"
              (click)="openModal(lessionFirstVideoLink,lessionFirstVideoLinkPaid)">
              <span></span>
            </a>
            <div class="video-overlay">
              <a id="mainVideoLinkId" class="video-overlay-close" (click)="closeVideo()">×</a>
            </div>
          </div>
        </div>
        <div class="thumbnail mb--30" style="position: relative;" *ngIf="courseFullDetails.length == 0">
          <img src="../../../assets/images/course/Course-not-Found.jpg" alt="course not found">
        </div>
        <div class="course-content-wrapper">
          <h5 class="title">About Course</h5>
          <p class="disc">
            The foundation course is essentially a grade IX and X mathematics course designed to acclimatise students to
            mathematical concepts and their applications in a creative manner. It might be of interest to anyone
            interested in elementary mathematics irrespective of his/her walk of life.
          </p>
          <h5 class="title">Description</h5>
          <p class="disc">
            {{plainTextDescription ? plainTextDescription : 'NA'}}
          </p>

          <div class="module-wrapper" *ngIf="additionalData?.whatWillYouLearn.length > 0">
            <h6 class="title">What Will You Learn?</h6>
            <div class="inner-content">
              <div class="single-wrapper" *ngFor="let lessionItem of additionalData?.whatWillYouLearn">
                <div class="single-codule d-flex align-items-start mb-3">
                  <i class="fa-regular fa-check me-2" style="font-size: 24px;"></i>
                  <p class="mb-0">{{lessionItem.title}}</p>
                </div>
               
              </div>
            </div>
          </div>

        </div>
        <div class="course-content-wrapper-main mt--40" *ngIf="courseFullDetails?.lessons?.length > 0">
          <h5 class="title">Course Content</h5>
          <div class="accordion mt--30" id="accordionExample">
            <div class="accordion-item" *ngFor="let lessionItem of courseFullDetails?.lessons; let i = index">
              <h2 class="accordion-header" [id]="'heading' + i">
                <button class="accordion-button" type="button" [attr.data-bs-toggle]="'collapse'"
                  [attr.data-bs-target]="'#collapse' + i" [attr.aria-expanded]="i === 0 ? 'true' : 'false'"
                  [attr.aria-controls]="'collapse' + i">
                  <span>{{ lessionItem?.lessonName ? lessionItem?.lessonName : 'NA' }}</span>
                  <span>
                    {{ lessionItem?.lectureCount ? lessionItem?.lectureCount : 'NA' }} Lectures .
                    {{ lessionItem?.lessonDuration ? lessionItem?.lessonDuration : 'NA' }} sec
                  </span>
                </button>
              </h2>
              <div [id]="'collapse' + i" class="accordion-collapse collapse" [class.show]="i === 0"
                [attr.aria-labelledby]="'heading' + i" data-bs-parent="#accordionExample">
                <div class="accordion-body" *ngFor="let lessionItemVideo of lessionItem?.videoRow">
                  <a class="play-vedio-wrapper">
                    <div class="left">
                      <i class="fa-light fa-circle-play"></i>
                      <span class="c-pointer" (click)="goToMainScreen(lessionItemVideo)"><a>{{
                          lessionItemVideo?.videoName ? lessionItemVideo?.videoName : 'NA' }}</a></span>
                    </div>
                    <div class="right">
                      <span class="play c-pointer" (click)="preopenModal(lessionItemVideo?.videoURL)"
                        (click)="checkPaid(lessionItemVideo)">
                        Preview
                      </span>
                      <span class="play c-pointer" (click)="addFAQ(lessionItemVideo?.faqs)">
                        FAQ
                      <button type="button" id="faqButton" data-bs-toggle="modal" data-bs-target="#faqModal" style="display: none;"></button>
                      </span>
                      <span class="play c-pointer" (click)="addPDFDescription(lessionItemVideo?.lessionNotesDescription)">
                        Notes
                        <button type="button" id="pdfNotesButton" data-bs-toggle="modal" data-bs-target="#pdfNotesModal" style="display: none;"></button>
                      </span>
                      <span class="play c-pointer" (click)="downloadPDF(lessionItemVideo?.lessionWisePdfOrDocx)">
                        PDF
                      </span>
                      <span>{{ lessionItemVideo?.video_Duration ? lessionItemVideo?.video_Duration : 'NA' }} sec</span>
                    </div>
                    
                  </a>
                </div>
              </div>
            </div>
          </div>


        </div>
        <div class="course-content-wrapper-main mt-4" *ngIf="courseComments?.length > 0">
          <h5 class="title mb-4">Comments Content</h5>
          <div class="row">
            <div class="col-12 mb-3" *ngFor="let comment of courseComments">
              <div class="card shadow-sm">
                <div class="card-body d-flex align-items-start">
                  <!-- Profile Picture -->
                  <div
                    class="rounded-circle text-white d-flex justify-content-center align-items-center me-3"
                    style="width: 50px; height: 50px; font-size: 1.5rem; text-transform: uppercase; background: #553cdf;"
                  >
                    {{ comment?.userName ? comment.userName[0] : '%' }}
                  </div>
                  <!-- Comment Content -->
                  <div>
                    <h6 class="card-title mb-1">
                      {{ comment?.userName ? comment.userName : 'NA' }}
                    </h6>
                    <p class="card-text mb-0">{{ comment?.review ? comment?.review : 'NA' }}</p>
                  </div>
                  <!-- Heart Icon -->
                  <i class="bi bi-heart ms-auto fs-5 text-danger"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        

      </div>

      <div class="col-lg-4 order-cl-2 order-lg-2 order-md-1 order-sm-1 order-1 rts-sticky-column-item">

        <div class="right-course-details mt--0">

          <div class="course-side-bar">
            <div class="price-area" *ngIf="!lessionFirstVideoLinkPaid">
              <h3 class="title">&#8377;{{courseFullDetails?.offer_Price ? courseFullDetails?.offer_Price :'NA'}}</h3>
              <h4 class="none text-decoration-line-through">&#8377;{{courseFullDetails?.actual_Price ?
                courseFullDetails?.actual_Price : 'NA'}}</h4>
              <span class="discount">{{courseFullDetails?.offer_Percentage ? courseFullDetails?.offer_Percentage :
                'NA'}} %
                off</span>
            </div>
            <div *ngIf="!lessionFirstVideoLinkPaid">
              <a class="rts-btn btn-primary" (click)="addToCart(detailsOfCourse)" *ngIf="!alerdyAddedToCart">Add To
                Cart</a>
              <a class="rts-btn btn-primary" *ngIf="alerdyAddedToCart">Added To Cart</a>
              <a class="rts-btn btn-border c-pointer" (click)="goViewCart()">Buy Now</a>
            </div>

            <div class="what-includes">
              <h5 class="title">This course includes: </h5>
              <div class="single-include">
                <div class="left">
                  <i class="fa-light fa-chart-bar"></i>
                  <span>Levels</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.skill ? courseFullDetails?.skill : 'NA'}}</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-light fa-timer"></i>
                  <span>Duration</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.totalCourseDuration ? courseFullDetails?.totalCourseDuration : 'NA'}}
                    Second</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-regular fa-floppy-disk"></i>
                  <span>Subject</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.courseCategory ? courseFullDetails?.courseCategory : 'NA'}}</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-regular fa-pen-to-square"></i>
                  <span>Update</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.lastUpdatedDateOfCourse ? courseFullDetails?.lastUpdatedDateOfCourse :
                    'NA'}}</span>
                </div>
              </div>
              <div class="single-include">
                <div class="left">
                  <i class="fa-regular fa-pen-to-square"></i>
                  <span>Timeline</span>
                </div>
                <div class="right">
                  <span>{{courseFullDetails?.courseDurations ? courseFullDetails?.courseDurations :
                    'NA'}}</span>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="right-course-details mt--30">

          <div class="course-side-bar">

            <div class="course-single-information">
              <h5 class="title">A course by</h5>
              <div class="body d-flex align-items-center gap-5">
                <div class="author author-area zoom-container">
                  <img class="zoom-image" [src]="apiURL+'/'+courseFullDetails?.authorImage" alt="authimg" style="height: 70px;border-radius: 50%;width: 70px;">
                  <!-- <img src="assets/images/rohits.png" alt=""> -->
                </div>
                <p>{{courseFullDetails?.authorName ? courseFullDetails?.authorName : 'NA'}}</p>
              </div>
            </div>

            <div class="course-single-information" *ngIf="additionalData?.materialIncludes.length > 0">
              <h5 class="title">Material Includes</h5>
              <div class="body" *ngFor="let material of additionalData?.materialIncludes">

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  {{material.title}}
                </div>
              </div>
            </div>

            <div class="course-single-information" *ngIf="additionalData?.requirements.length > 0">
              <h5 class="title">Requirements</h5>
              <div class="body" *ngFor="let requirement of additionalData?.requirements">

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                 {{requirement.title}}
                </div>
              </div>
            </div>

            <div class="course-single-information" *ngIf="additionalData?.tags.length > 0">
              <h5 class="title">Tags</h5>
              <div class="body">
                <div class="tags-wrapper" *ngFor="let tag of additionalData?.tags">
                  <span>{{tag.title}}</span>
                </div>
              </div>
            </div>

            <!-- <div class="course-single-information">
              <h5 class="title">Share</h5>
              <div class="body">

                <div class="social-share-course-side-bar">
                  <ul>
                    <li><a href="#"><i class="fa-brands fa-whatsapp"></i></a></li>

                  </ul>
                </div>

              </div>
            </div> -->

            <div class="course-single-information last">
              <h5 class="title">Audience</h5>
              <div class="body">

                <div class="single-check">
                  <i class="fa-light fa-circle-check"></i>
                  Suitable for {{courseFullDetails?.skill ? courseFullDetails?.skill : 'NA'}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="cart-bar">
  <div class="cart-header">
    <h3 class="cart-heading">MY CART (3 ITEMS)</h3>
    <div class="close-cart"><i class="fal fa-times"></i></div>
  </div>
  <div class="product-area">
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/01.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Construct Map</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">3 ×</span>
            <span class="product-price">&#8377;198.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="3">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/02.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name"> Bridge product</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">2 ×</span>
            <span class="product-price">&#8377;88.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
    <div class="product-item last-child">
      <div class="product-detail">
        <div class="product-thumb"><img src="assets/images/course/cart/03.jpg" alt="product-thumb"></div>
        <div class="item-wrapper">
          <span class="product-name">Labour helmet</span>
          <div class="item-wrapper">
            <span class="product-variation"><span class="color">Green /</span>
              <span class="size">XL</span></span>
          </div>
          <div class="item-wrapper">
            <span class="product-qnty">1 ×</span>
            <span class="product-price">&#8377;289.00</span>
          </div>
        </div>
      </div>
      <div class="cart-edit">
        <div class="quantity-edit">
          <button class="button"><i class="fal fa-minus minus"></i></button>
          <input type="text" class="input" value="2">
          <button class="button plus">+<i class="fal fa-plus plus"></i></button>
        </div>
        <div class="item-wrapper d-flex mr--5 align-items-center">
          <a href="#" class="product-edit"><i class="fal fa-edit"></i></a>
          <a href="#" class="delete-cart"><i class="fal fa-times"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="cart-bottom-area">
    <span class="spend-shipping"><i class="fal fa-truck"></i> SPENT <span class="amount">&#8377;199.00</span> MORE
      FOR FREE SHIPPING</span>
    <span class="total-price">TOTAL: <span class="price">&#8377;556</span></span>
    <a href="checkout.html" class="checkout-btn cart-btn">PROCEED TO CHECKOUT</a>
    <a href="cart.html" class="view-btn cart-btn">VIEW CART</a>
  </div>
</div>

<div id="side-bar" class="side-bar header-two">
  <button class="close-icon-menu"><i class="far fa-times"></i></button>

  <div class="inner-main-wrapper-desk">
    <div class="thumbnail">
      <img src="assets/images/banner/04.jpg" alt="elevate">
    </div>
    <div class="inner-content">
      <h4 class="title">We Build Building and Great Constructive Homes.</h4>
      <p class="disc">
        We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly master new
        technologies.
      </p>
      <div class="footer">
        <h4 class="title">Got a project in mind?</h4>
        <a href="contact.html" class="rts-btn btn-primary">Let's talk</a>
      </div>
    </div>
  </div>

  <div class="mobile-menu-main">
    <nav class="nav-main mainmenu-nav mt--30">
      <ul class="mainmenu metismenu" id="mobile-menu-active">
        <li>
          <a href="index.html" class="main">Home</a>
        </li>
        <li>
          <a href="about.html" class="main">About Us</a>
        </li>
        <li>
          <a href="course-one.html" class="main">Course</a>

        </li>
        <li>
          <a href="contact.html" class="main">Contact Us</a>

        </li>
        <li>
          <a href="FAQ.html" class="main">FAQ</a>

        </li>
      </ul>
    </nav>

    <div class="buttons-area">
      <a href="log-in.html" class="rts-btn btn-border">Log In</a>
      <a href="registration.html" class="rts-btn btn-primary">Sign Up</a>
    </div>

    <div class="rts-social-style-one pl--20 mt--50">
      <ul>
        <li>
          <a href="#">
            <i class="fa-brands fa-facebook-f"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-twitter"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-youtube"></i>
          </a>
        </li>
        <li>
          <a href="#">
            <i class="fa-brands fa-linkedin-in"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="progress-wrap">
  <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
      style="transition: stroke-dashoffset 10ms linear 0s; stroke-dasharray: 307.919, 307.919; stroke-dashoffset: 307.919;">
    </path>
  </svg>
</div>
<div class="search-input-area">
  <div class="container">
    <div class="search-input-inner">
      <div class="input-div">
        <input class="search-input autocomplete" type="text" placeholder="Search by keyword or #">
        <button><i class="far fa-search"></i></button>
      </div>
    </div>
  </div>
  <div id="close" class="search-close-icon"><i class="far fa-times"></i></div>
</div>
<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>
<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>
<div class="modal fade" id="videoModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="videoModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="videoModalLabel">Antarkar Sir's Video Player</h5>
        <button type="button" class="btn-close" id="closeLessionModel" data-bs-dismiss="modal" aria-label="Close"
          (click)="onModalClose()"></button>
      </div>
      <div class="modal-body">
        <div class="ratio ratio-16x9">
          <iframe id="ytPlayerPreview" [src]="presanitizedVideoUrl" frameborder="0" allow="autoplay; encrypted-media"
            allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </div>
</div>


<!---->
<div class="modal fade" id="faqModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">FAQ Questions</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item" *ngFor="let faq of faqArrays; let i = index">
            <h2 class="accordion-header" [id]="'flush-heading' + i">
              <button 
                class="accordion-button collapsed" 
                type="button" 
                data-bs-toggle="collapse" 
                [attr.data-bs-target]="'#flush-collapse' + i" 
                aria-expanded="false" 
                [attr.aria-controls]="'flush-collapse' + i">
                {{ faq?.questions }}
              </button>
            </h2>
            <div 
              [id]="'flush-collapse' + i" 
              class="accordion-collapse collapse" 
              [attr.aria-labelledby]="'flush-heading' + i" 
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                {{ faq?.answers }}
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
  </div>
</div>



<div class="modal fade" id="pdfNotesModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Notes</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>{{pdfNotesDescription}}</p>
      </div>
    </div>
  </div>
</div>
// import { Injectable } from '@angular/core';
// import { ActivatedRoute } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
// import { BehaviorSubject, Subject } from 'rxjs';
// import { CourseService } from 'src/app/courses/services/course.service';
// import * as CryptoJS from 'crypto-js';

// @Injectable({
//   providedIn: 'root'
// })
// export class CartService {

//   secretKey = "Pass!0000"
//   public cartItemList: any = []
//   public productList = new BehaviorSubject<any>([])

//   private cartArray: BehaviorSubject<any> = new BehaviorSubject<any>([]);

//   public coursePresent = new BehaviorSubject<boolean>(false);
//   coursePresentSignal$ = this.coursePresent.asObservable();

//   constructor(private ActivateRoute: ActivatedRoute, private courseService: CourseService, private toastr: ToastrService) {
//     this.getCartCourseCount()
//   }

//   getCartCourseCount(){
//     const cart = localStorage.getItem('cart');
//     if (cart) {
//       const parsedCart = JSON.parse(cart);
//       this.cartArray.next(parsedCart.items);
//     }else{
//       this.cartArray.next([]);
//     }
//   }

 

//   getProduct() {
//     return this.productList.asObservable();
//   }

//   setProduct(client_id: any, courseId: any) {
//     this.cartItemList.push(...client_id);
//     this.productList.next(client_id)
//   }

//   addtoCart(client_id: any, courseId: any) {
//     this.cartItemList.push(client_id, courseId);
//     this.productList.next(this.cartItemList);
//     // this.getTotalPrice();
//     // console.log(this.cartItemList)
//     return this.productList.asObservable();
//   }

//   // getTotalPrice(){
//   //   let grandTotal=0;
//   //   this.cartItemList.map((a:any)=>{
//   //     grandTotal += a.total;
//   //   })
//   // }

//   getCartCourse() {
//     return this.cartArray.asObservable(); // Expose as observable
//   }

//   saveCart(cart: any): void {
//     localStorage.setItem('cart', JSON.stringify(cart));
//     let cartCourse: any = JSON.parse(localStorage.getItem('cart')!);
//     this.cartArray.next(cartCourse.items); // Update the BehaviorSubject
//   }

//   cartService(course: any) { 
    

//     if (localStorage.getItem('cart')) {
//       let cart: any = JSON.parse(localStorage.getItem('cart')!);

//       if (cart.items.some((ele: any) => ele.id === course.id)) {
//         cart.items = cart.items.map((item: any) => {
//           if (item.id === course.id) {
//             return {
//               ...item,
//               quantity: item.quantity + 1
//             };
//           }
//           return item;
//         });
//       } else {

//         let newCourse = {
//           id: course.id,
//           quantity: 1,
//           offer_Price: course.offer_Price,
//           actual_Price: course.actual_Price,
//           offer_Percentage:course.offer_Percentage,
//           name: course.name,
//           image: course.bannerImg
//         };
//         cart.items.push(newCourse);
//       }

//       this.saveCart(cart);
//     } else {

//       let firstObject = {
//         id: course.id,
//         quantity: 1,
//         offer_Price: course.offer_Price,
//         actual_Price: course.actual_Price,
//         offer_Percentage:course.offer_Percentage,
//         name: course.name,
//         image: course.bannerImg
//       };
//       let cart = {
//         items: [firstObject]
//       };
//       this.saveCart(cart);
//     }

//     this.checkCourseInsideCart(course)


//   }


//   increaseQuantity(course: any) {
//     if (localStorage.getItem('cart')) {
//       let cart: any = JSON.parse(localStorage.getItem('cart')!);
//       cart.items = cart.items.map((item: any) => {
//         if (item.id === course.id) {
//           return {
//             ...item,
//             quantity: item.quantity + 1
//           };
//         }
//         return item;
//       });
//       this.saveCart(cart);
//     }
//   }

//   descreaseQuantity(course: any) {
//     if (localStorage.getItem('cart')) {
//       let cart: any = JSON.parse(localStorage.getItem('cart')!);
//       // First, filter out any item with quantity === 1 and matching the course.id
//       cart.items = cart.items.filter((item: any) => {
//         // If item.id matches and quantity is 1, filter it out
//         if (item.id === course.id && item.quantity === 1) {
//           console.log(`Removing course with id: ${course.id}`);
//           return false; // Remove item
//         }
//         return true; // Keep the rest
//       });

//       // Now, update the quantity for other items (if item.id matches)
//       cart.items = cart.items.map((item: any) => {
//         if (item.id === course.id) {
//           return {
//             ...item,
//             quantity: item.quantity - 1
//           };
//         }
//         return item;
//       });
//       this.saveCart(cart);
//     }
//   }

//   removeCourse(course: any) {
//     if (localStorage.getItem('cart')) {
//       let cart: any = JSON.parse(localStorage.getItem('cart')!);
//       cart.items = cart.items.filter((item: any) => item.id !== course.id);
//       this.saveCart(cart);

//       // After removing the course, check if it still exists in the cart
//       this.checkCourseInsideCart(course);
//     }
//   }


//   checkCourseInsideCart(course: any) {
//     let cart: any = JSON.parse(localStorage.getItem('cart')!);
//     if(cart){
//       if (cart.items.some((ele: any) => ele.id === course.id)) {
//         this.coursePresent.next(true);
//       } else {
//         this.coursePresent.next(false);
//       }
//     }
   
//   }

//   getCart() {
//     return JSON.parse(localStorage.getItem('cart')!);
//   }

//   cartCoursePresent(){
//     let cart: any = JSON.parse(localStorage.getItem('cart')!);
//     if(cart){
//       if(cart.items.length > 0){
//         return true
//       }else{
//         return false
//       }
//     }else{
//       return false
//     }
//   }

// }

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { CourseService } from 'src/app/courses/services/course.service';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  secretKey = "Pass!0000"; // Your encryption key
  public cartItemList: any = [];
  public productList = new BehaviorSubject<any>([]);

  private cartArray: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public coursePresent = new BehaviorSubject<boolean>(false);
  coursePresentSignal$ = this.coursePresent.asObservable();

  constructor(private ActivateRoute: ActivatedRoute, private courseService: CourseService, private toastr: ToastrService) {
    this.getCartCourseCount();
  }

  // Encrypt data
  encryptData(data: any): string {
    return CryptoJS.AES.encrypt(JSON.stringify(data), this.secretKey).toString();
  }

  // Decrypt data
  decryptData(data: string): any {
    const bytes = CryptoJS.AES.decrypt(data, this.secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  getCartCourseCount() {
    const cart = localStorage.getItem('cart');
    if (cart) {
      const decryptedCart = this.decryptData(cart);
      this.cartArray.next(decryptedCart.items);
    } else {
      this.cartArray.next([]);
    }
  }

  getProduct() {
    return this.productList.asObservable();
  }

  setProduct(client_id: any, courseId: any) {
    this.cartItemList.push(...client_id);
    this.productList.next(client_id);
  }

  addtoCart(client_id: any, courseId: any) {
    this.cartItemList.push(client_id, courseId);
    this.productList.next(this.cartItemList);
    return this.productList.asObservable();
  }

  getCartCourse() {
    return this.cartArray.asObservable(); // Expose as observable
  }

  saveCart(cart: any): void {
    const encryptedCart = this.encryptData(cart);
    localStorage.setItem('cart', encryptedCart);
    let cartCourse: any = this.decryptData(localStorage.getItem('cart')!);
    this.cartArray.next(cartCourse.items); // Update the BehaviorSubject
  }

  cartService(course: any) { 
    if (localStorage.getItem('cart')) {
      let cart: any = this.decryptData(localStorage.getItem('cart')!);

      if (cart.items.some((ele: any) => ele.id === course.id)) {
        cart.items = cart.items.map((item: any) => {
          if (item.id === course.id) {
            return {
              ...item,
              quantity: item.quantity + 1
            };
          }
          return item;
        });
      } else {
        let newCourse = {
          id: course.id,
          quantity: 1,
          offer_Price: course.offer_Price,
          actual_Price: course.actual_Price,
          offer_Percentage: course.offer_Percentage,
          name: course.name,
          image: course.bannerImg
        };
        cart.items.push(newCourse);
      }

      this.saveCart(cart);
    } else {
      let firstObject = {
        id: course.id,
        quantity: 1,
        offer_Price: course.offer_Price,
        actual_Price: course.actual_Price,
        offer_Percentage: course.offer_Percentage,
        name: course.name,
        image: course.bannerImg
      };
      let cart = {
        items: [firstObject]
      };
      this.saveCart(cart);
    }

    this.checkCourseInsideCart(course);
  }

  increaseQuantity(course: any) {
    if (localStorage.getItem('cart')) {
      let cart: any = this.decryptData(localStorage.getItem('cart')!);
      cart.items = cart.items.map((item: any) => {
        if (item.id === course.id) {
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        return item;
      });
      this.saveCart(cart);
    }
  }

  descreaseQuantity(course: any) {
    if (localStorage.getItem('cart')) {
      let cart: any = this.decryptData(localStorage.getItem('cart')!);
      cart.items = cart.items.filter((item: any) => {
        if (item.id === course.id && item.quantity === 1) {
          console.log(`Removing course with id: ${course.id}`);
          return false;
        }
        return true;
      });

      cart.items = cart.items.map((item: any) => {
        if (item.id === course.id) {
          return {
            ...item,
            quantity: item.quantity - 1
          };
        }
        return item;
      });
      this.saveCart(cart);
    }
  }

  removeCourse(course: any) {
    if (localStorage.getItem('cart')) {
      let cart: any = this.decryptData(localStorage.getItem('cart')!);
      cart.items = cart.items.filter((item: any) => item.id !== course.id);
      this.saveCart(cart);
      this.checkCourseInsideCart(course);
    }
  }

  checkCourseInsideCart(course: any) { 
    if(localStorage.getItem('cart')){
      let cart: any = this.decryptData(localStorage.getItem('cart')!);
      if (cart) {
        if (cart.items.some((ele: any) => ele.id === course.id)) {
          this.coursePresent.next(true);
        } else {
          this.coursePresent.next(false);
        }
      }
    }
  }

  getCart() {
    if(localStorage.getItem('cart')){
      return this.decryptData(localStorage.getItem('cart')!);
    }
  }

  cartCoursePresent() {
    let cart: any = this.decryptData(localStorage.getItem('cart')!);
    if (cart) {
      if (cart.items.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}


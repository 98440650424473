import { AfterViewInit, Component, OnInit } from '@angular/core';
import { IndexService } from '../../services/index.service';
import { trigger, transition, style, animate } from '@angular/animations';
interface Testimonial {
  feedback: string;
  name: string;
  work: string;
  stars: number;
}

@Component({
  selector: 'app-students-feedback',
  templateUrl: './students-feedback.component.html',
  styleUrls: ['./students-feedback.component.scss'],
  animations: [
    trigger('slideAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateX(-100%)' }))
      ])
    ])
  ]
})
export class StudentsFeedbackComponent implements OnInit  {


  currentSlide = 0;
  showMoreStates: { [key: string]: boolean } = {};
  private slideInterval: any;
  
  testimonials: Testimonial[] = [];

  chunkedTestimonials: Testimonial[][] = [];

  constructor(private indexService:IndexService) {
  }
  screenWidth: number = window.innerWidth;
  ngOnInit(): void {
    this.getTestimonial()
    this.startAutoSlide();
    this.updateScreenWidth();
  window.addEventListener('resize', () => this.updateScreenWidth());
  }
  updateScreenWidth() {
    this.screenWidth = window.innerWidth;
    this.chunkTestimonials();
  }
  getTestimonial() {
    this.indexService.getApprovedTestimonials().subscribe((resTestimonial: any) => {
      if (resTestimonial?.items.length > 0) {
        this.testimonials = resTestimonial.items.map((item: any) => ({
          stars: Math.floor(item.stars),
          feedback: item.reviews,
          name: item.clientName,
          work: 'NA'
        }));
        this.chunkTestimonials();
      } else {
        this.testimonials = [];
        this.chunkTestimonials();
      }
    });
  }
  

  startAutoSlide(): void {
    this.slideInterval = setInterval(() => {
      this.nextSlide();
    }, 3000); 
  }

  chunkTestimonials(): void {
    const chunkSize = this.screenWidth > 768 ? 3 : 1;
    this.chunkedTestimonials = [];
    
    for (let i = 0; i < this.testimonials.length; i += chunkSize) {
      this.chunkedTestimonials.push(this.testimonials.slice(i, i + chunkSize));
    }
  }

  nextSlide(): void {
    if (this.currentSlide < this.chunkedTestimonials.length - 1) {
      this.currentSlide++;
    }else{
      this.currentSlide = 0;  
    }
  }

  previousSlide(): void {
    if (this.currentSlide > 0) {
      this.currentSlide--;
    }
  }

  review(reviewString: string, maxLength: number, index: string): string {
    if (this.showMoreStates[index]) {
      return reviewString;
    }
    if (reviewString.length > maxLength) {
      return reviewString.substring(0, maxLength) + '...';
    }
    return reviewString;
  }

  toggleShowMore(index: string): void {
    this.showMoreStates[index] = !this.showMoreStates[index];
  }

  getDotArray(): number[] {
    return Array(this.chunkedTestimonials.length).fill(0).map((_, i) => i);
  }

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }

  pauseAutoSlide(): void {
    if (this.slideInterval) {
      clearInterval(this.slideInterval);
    }
  }
  
  resumeAutoSlide(): void {
    this.startAutoSlide();
  }
}

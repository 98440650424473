import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { CourseSingleComponent } from './courses/course-single/course-single.component';
import { CourseComponent } from './courses/course/course.component';
import { AboutUsComponent } from './shared/components/about-us/about-us.component';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { IndexComponent } from './shared/components/index/index.component';
import { LoginComponent } from './shared/components/login/login.component';
import { RegisterComponent } from './shared/components/register/register.component';
import { WhyUsComponent } from './shared/components/why-us/why-us.component';
import { MyProfileComponent } from './shared/components/my-profile/my-profile.component';
import { ViewCartComponent } from './cart/view-cart/view-cart.component';
import { CheckoutComponent } from './cart/checkout/checkout.component';
import { AuthguardGuard } from './shared/authguard.guard';
import { AddComponent } from './ibc/add/add.component';
import { ViewComponent } from './ibc/view/view.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { CheckCartGuard } from './shared/check-cart.guard';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './shared/components/terms-and-conditions/terms-and-conditions.component';
import { OrderSuccessComponent } from './shared/components/order-success/order-success.component';
import { RefundPolicyComponent } from './shared/components/refund-policy/refund-policy.component';
import { DisclaimerComponent } from './shared/components/disclaimer/disclaimer.component';
const routes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', component: IndexComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'why-us', component: WhyUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'refund-policy', component: RefundPolicyComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent },
  { path: 'order-success', component: OrderSuccessComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'view-cart', component: ViewCartComponent },
  { path: 'checkout', component: CheckoutComponent, canActivate: [CheckCartGuard] },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthguardGuard] },
  {
    path: '', component: CourseComponent,
    loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'courses/course-details/:id', component: CourseSingleComponent,
  },
  {
    path: 'ibc/register', component: AddComponent,
    loadChildren: () => import('./ibc/ibc.module').then(m => m.IbcModule)
  },
  {
    path: 'ibc/:Username', component: ViewComponent,
    loadChildren: () => import('./ibc/ibc.module').then(m => m.IbcModule)
  },
  { path: '**', redirectTo: 'index', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

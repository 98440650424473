  <!-- PAGE TITLE
    ================================================== -->
    <div class="rts-bread-crumbarea-1 rts-section-gap bg_image">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <div class="col-6">
                            <div class="breadcrumb-main-wrapper">
                                <h1 class="title">Disclaimer</h1>
                                <div class="pagination-wrapper">
                                    <a routerLink="/index">Home</a>
                                    <i class="fa-regular fa-chevron-right"></i>
                                    <a class="active">Disclaimer</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="breadcrumb-img-book">
                                <img src="\assets\images\breadcrumb\book.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <main class="policy-container">
        <section class="policy-header">
            <h2>Disclaimer</h2>
            <!-- <p class="effective-date">Effective Date: <strong>[29/10/2024]</strong></p> -->
            <p class="intro">The information provided by <b>Antarkar Sir's Educative Programmes</b> ("we," "us," or "our") on our website and during our courses is for general informational and educational purposes only. While we strive to ensure that all information and content are accurate and up-to-date, we make no guarantees about the completeness, reliability, or accuracy of this information.
            </p>
            <p><b>Antarkar Sir's Educative Programmes</b> is not responsible for any errors or omissions in the content provided, nor for any outcomes, decisions, or actions taken based on the use of this information. The use of our services and content is at your own risk.
            </p>
            <p>By participating in our courses or using our resources, you acknowledge that Antarkar Sir's Educative Programmes does not offer any form of professional, legal, financial, or medical advice. Our educational content is intended solely to support your academic development.
            </p>
            <p>
                <b>Antarkar Sir's Educative Programmes</b> reserves the right to modify, update, or remove any portion of the content provided without prior notice.
            </p>
        </section>
      
        <!-- Policy Sections in Rows -->
        <div class="policy-row">
            <h3>Consent
            </h3>
            <p>
                By using our website, you hereby consent to our disclaimer and agree to its terms.
            </p>
        </div>

        <div class="policy-row">
            <h3>Update
            </h3>
            <p>Should we update, amend, or make any changes to this document, those changes will be prominently posted here.
            </p>
            <p>If you require any more information or have any questions about our site’s disclaimer, please feel free to contact us by email at info@antarkarsir.com
            </p>
        </div>

       
        <footer class="policy-footer">
            <p>For inquiries, please contact us at <a href="mailto:antarkarsir1@gmail.com">antarkarsir1@gmail.com</a>.</p>
        </footer>
    </main>
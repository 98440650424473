<!-- students-feedback.component.html -->
<div class="testimonial-section container-fluid">
  <div class="container mx-auto rts-section-gap">
    <!-- Section Header -->
    <div class="text-center mb-12">
      <h2 class="text-3xl font-bold mb-2">Students Testimonial</h2>
      <p class="text-gray-600">Hear What Our Students Are Buzzing About!</p>
    </div>

    <!-- Testimonials Container -->
    <div class="testimonials-container relative" (mouseenter)="pauseAutoSlide()" (mouseleave)="resumeAutoSlide()">
      <div class="testimonials-grid" [@slideAnimation]="currentSlide">
        <div class="grid-row" *ngIf="chunkedTestimonials[currentSlide]">
          <div class="testimonial-card" *ngFor="let testimonial of chunkedTestimonials[currentSlide]; let j = index">
            <div class="card-content">
              <!-- Quote Mark -->
              <div class="quote-mark">"</div>
              
              <!-- Testimonial Text -->
              <p class="feedback-text">
                {{ review(testimonial.feedback, 100, currentSlide + '-' + j) }}
                <button 
                  *ngIf="testimonial.feedback.length > 100"
                  class="read-more"
                  (click)="toggleShowMore(currentSlide + '-' + j)"
                >
                  {{ showMoreStates[currentSlide + '-' + j] ? 'Read Less' : 'Read More' }}
                </button>
              </p>

              <!-- Author Info -->
              <div class="author-info">
                <div class="avatar">
                  <i class="user-icon"></i>
                  <img src="assets/images/testo.png" alt="avatar"> 
                </div>
                <div class="author-details">
                  <h6 class="author-name">{{ testimonial.name }}</h6>
                  <p class="author-work">{{ testimonial.work }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <button 
        class="nav-button prev" 
        (click)="previousSlide()" 
        *ngIf="currentSlide > 0"
      >
        <i class="arrow-left"></i>
      </button>

      <button 
        class="nav-button next" 
        (click)="nextSlide()" 
        *ngIf="currentSlide < chunkedTestimonials.length - 1"
      >
        <i class="arrow-right"></i>
      </button>

      <!-- Pagination Dots -->
      <div class="pagination-dots">
        <button 
          *ngFor="let dot of getDotArray()"
          class="dot"
          [class.active]="currentSlide === dot"
          (click)="currentSlide = dot"
        ></button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modalExample" tabindex="-1" role="dialog" aria-labelledby="modalExampleTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">

        <!-- Close -->
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>

        <!-- Heading -->
        <h2 class="fw-bold text-center mb-1" id="modalExampleTitle">
          Schedule a demo with us
        </h2>

        <!-- Text -->
        <p class="font-size-lg text-center text-muted mb-6 mb-md-8">
          We can help you solve company communication.
        </p>

        <!-- Form -->
        <form>
          <div class="row">
            <div class="col-12 col-md-6">

              <!-- First name -->
              <div class="form-label-group">
                <input type="text" class="form-control form-control-flush" id="registrationFirstNameModal"
                  placeholder="First name">
                <label for="registrationFirstNameModal">First name</label>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <!-- Last name -->
              <div class="form-label-group">
                <input type="text" class="form-control form-control-flush" id="registrationLastNameModal"
                  placeholder="Last name">
                <label for="registrationLastNameModal">Last name</label>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">

              <!-- Email -->
              <div class="form-label-group">
                <input type="email" class="form-control form-control-flush" id="registrationEmailModal"
                  placeholder="Email">
                <label for="registrationEmailModal">Email</label>
              </div>

            </div>
            <div class="col-12 col-md-6">

              <!-- Password -->
              <div class="form-label-group">
                <input type="password" class="form-control form-control-flush" id="registrationPasswordModal"
                  placeholder="Password">
                <label for="registrationPasswordModal">Password</label>
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12">

              <!-- Submit -->
              <button class="btn btn-block btn-primary mt-3 lift">
                Request a demo
              </button>

            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>
<!-- Modal Sidebar cart -->
<div class="modal modal-sidebar left fade-left fade" id="cartModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header mb-4 shop-cart">
        <h5 style="font-size: 20px;" class="modal-title">Your Shopping Cart</h5>
        <button type="button" class="close text-primary" data-bs-dismiss="modal" aria-label="Close">

          <i class="fas fa-lg fa-times"></i>

        </button>
      </div>

      <div class="modal-body">
        <ul class="list-group list-group-flush mb-5">

          <ng-container *ngFor="let getCourse of cartCousesArray;let i=index">
            <li class="list-group-item border-bottom py-0">
              <div class="d-flex py-5">
                <img class="bg-gray-200  rounded-circle overflow-hidden"
                  style="width: 14%;height: auto; aspect-ratio: 1/1;" src="{{apiURL}}/{{getCourse.image}}" alt="...">
                <!-- <div class="bg-gray-200 w-60p h-60p rounded-circle overflow-hidden"></div> -->
                <!-- {{apiURL}}/{{viewData?.bannerImg}} -->

                <div class="flex-grow-1 mt-1 ms-4">
                  <h6 class="fw-normal mb-0">{{getCourse.name}}</h6>
                  <div class="font-size-sm">₹{{getCourse.offer_Price}}</div>
                </div>

                <!-- <input type="hidden" #course_id value="{{cartCourse.course_Id}}"> -->
                <div class="d-inline-flex text-secondary" (click)="removeCourse(getCourse)">
                  <!-- Icon -->
                  <i class="far fa-sm fa-trash-alt" style="cursor: pointer;"></i>
                </div>
              </div>
            </li>
          </ng-container>

          <ng-container *ngIf="cartCousesArray.length == 0">
            <li class="list-group-item border-bottom py-0">
              <div class="d-flex py-5 cart-head" style="height: 300px;">
                <!-- <h1>Your cart is empty!</h1> -->
                <img src="assets/images/cart/cartempty.png" alt="img" class="responsive-img">
              </div>
            </li>

          </ng-container>
        </ul>
        <div class="modal-footer d-flex justify-content-between ">
          <button class="btn btn-primary rts-btn btn-sm-wide me-auto" (click)="goViewCart()" data-bs-dismiss="modal">
            VIEW CART
          </button>

          <div>
            Order Subtotal : ₹<b>{{cartSubtotalAmt}}</b>
          </div>
        </div>



      </div>



    </div>
  </div>
</div>








<header class="header-one v-5 header--sticky">
  <div class="container" >
    <div class="row">
      <div class="col-lg-12">
        <div class="header-one-wrapper">
          <div class="left-side-header">
            <a routerLink="/index" class="lgoo-area">
              <img style="height: 90px;width: 185px;margin-bottom: 25px;"
                src="assets/images/Antarkar Sir_s logo color.png" alt="logo">
            </a>
            <div class="main-nav-one">
              <nav>
                <ul> 
                  <li class="" style="position: static;">
                    <a class="nav-link " [routerLink]="['/index']" (click)="closeMobileNav()">Home</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/about-us']" (click)="closeMobileNav()">About Us</a>
                  </li>
                  <li style="position: static;">
                    <a class="nav-link" [routerLink]="['/courses']" (click)="closeMobileNav()">Course</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/faq']" (click)="closeMobileNav()">FAQ</a>
                  </li>
                  <li>
                    <a class="nav-link" [routerLink]="['/contact-us']" (click)="closeMobileNav()">Contact us</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="header-right-area-one">
            <div class="actions-area">
              <div class="search-btn" id="search">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path
                    d="M19.9375 18.9652L14.7454 13.7732C15.993 12.2753 16.6152 10.3542 16.4824 8.40936C16.3497 6.46453 15.4722 4.64575 14.0326 3.33139C12.593 2.01702 10.7021 1.30826 8.75326 1.35254C6.8044 1.39683 4.94764 2.19075 3.56924 3.56916C2.19083 4.94756 1.39691 6.80432 1.35263 8.75317C1.30834 10.702 2.0171 12.5929 3.33147 14.0325C4.64584 15.4721 6.46461 16.3496 8.40944 16.4823C10.3543 16.6151 12.2754 15.993 13.7732 14.7453L18.9653 19.9374L19.9375 18.9652ZM2.75 8.93742C2.75 7.71365 3.11289 6.51736 3.79278 5.49983C4.47267 4.4823 5.43903 3.68923 6.56965 3.22091C7.70026 2.7526 8.94436 2.63006 10.1446 2.86881C11.3449 3.10756 12.4474 3.69686 13.3127 4.56219C14.1781 5.42753 14.7674 6.53004 15.0061 7.7303C15.2449 8.93055 15.1223 10.1747 14.654 11.3053C14.1857 12.4359 13.3926 13.4022 12.3751 14.0821C11.3576 14.762 10.1613 15.1249 8.9375 15.1249C7.29703 15.1231 5.72427 14.4706 4.56429 13.3106C3.4043 12.1506 2.75182 10.5779 2.75 8.93742Z"
                    fill="#553CDF" />
                </svg>
              </div>
              <button type="button" class="btn position-relative" style="font-size: large;" data-bs-toggle="offcanvas"
                href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                <i class="fa-solid fa-cart-shopping position-relative"></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{totalItem}}
                  <span class="visually-hidden">unread messages</span>
                </span>
              </button>
              <div *ngIf="logged_user" class="user-dropdown">
                <!-- User Profile Link -->
                <a class="nav-link"  (click)="closeMobileNav()">
                  <i class="fa-solid fa-user"></i>
                </a>

                <div class="dropdown-menu mobile-menu-pro">
                  <ul>
                    <li><a [routerLink]="['/my-profile']">My Profile</a></li>
                    <li><a (click)="logout()">Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="buttons-area" *ngIf="!logged_user">
              <a class="nav-link rts-btn btn-border" [routerLink]="['/login']" (click)="closeMobileNav()">Login</a>
              <!-- <a href="registration.html" class="rts-btn btn-primary">Sign Up</a> -->
            </div>
            <div class="buttons-area">
              <!--22-11-2024-->
             
                

              <!-- <a href="registration.html" class="rts-btn btn-primary">Sign Up</a> -->
            </div>


            <div *ngIf="logged_user">
              <!-- <a class="nav-link" >
                          <a  data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                            <i class="fa-solid fa-cart-shopping position-relative"></i>
                          </a>
                        </a> -->

              <!-- <button type="button" class="btn position-relative" style="font-size: large;" data-bs-toggle="offcanvas"
                href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                <i class="fa-solid fa-cart-shopping position-relative"></i>
                <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                  {{totalItem}}
                  <span class="visually-hidden">unread messages</span>
                </span>
              </button> -->
            </div>
            <div class="menu-btn" id="menu-btn">
              <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="14" width="20" height="2" fill="#1F1F25"></rect>
                <rect y="7" width="20" height="2" fill="#1F1F25"></rect>
                <rect width="20" height="2" fill="#1F1F25"></rect>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
<div id="side-bar" class="side-bar header-two">
  <button class="close-icon-menu" id="mobileNavId"><i class="far fa-times"></i></button>
  <!-- inner menu area desktop start -->
  <div class="inner-main-wrapper-desk">
    <div class="thumbnail">
      <img src="assets/images/banner/04.png" alt="elevate">
    </div>
    <div class="inner-content">
      <h4 class="title">We Build Building and Great Constructive Homes.</h4>
      <p class="disc">
        We successfully cope with tasks of varying complexity, provide long-term guarantees and regularly
        master new technologies.
      </p>
      <div class="footer">
        <h4 class="title">Got a project in mind?</h4>
        <a href="contact.html" class="rts-btn btn-primary">Let's talk</a>
      </div>
    </div>
  </div>
  <!-- mobile menu area start -->
  <div class="mobile-menu-main">
    <nav class="nav-main mainmenu-nav mt--30">
      <ul class="mainmenu metismenu" id="mobile-menu-active">
        <li class="">
          <a class="nav-link " [routerLink]="['/index']" (click)="closeMobileNav()">Home</a>
        </li>
        <li>
          <a class="nav-link" [routerLink]="['/about-us']" (click)="closeMobileNav()">About Us</a>
        </li>
        <li>
          <a class="nav-link" [routerLink]="['/courses']" (click)="closeMobileNav()">Course</a>
        </li>
        <li>
          <a class="nav-link" [routerLink]="['/faq']" (click)="closeMobileNav()">FAQ</a>
        </li>
        <li>
          <a class="nav-link" [routerLink]="['/contact-us']" (click)="closeMobileNav()">Contact us</a>
        </li>
      </ul>
    </nav>

    <div class="buttons-area">
      <a class="nav-link rts-btn btn-border" [routerLink]="['/login']" (click)="closeMobileNav()">Login</a>
      <!-- <a href="registration.html" class="rts-btn btn-primary">Sign Up</a> -->
    </div>


  </div>
  <!-- mobile menu area end -->
</div>

<div class="search-input-area">
  <div class="container">
    <div class="search-input-inner">
      <div class="input-div">
        <input class="" type="text" placeholder="Search by keyword or #" [ngModel]="searchInput"
          (keyup)="onKeyup($event)">
        <button (click)="callSearchCourse()"><i class="far fa-search"></i></button>
      </div>
    </div>
    <div class="input-div">
      <ul class="list-group" *ngFor="let item of searchCourseList">
        <li class="list-group-item c-pointer" (click)="callCourseDetails(item)">{{item?.name}}</li>
      </ul>
    </div>

  </div>
  <div id="close" class="search-close-icon"><i class="far fa-times"></i></div>
</div>




<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasExampleLabel">Shopping Cart</h5>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <div>
      <p>Your cart contains the following courses:</p>
      <ul class="list-group list-group-flush mb-5">

        <ng-container *ngFor="let getCourse of cartCousesArray;let i=index">
          <li class="border-bottom py-0">
            <div class="d-flex py-5">
              <img class="bg-gray-200  rounded-circle overflow-hidden"
                style="width: 14%;height: auto; aspect-ratio: 1/1;" src="{{apiURL}}/{{getCourse.image}}" alt="..." (error)="setDefaultImage($event)">
              <!-- <div class="bg-gray-200 w-60p h-60p rounded-circle overflow-hidden"></div> -->
              <!-- {{apiURL}}/{{viewData?.bannerImg}} -->

              <div class="flex-grow-1 mt-1 ms-4">
                <h6 class="fw-normal mb-0">{{getCourse.name}}</h6>
                <div class="font-size-sm">₹{{getCourse.offer_Price}}</div>
              </div>

              <!-- <input type="hidden" #course_id value="{{cartCourse.course_Id}}"> -->
              <div class="d-inline-flex text-secondary" (click)="removeCourse(getCourse)">
                <!-- Icon -->
                <i class="far fa-sm fa-trash-alt" style="cursor: pointer;"></i>
              </div>
            </div>
          </li>
        </ng-container>

        <ng-container *ngIf="cartCousesArray.length == 0">
          <li class=" border-bottom py-0">
            <div class="d-flex py-5 cart-head" style="height: 300px;">
              <!-- <h1>Your cart is empty!</h1> -->
              <img src="assets/images/cart/cartempty.png" alt="img" class="responsive-img">
            </div>
          </li>

        </ng-container>
      </ul>
    </div>


  </div>
  <div class="offcanvas-footer">
    <div class="modal-footer d-flex justify-content-between ">
      <button class="btn btn-primary rts-btn btn-sm-wide me-auto" (click)="goViewCart()" data-bs-dismiss="offcanvas">
        VIEW CART
      </button>

      <div>
        Order Subtotal : ₹<b>{{cartSubtotalAmt}}</b>
      </div>
    </div>
  </div>
  

</div>
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { ToastrService } from 'ngx-toastr';


@Component({
selector: 'app-forgot-password',
templateUrl: './forgot-password.component.html',
styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
form!: FormGroup;
submitted = false;
loading!: false;
name:string='Angular';
returnUrl?: string='';
returnUrlHash?: string='';


constructor(private formBuilder: FormBuilder,private accountservice : AccountService,
private router: Router,private route: ActivatedRoute,private toastr:ToastrService) { }

ngOnInit(): void {
this.form = this.formBuilder.group({
email: ['', Validators.required],
appname:['']
});
}

get f() { return this.form.controls; }


onSubmit(){
this.submitted = true;
this.form.value.appname = this.name;

this.accountservice.forgotPassword(this.form.value)
.subscribe((res) => {
this.router.navigate(['/login']);

this.toastr.success('Please check your email for password reset instructions')
},
(error) => {
console.log(error);
this.toastr.error(`Can not find the given email address:${this.form.value.email}`)
});

}

}

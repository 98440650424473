import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { AlertService } from '../../services/alert.service';
import { ToastrService } from 'ngx-toastr';
import jwt_decode from 'jwt-decode';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import jwtDecode from 'jwt-decode';

interface GoogleUserInfo {
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email_verified: boolean;
  sub: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
  loginForm!: FormGroup;
  isSubmitted: boolean = false;
  guiArray: any;
  loading = false;
  returnUrl!: string;
  showPassword = false;
  isRememberMeChecked: boolean = false;
  appleForm !: FormGroup;
  googleForm !: FormGroup;
  decodedToken:any;

  constructor(private formBuilder: FormBuilder, private alertservice: AlertService,
    private router: Router, private accountservice: AccountService, private route: ActivatedRoute, private toastr: ToastrService,
    private cryptojsService: CryptojsService, private http: HttpClient
  ) { }


  

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      userNameOrEmailAddress: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: false,
    });
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.checkRememberMe()
    this.loadGoogleSignInScript();
    this.initAppleSignIn();
    this.createAppleForm()
    this.createGoogleForm()
  }

  createAppleForm(){
    this.appleForm = this.formBuilder.group({
      name: ['', Validators.required],
      number: [
        '',
        [Validators.required,],
      ],
      username:[''],
      email: ['pawarvshal10k@gmail.com', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      surname: ['', [Validators.required]],
      appname: ['anterkarsir', [Validators.required]],
    });
  }


  createGoogleForm(){
    this.googleForm = this.formBuilder.group({
      number: [
        '',
        [Validators.required,],
      ],
      password: [
        '',
        [Validators.required,],
      ],
    })
  }

  checkRememberMe(){
    let getRememberMeData:any=this.cryptojsService.decryptLocalLoginEmailPassword()
    let JsonParsedData:any=JSON.parse(getRememberMeData)
    if(JsonParsedData){
      this.loginForm.controls['userNameOrEmailAddress'].setValue(JsonParsedData?.email)
      this.loginForm.controls['password'].setValue(JsonParsedData?.password)
      this.isRememberMeChecked = true;
    }else{
      this.isRememberMeChecked = false;
    }
  }

  loadGoogleSignInScript(): void {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => this.initializeGoogleSignIn();
    document.head.appendChild(script);
  }

  initializeGoogleSignIn(): void {
    if ((window as any).google) {
      try {
        (window as any).google.accounts.id.initialize({
          client_id: '1035941459256-9t7fmuelviq37bd28vu64e8i7afq03iu.apps.googleusercontent.com', // Replace with your actual client ID
          callback: this.handleGoogleSignIn.bind(this)
        });
        (window as any).google.accounts.id.renderButton(
          document.getElementById('googleSignInButton'),
          { theme: 'outline', size: 'large' }
        );
      } catch (error) {
        console.error('Google Sign-In initialization error:', error);
        this.toastr.error('Google Sign-In could not be initialized');
      }
    }
  }


  ngAfterViewInit(): void {
    (window as any).onGoogleSignIn = (response: any) => this.handleGoogleSignIn(response);
  }

  private decodeJWT(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error decoding JWT:', error);
      return null;
    }
  }

  handleGoogleSignIn(response: any) {
    const credential = response.credential;
    // console.log('Google credential received:', credential);
    if (credential) {
      this.decodedToken = this.decodeJWT(credential);
      // console.log('Decoded user info:', this.decodedToken);
      document.getElementById('googleIdExtra')?.click()
    }
  }

  onGoogleFormSubmit(){
    // console.log("get google form",this.googleForm);
    if(this.googleForm.valid){
      if (this.decodedToken) {
        const userInfo: GoogleUserInfo = {
          email: this.decodedToken.email,
          name: this.decodedToken.name,
          given_name: this.decodedToken.given_name,
          family_name: this.decodedToken.family_name,
          picture: this.decodedToken.picture,
          email_verified: this.decodedToken.email_verified,
          sub: this.decodedToken.sub
        };
        const payload: any = {
          emailAddress: userInfo.email,
          name: userInfo.given_name,
          surname: userInfo.family_name,
          username: userInfo.given_name + '@A' + this.googleForm.value.number,
          password: this.googleForm.value.password,
          phoneNumber:this.googleForm.value.number,
          appname: 'anterkarsir'
        };

        this.accountservice.register(payload)
          .subscribe({
            next: (result: any) => {
              // console.log("get data\\\\\\", result);
              if (result) {
                this.loginForm.controls['userNameOrEmailAddress'].setValue(result?.email);
                this.loginForm.controls['password'].setValue(this.googleForm.value.password);
                this.onSubmit();
                document.getElementById('googleIdExtraClose')?.click()
              }

            },
            error: (error) => {
                this.toastr.error(error?.error?.error?.message ? error?.error?.error?.message : 'Something went wrong please try later.', 'Error', { timeOut: 5000 });
            }
          });
      } else {
        this.toastr.error('Error processing Google sign-in information');
      }
    }else{
      this.googleForm.markAllAsTouched()
    }
  }

  resetGoogleForm(){
    this.googleForm.reset()
  }

  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.isSubmitted = true;
    this.alertservice.clear();
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched()
      return;
    }
    this.loading = true;
    this.accountservice.loginCall(this.loginForm.value).subscribe((resLoginCall: any) => {
      if (resLoginCall) {
        // this.toastr.success(resLoginCall?.description ? resLoginCall?.description : 'invalid username or password', 'Error', { timeOut: 8000 })
      }
    },
      (err) =>
        console.log(err?.error?.error_description)
    )

    this.accountservice.login(this.loginForm.value.userNameOrEmailAddress, this.loginForm.value.password)// ,this.loginForm.value.rememberMe
      .subscribe((result: any) => {
        if (result.access_token !== null) {
          this.cryptojsService.encryptLocalUserName(this.loginForm.value.userNameOrEmailAddress)
          this.cryptojsService.encryptLocalAccessToken(result.access_token)
          let token = this.cryptojsService.decryptLocalAccessToken()
          if (!token) {
            token = '{}';
          }
          // console.log("Decrypted Token: ", token);
          let decoded_token = jwt_decode(token);
          this.cryptojsService.encryptLocalUserId(Object(decoded_token)["sub"])
          this.router.navigateByUrl(this.returnUrl)
            .then(() => {
              this.router.navigateByUrl('index')
              this.toastr.success('Login successful');
              this.cryptojsService.checkUserLoginOrNot()
            });
        }
      }, (err) =>
        this.toastr.error(err?.error?.error_description ? err?.error?.error_description : 'invalid username or password', 'Error', { timeOut: 5000 })
      );
  }

  displayPassWord() {
    this.showPassword = !this.showPassword;
  }


  rememberMeCall(event: any): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      let loginEmailPassword:any={
        email:this.loginForm.value.userNameOrEmailAddress,
        password:this.loginForm.value.password
      }
      this.cryptojsService.encryptLocalLoginEmailPassword(JSON.stringify(loginEmailPassword))
    } else {
      localStorage.removeItem("loginEmailPassword")
    }
  }

  initAppleSignIn(){
    const clientId = 'com.asir.antllp.'; // Replace with your Service ID
    const redirectURI = 'https://asir.antllp.com/index'; // Replace with your redirect URI
  
    // (window as any).AppleID.auth.init({
    //   clientId: 'com.asir.myapp.signin',
    //   scope: 'email',
    //   redirectURI: 'https://asir.antllp.com/index',
    // });

    (window as any).AppleID.auth.init({
      clientId,
      scope: 'name email',
      redirectURI,
      state: 'optional-csrf-token', // Optional, for CSRF protection
      usePopup: true // Set to true for a popup flow
    });

  }

  signInWithApple(){
    (window as any).AppleID.auth.signIn()
    .then((response: any) => {
      // console.log('User successfully authenticated:', response);

      const idToken = response.authorization.id_token;
      if (!idToken) {
        console.error('Error: id_token not returned in response');
        return;
      }

      // Log the id_token for debugging
      // console.log('id_token:', idToken);

      // Decode the JWT manually (without a library)
      const tokenParts = idToken.split('.');
      if (tokenParts.length !== 3) {
        console.error('Error: Invalid id_token format');
        return;
      }

      const payloadApple = JSON.parse(atob(tokenParts[1])); // Decode the payload
      // console.log('Decoded User Data:', payloadApple);

      if(payloadApple.email != null){
        this.appleForm.controls['username'].setValue(payloadApple.email)
        document.getElementById('appleIdExtra')?.click()
      }

   
      // console.log('Valid token, user data:', payloadApple);
    })
    .catch((error: any) => {
      // console.error('Error during Apple Sign-In:', error);
    });
  }

  onAppleFormSubmit() {
    this.appleForm.controls['username'].setValue(this.appleForm.value.name + '@'+ this.appleForm.value.number)
    // console.log("get apple form",this.appleForm)
    
    if (this.appleForm.valid) {
      // console.log('Form Submitted', this.appleForm.value);

      const payload: any = {
        emailAddress: this.appleForm.value.email,
        name: this.appleForm.value.name,
        surname: this.appleForm.value.surname,
        username: this.appleForm.value.username,
        password: this.appleForm.value.password,
        phoneNumber:this.appleForm.value.number,
        appname: 'anterkarsir'
      };

      this.accountservice.register(payload)
          .subscribe({
            next: (result: any) => {
              // console.log("get data\\\\\\", result);
              if (result) {
                this.loginForm.controls['userNameOrEmailAddress'].setValue(result?.email);
                this.loginForm.controls['password'].setValue(this.appleForm.value.password);
                this.onSubmit();
                document.getElementById('appleIdExtraClose')?.click()
              }

            },
            error: (error) => {
                this.toastr.error(error?.error?.error?.message ? error?.error?.error?.message : 'Something went wrong please try later.', 'Error', { timeOut: 5000 });
            }
          });
          
    } else {
      // console.log('Form is invalid');
    }
  }

  resetAppleForm(){
    this.appleForm.reset()
  }


  

}


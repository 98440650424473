import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'src/app/shared/services/account.service';

import { TimeConvertorPipe } from 'src/app/shared/time-convertor.pipe'
import { CourseService } from '../services/course.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss']
})
export class TopicComponent implements OnInit,AfterViewInit {
  @Input() lessonData: any;
  
  
  href: any;
  url: any;
  subscription: any;
  Id: any;
  viewCourse: any;
  enabled = false;

  constructor(private router: Router,private courseService: CourseService,private ActivateRoute: ActivatedRoute,
    private cryptojsService:CryptojsService
  ) {

  }

  ngOnInit(): void {
    this.href = this.router.url;
     //console.log(this.href,'url');
     this.url  = this.href.split('/');
    //  console.log(this.url[1],'url');
     this.Id = this.cryptojsService.decryptLocalUserId()
     

     this.ActivateRoute.params.subscribe(data => {
      this.viewCourse = data.Id.toString()
      // console.log(this.viewCourse);
      this.Subscription();
     

    })

  }

  ngAfterViewInit() : void{
  }
  
  @Output() newVideoEvent = new EventEmitter();
  PlayVideo(id: any) {
    this.newVideoEvent.emit(id);
  }

  Subscription() {
   
    let ClientId = this.cryptojsService.decryptLocalUserId()
    this.courseService.GetSubscriptionData(ClientId,this.viewCourse,).subscribe(res => {
      this.subscription = res;
      
      this.enabled = true;

    },err =>{
      if(err.status == 404){
        this.enabled = false;
        
      }
    }
    
    )
    

  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IndexService } from '../../services/index.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  resetPasswordForm!: FormGroup;
  confirmPasswordForm!: FormGroup;
  form!: FormGroup;
  loading = false;
  submitted = false;
  phoneNumber: string = '8828228330';  // Enter the phone number in international format
  defaultMessage: string = '“Hello, I would like to know more about the course.”';

  showOtpInput: boolean = false;

  constructor(private formBuilder: FormBuilder, private toastr: ToastrService, private IndexService: IndexService) { }

  ngOnInit(): void {
    this.createResetPasswordForm()
    this.createConfirmPasswordForm()
    this.form = this.formBuilder.group({
      sendmail: ['', Validators.required, Validators.email],
    });
  }

  get f() { return this.form.controls; }


  getWhatsAppLink(): string {
    return `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(this.defaultMessage)}`;
  }


  onSubmit() {

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    this.IndexService.sendMail(this.form.value)
      .subscribe({
        next: () => {

          this.toastr.success('Thank you for subscribe...');
          // console.log(this.form.value)

        },
        error: error => {
          this.toastr.error(error);
          this.loading = false;
        }
      });
  }


  createResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      emailorphonenumber: ['', Validators.required],
      otp: ['', [Validators.required]]
    })
  }

  setOTPFlag(){
    this.showOtpInput = false;
  }

  createConfirmPasswordForm() {
    this.confirmPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmpassword: ['', [Validators.required]]
    })
  }

  showResetPasswordForm() {
    this.showOtpInput = true;
    this.toastr.success('OTP has been sent to your email address');
  }

  onSubmitAdmission() {
    // console.log("get value",this.resetPasswordForm);

    if (this.resetPasswordForm.valid) {
      // console.log(this.resetPasswordForm.value);
      document.getElementById('resetPasswordID')?.click();
      document.getElementById('confirmPasswordID')?.click();
      this.resetPasswordForm.reset()
      // Handle form submission
    } else {
      // console.log('Form is invalid');
    }
  }

  onConfirmSubmit() {
    // console.log("get value",this.confirmPasswordForm);
    if (this.confirmPasswordForm.valid) {
      document.getElementById('confirmPasswordID')?.click();
      this.toastr.success('Password has been reset successfully');
      this.confirmPasswordForm.reset()
    } else {

    }
  }

  findToaster() {
    // this.toastr.warning("Get data")
  }

  showCustomToastr() {
    // this.toastr.show('Get data', '', {
    //     toastClass: 'custom-toastr',
    //    // Add any specific icon classes you need
    //     closeButton: true,    // Enable close button
    // });
  }



}

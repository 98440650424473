import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../services/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  resetform!: FormGroup;
  submitted = false;
  loading!: false;
  resetToken!:string;
  UserId!:string;


  constructor(private formBuilder: FormBuilder,private accountservice : AccountService,
    private router: Router,private route: ActivatedRoute,private toastr:ToastrService) { }

  ngOnInit(): void {
    this.resetform = this.formBuilder.group({
    password: ['', Validators.required],
    confirmpassword: ['', Validators.required],
    resetToken:[''],
    UserId:['']
    });
    }

  get f() { return this.resetform.controls; }

  onSubmit(){
    this.resetform.value.resetToken = this.route.snapshot.queryParamMap.get("resetToken");
    this.resetform.value.UserId = this.route.snapshot.queryParamMap.get("userId");

    if(this.resetform.value.password===this.resetform.value.confirmpassword){
      this.submitted = true;
      this.accountservice.resetPassword(this.resetform.value)
      .subscribe((res) => {
      this.router.navigate(['/login']);
      
      this.toastr.success('Successfully updated password! Please Login with new password')
      },
      (error) => {
      console.log(error);
      this.toastr.error("Something went wrong...")
      });
    }
    else{
      this.toastr.error('Password Does Not match...')
    }
}

}

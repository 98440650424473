
     <!-- PAGE TITLE
    ================================================== -->
    <header class="py-2 py-md-4 main-banner-in1 overlay overlay-primary overlay-70 jarallax" data-jarallax
        data-speed=".8" style="background-image: url(assets/img/covers/cover-19.jpg);">
        <div class="container-fluid text-center ">
            <h2 class="fw-semi-bold mb-0 text-white py-1 py-md-3">BLOG</h2>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-scroll justify-content-center py-1 ">
                    <li class="breadcrumb-item">
                        <a class="text-white" href="#">
                            Home
                        </a>
                    </li>
                    <li class="breadcrumb-item text-white active" aria-current="page">
                        Blog
                    </li>
                </ol>
            </nav>
        </div>
        <!-- Img -->

    </header>


    <!-- BLOG-SINGLE
    ================================================== -->
    
    <div class="container-fluid py-8 pt-lg-8">
        <div class="row">
            <div class="w-xl-100 mx-auto" data-aos="fade-up">
                <h1 class="text-capitalize">{{viewData.title}}</h1>

                <p class="me-xl-12">{{viewData.shortDescription}}</p>

                <div class="d-md-flex align-items-center">
                    <div class="border rounded-circle d-inline-block mb-4 mb-md-0 me-4">
                        <div class="p-1">
                            <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="rounded-circle" width="52" height="52">
                        </div>
                    </div>

                    <div class="mb-4 mb-md-0">
                        <a href="#" class="d-block"><h6 class="mb-0">{{viewData.authorName}}</h6></a>
                        <span class="font-size-sm">{{viewData.creationTime | date:'mediumDate'}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sk-thumbnail1 img-ratio-71 p-8 pt-1" data-aos="fade-in">
        <div class="row">
        <img src="{{apiURL}}/{{viewData.coverImg}}" alt="..." class="img-fluid1"></div>
    </div>
    <div class="container-fluid">
        <div class="row mb-8 mb-md-12">
            <div class="w-xl-100 mx-auto" data-aos="fade-in">
                <h3 class="">Description</h3>
                <p [innerHTML]="viewData.content" class="mb-6 line-height-md"></p>               

                <h3 class="mb-6">Comment</h3>
                <ul class="list-unstyled pt-2">
                    <li class="media d-flex">
                        <div class="avatar avatar-xxl me-3 me-md-6 flex-shrink-0">
                            <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                        </div>
                        <div class="media-body flex-shrink-1">
                            <div class="d-md-flex align-items-center mb-5">
                                <div class="me-auto mb-4 mb-md-0">
                                    <h5 class="mb-0">Oscar Cafeo</h5>
                                    <p class="font-size-sm font-italic">Beautiful courses</p>
                                </div>
                                <div class="star-rating">
                                    <div class="rating" style="width:100%;"></div>
                                </div>
                            </div>
                            <p class="mb-6 line-height-md">This course was well organized and covered a lot more details than any other Figma courses. I really enjoy it. One suggestion is that it can be much better if we could complete the prototype together. Since we created 24 frames, I really want to test it on Figma mirror to see all the connections. Could you please let me take a look at the complete prototype?</p>
                        </div>
                    </li>
                    <li class="media d-flex">
                        <div class="avatar avatar-xxl me-3 me-md-6 flex-shrink-0">
                            <img src="assets/img/avatars/avatar-2.jpg" alt="..." class="avatar-img rounded-circle">
                        </div>
                        <div class="media-body flex-shrink-1">
                            <div class="d-md-flex align-items-center mb-5">
                                <div class="me-auto mb-4 mb-md-0">
                                    <h5 class="mb-0">Alex Morgan</h5>
                                    <p class="font-size-sm font-italic">Beautiful courses</p>
                                </div>
                                <div class="star-rating">
                                    <div class="rating" style="width:100%;"></div>
                                </div>
                            </div>
                            <p class="mb-6 line-height-md">This course was well organized and covered a lot more details than any other Figma courses. I really enjoy it. One suggestion is that it can be much better if we could complete the prototype together. Since we created 24 frames, I really want to test it on Figma mirror to see all the connections. Could you please let me take a look at the complete prototype?</p>
                        </div>
                    </li>
                </ul>

                <div class="border shadow rounded p-6 p-md-9">
                    <h3 class="mb-2">Add Reviews & Rate</h3>
                    <div class="">What is it like to Course?</div>
                    <form [formGroup]="addreviewsForm" (ngSubmit)="addReviewToBlog()">
                        <div class="clearfix">
                            <fieldset class="slect-rating mb-3">
                                <input type="radio" id="star5" formControlName="stars" value="5" />
                                <label class="full" for="star5" title="Awesome - 5 stars"></label>
              
                                <input type="radio" id="star4half" formControlName="stars" value="4.5" />
                                <label class="half" for="star4half" title="Pretty good - 4.5 stars"></label>
              
                                <input type="radio" id="star4" formControlName="stars" value="4" />
                                <label class="full" for="star4" title="Pretty good - 4 stars"></label>
              
                                <input type="radio" id="star3half" formControlName="stars" value="3.5" />
                                <label class="half" for="star3half" title="Meh - 3.5 stars"></label>
              
                                <input type="radio" id="star3" formControlName="stars" value="3" />
                                <label class="full" for="star3" title="Meh - 3 stars"></label>
              
                                <input type="radio" id="star2half" formControlName="stars" value="2.5" />
                                <label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label>
              
                                <input type="radio" id="star2" formControlName="stars" value="2" />
                                <label class="full" for="star2" title="Kinda bad - 2 stars"></label>
              
                                <input type="radio" id="star1half" formControlName="stars" value="1.5" />
                                <label class="half" for="star1half" title="Meh - 1.5 stars"></label>
              
                                <input type="radio" id="star1" formControlName="stars" value="1" />
                                <label class="full" for="star1" title="Sucks big time - 1 star"></label>
              
                                <input type="radio" id="starhalf" formControlName="stars" value="0.5" />
                                <label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label>
                              </fieldset>
                        </div>

                        <div class="form-group mb-6">
                            <label for="exampleInputTitle1">Review Title</label>
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && f.reviewTitle.errors }" formControlName="reviewTitle" class="form-control placeholder-1" id="exampleInputTitle1" placeholder="Courses">
                            <div *ngIf="submitted && f.reviewTitle.errors" class="invalid-feedback">
                                <div *ngIf="f.reviewTitle.errors.required" style="color: red">Title is required</div>
                            </div>
                        </div>

                        <div class="form-group mb-6">
                            <label for="exampleFormControlTextarea1">Review Content</label>
                            <textarea [ngClass]="{ 'is-invalid': submitted && f.reviews.errors }" formControlName="reviews" class="form-control placeholder-1" id="exampleFormControlTextarea1" rows="6" placeholder="Content"></textarea>
                            <div *ngIf="submitted && f.reviews.errors" class="invalid-feedback">
                                <div *ngIf="f.reviews.errors.required" style="color: red">Review is required</div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary btn-sm btn-block mw-md-300p">SUBMIT REVIEW</button>
                    </form>
                </div>
            </div>
        </div>

    </div>


  
    <div class="bubbles_wrap">
        <div class="bubble x1"></div>
        <div class="bubble x2"></div>
        <div class="bubble x3"></div>
        <div class="bubble x4"></div>
        <div class="bubble x5"></div>
        <div class="bubble x6"></div>
        <div class="bubble x7"></div>
        <div class="bubble x8"></div>
        <div class="bubble x9"></div>
        <div class="bubble x10"></div>
    </div>
    <!-- Scroll To Top Start -->
    <a href="#main-banner" class="scroll-top" id="scroll-to-top">
        <i class="fa fa-arrow-up" aria-hidden="true"></i>
    </a>

import { formatDate, LowerCasePipe, } from '@angular/common';
import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IndexService } from 'src/app/shared/services/index.service';
import { environment } from 'src/environments/environment';
import { CourseService } from '../services/course.service';
import { CryptojsService } from 'src/app/core/services/cryptojs.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {
  courses: any = [];
  apiURL = environment.url;
  id: any;
  courseCategory: any = []
  wishlist: any = []
  constructor(private indexService: IndexService,
    private courseService: CourseService, private toastr: ToastrService,
    private cryptojsService: CryptojsService
  ) { }

  ngOnInit(): void {
    this.getAllCourseCategory();
    this.getAllCourses();
  }

  getAllCourseCategory() {
    this.indexService.getCategory().subscribe((resCategory: any) => {
      if (resCategory.items) {
        this.courseCategory = resCategory.items
      }
    })
  }

  getAllCourses() {
    this.courses = [];
    this.indexService.getCourse().subscribe(
      (response: any) => {
        this.courses = response['items']; 
        this.courses = this.courses.map((item: any) => {
          return {
            ...item,
            star: Math.round(item.star), 
          };
        });
        this.myWishlist(); 
      },
      (err) => {
        console.error("Error fetching courses:", err);
      }
    );
  }

  myWishlist() {
    let Id = this.cryptojsService.decryptLocalUserId()
    this.indexService.myWishlist(Id).subscribe(res => {
      if (res.length > 0) {
        this.wishlist = res
      } else {
        this.wishlist = []
      }
      let updatedCourse: any = this.courses.map((courseItem: any) => {
        const isFavourite = this.wishlist.some((wishItem: any) => LowerCasePipe.prototype.transform(wishItem.course_Id) === courseItem.id);

        const isFavouriteId: any = this.wishlist.filter((wishItem: any) => LowerCasePipe.prototype.transform(wishItem.course_Id) === courseItem.id);
        return { ...courseItem, favourite: isFavourite, favouriteId: isFavouriteId[0]?.id };
      });
      this.courses = updatedCourse
    })
  }

  addToWishlist() {
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.id;
    this.courseService.addToWishlist(client_Id, course_Id)
      .subscribe(results => {
        this.toastr.success("Course added successfully to Wishlist")
      });
  }

  courseId(id: any) {
    this.id = id;
    let client_Id = this.cryptojsService.decryptLocalUserId()
    let course_Id = this.id;
    this.courseService.addToWishlist(client_Id, course_Id)
      .subscribe(results => {
        this.toastr.success("Course added successfully to Wishlist")
      });
  }

  changeCategory(event: any) {
    if (event.target.value != '' || event.target.value != undefined || event.target.value != null) {
      this.courseService.ViewMainSortCourse(event.target.value).subscribe((resMainSort: any) => {
        if (resMainSort.items.length > 0) {
          this.courses = resMainSort.items
          this.courses = this.courses.map((item: any) => {
            return {
              ...item,
              star: Math.round(item.star), 
            };
          });
        } else {
          this.courses = []
        }
      })
    } else {
      this.getAllCourses()
    }
  }

  onActivate() {
    this.indexService.onActivate()
  }

  toggleHeart(courseBookmark: any, courseId: any, favouriteId: any) {
    if (courseBookmark) {
      this.courseService.deleteToWishlist(favouriteId).subscribe((res: any) => {
        this.getAllCourses()
      })
    } else {
      let client_Id = this.cryptojsService.decryptLocalUserId()
      this.courseService.addToWishlist(client_Id, courseId).subscribe((res: any) => {
        this.getAllCourses()
      })
    }
  }

  setDefaultImage(event: Event): void {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = '../../../../assets/defaultimg/defaultMainImg.png';
  }
}

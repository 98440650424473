import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Alert } from '../modals/alert';


@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.id === id));
    }

    success(message: string, options?: any) {
        Swal.fire({
            icon: 'success',
            title: 'Status',
            text: message,
            timer: 2000,
            showConfirmButton: false
        })
    }

    error(message: string, options?: any) {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message,
            showConfirmButton: true,
            customClass: {
                popup: 'swal-front'
            }
        })
    }

    info(message: string, options?: any) {
        Swal.fire({
            icon: 'info',
            title: 'Status',
            text: message,
            timer: 2000,
            showConfirmButton: false
        })
    }

    warn(message: string, options?: any) {
        Swal.fire({
            icon: 'warning',
            title: 'Status',
            text: message,
            timer: 2000,
            showConfirmButton: false
        })
    }

    warning(message: string, options?: any) {
        Swal.fire({
            icon: 'warning',
            title: 'Status',
            text: message,
            timer: 2000,
            showConfirmButton: false
        })
    }

    notification(title: string, message: string) {
        Swal.fire({
            position: 'top-end',
            title: title,
            text: message,
            backdrop: false,
            showConfirmButton: false,
            timer: 10000
        })
    }

    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}

interface ICallback {
    (status: boolean): void;
}
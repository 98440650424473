<section class="jarallax position-relative pt-12 pt-md-8 pt-lg-12 pt-xl-12 mt-n12 rts-section-gap" data-jarallax data-speed=".8">
  <div class="container-fluid p-0">
    <div class="rts-banner-five bg_image">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2">
            <div class="rts-banner-five-content-wrapper pb--150">

              <h1 class="title-m-5">
                <span>UNLOCK YOUR POTENTIAL</span> <br>
                <div class="d-flex  gap-4">
                  <h1 class="with-span">WITH</h1>
                  <img src="assets/images/antarkar.sir (1).png" alt="banner-image">
                </div>
              </h1>
              <div class="banner-btn-author-wrapper">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <a routerLink="/courses" class="rts-btn btn-primary-white radious-0">My Course</a>
                  </div>
                  <div class="col-12 col-md-4 p-0">
                    <div class="rts-wrapper-stars-area">
                      <h5 class="title" style="white-space: nowrap;">4.5 <span> Instructor Rating</span> </h5>
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star"></i>
                      <i class="fa-sharp fa-solid fa-star-half-stroke"></i>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 p-0">
                    <p style="width: 310px;" class="disc">
                      We are passionate about education and
                      dedicated to providing high-quality
                      learning for aspiring students
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 order-xl-2 order-lg-2 order-md-1 order-sm-1 order-1  justify-content-xl-end justify-content-md-center justify-content-sm-center d-flex align-items-end">

            <div class="banner-five-thumbnail">
              <img src="assets/images/Banner-image.png" alt="banner-imagfe">
            </div>

          </div>
        </div>
      </div>
      <div class="banner-absolute-wrapper">
        <!-- <div class="sm-image-wrapper">
          <div class="images-wrap">
            <img src="assets/images/banner/shape/06.png" alt="banner">
            <img class="two" src="assets/images/banner/shape/07.png" alt="banner">
            <img class="three" src="assets/images/banner/shape/08.png" alt="banner">
          </div>
          <div class="info">
            <h6 class="title">2k students</h6>
            <span>Joint our online Class</span>
          </div>
        </div> -->
        <div class="review-thumb">
          <div class="review-single two">
            <img src="assets/images/banner/08.png" alt="banner">
            <div class="info-right">
              <h6 class="title">100+
              </h6>
              <span>Online Course</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fun-facts-area-4 mt-dec-fun-f ">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="fun-facts-main-wrapper-1 style-two">
              <div class="single-fun-facts">
                <div class="icon">
                  <img src="assets/images/fun-facts/icon/02.svg" alt="icon">
                </div>
                <h5 class="title"><span class="counter">26</span></h5>
                <span class="enr">Years of teaching experience</span>
              </div>
              <div class="single-fun-facts">
                <div class="icon">
                  <img src="assets/images/fun-facts/icon/01.svg" alt="icon">
                </div>
                <h5 class="title"><span class="counter">2103+</span></h5>
                <span class="enr">No of students taught </span>
              </div>
              <div class="single-fun-facts">
                <div class="icon">
                  <img src="assets/images/fun-facts/icon/03.svg" alt="icon">
                </div>
                <h5 class="title"><span class="counter">1 In 5</span></h5>
                <span class="enr">Students got ranks in JEE Advanced</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="jarallax position-relative pt-12 pt-md-8 pt-lg-12 pt-xl-12 mt-n12" data-jarallax data-speed=".8">
  <div class="container-fluid">
    <div class="rts-about-area-five rts-section-gapBottom">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">

            <div class="about-left-style-five">
              <div class="title-wrapper">
                <h2 class="title-stroke">Instructor</h2>
              </div>
              <p class="disc mb--30">
                Prof. <span style="color: #131313; font-weight: 600;">Vinayak Antarkar</span> has completed
                his B. Tech. in Civil Engineering from IIT, Bombay. He has taught various subjects like Std.
                XI and XII Mathematics, Engineering Mechanics and Mathematics for IIT-JEE, AIEEE, JEE Main
                and MHT- CET.
                He has been teaching Mathematics for IIT-JEE since 1999.

              </p>
              <p class="disc mb--30">
                Prof. <span style="color: #131313; font-weight: 600;">Vinayak Antarkar</span> , B.Tech., IIT
                Bombay; has 28 years of experience of teaching mathematics and 25 years of coaching
                mathematics for JEE Advanced and other National and State level Engineering Entrance Exams.
              </p>
              <div class="call-sign-area-start">
                <div class="call-btn-area">
                  <div class="icon">
                    <a [href]="getWhatsAppLink()" target="_blank"> <i class="fa-brands fa-whatsapp"></i></a>
                  </div>
                  <div style="font-size: 20px;" class="information">
                    <a [href]="getWhatsAppLink()" target="_blank"> Connect with me</a>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div class="col-lg-6 pl--50 pl_md--15 pl_sm--10 mt_md--50  mt_sm--50">

            <div class="about-thumbnail-right-5">

              <img src="assets/images/Anarkar-sir-profile-photo.jpg" alt="about-area">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="">
  <div class="container-fluid">
    <div class="course-area-start rts-section-gap bg-category-2">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="section-title-w-style-center">
              <h2 class="title">Educational Programmers for Beginners</h2>
              <p>Strengthen your mathematical foundation and develop the advanced problem-solving skills</p>
            </div>
          </div>
        </div>
        <div class="row mt--20 g-5" *ngIf="courses.length > 0">
          <div class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">

            <div class="single-course-style-three">
              <a class="thumbnail c-pointer">
                <img routerLink="/courses/course-details/{{courseDisplay.id}}"  [src]="apiURL + courseDisplay.bannerImg" alt="course">
                <!-- <div class="heart-icon"  (click)="toggleHeart()" [ngClass]="{'active': isHeartActive}">
                  <i class="fa fa-heart"></i>
                </div> -->
                <!-- <div class="tag-thumb">
                  <span>{{courseDisplay.skillLevel ? courseDisplay.skillLevel:'NA'}}</span>
                </div> -->
              </a>
              <div class="body-area">
                <div class="course-top">
                  <div class="tags">Best Seller</div>
                  <div class="price text-decoration-line-through"> 10000/-</div>

                  <div class="price"> &#8377; {{courseDisplay.offer_Price ? courseDisplay.offer_Price : 'NA'}}</div>
                </div>
                <a routerLink="/courses/course-details/{{courseDisplay.id}}">
                  <h5 class="title">{{courseDisplay.name}}</h5>
                </a>
                <div class="teacher-stars">
                  <div class="teacher"><span style="font-size: 18px;">Prof {{courseDisplay.authorName ?
                      courseDisplay.authorName :'NA'}}</span></div>
                  <ul class="stars">
                    <li class="span">4.5</li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star"></i></li>
                    <li><i class="fa-sharp fa-solid fa-star-half-stroke"></i></li>

                  </ul>
                </div>
                <div class="leasson-students">
                  <div class="lesson">
                    <i class="fa-light fa-calendar-lines-pen"></i>
                    <span>{{courseDisplay?.lessonsCount}} Lessons</span>
                  </div>
                  <div class="students">
                    <i class="fa-light fa-users"></i>
                    <span>Online Learning</span>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>
        <div  class="row mt--20 g-5" style="display: flex; justify-content: center;" *ngIf="courses.length == 0">
          <div class="single-course-style-three">
            <div class="row">
              <img src="\assets\images\course\Course-not-Found.jpg" alt="">
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</section>


<app-all-course-secton></app-all-course-secton>

<app-students-feedback></app-students-feedback>


<section class="jarallax position-relative pt-12 pt-md-8 pt-lg-12 pt-xl-12 mt-n12" data-jarallax data-speed=".8">
  <div class="container-fluid">
    <div class="rts-contact-area rts-section-gap bg-category-2">
      <div class="container">
        <div class="row ">
          <div class="col-lg-6 d-flex align-items-start">
            <div class="contact-thumbnail-img ">
              <img src="assets/images/Enquiry-fom.jpg" alt="contact">
            </div>
          </div>
          <div class="col-lg-6 pl--70 pl_sm--15 pl_md--15 mt_md--50 mt_sm--50">
            <!-- contact- area right template -->
            <div class="contact-area-right-5">
              <h2 class="title">Feel free to reach out to us if you have any questions.</h2>
              <form [formGroup]="contactUsForm" class="contact-form-1" (ngSubmit)="contactUs()">
                <div class="single-input">
                  <label for="name">Your Name<span class="text-danger">*</span></label>
                  <input type="text" id="name" placeholder="Enter Your Name" formControlName="name">
                  <div class="text-danger"
                    *ngIf="contactUsForm.controls['name'].invalid && contactUsForm.controls['name'].touched">
                    Name is required
                  </div>
                </div>
                <div class="single-input">
                  <label for="email">Enter your email<span class="text-danger">*</span></label>
                  <input type="text" id="email" placeholder="info@gmail.com" formControlName="email">
                  <div *ngIf="contactUsForm.get('email')?.invalid && contactUsForm.get('email')?.touched">
                    <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.required">Email is
                      required.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('email')?.errors?.pattern">Email must contain
                      '&#64;' and end with '.com'.</span>
                  </div>
                </div>

                <div class="single-input">
                  <label for="number">Your Phone Number<span class="text-danger">*</span></label>
                  <input type="text" id="number" placeholder="Enter Phone Number" formControlName="phone_Number">
                  <div *ngIf="contactUsForm.get('phone_Number')?.invalid && contactUsForm.get('phone_Number')?.touched">
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.required">Phone Number is
                      required.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.pattern">Only numbers
                      are allowed.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.maxlength">Maximum
                      length is 10.</span>
                    <span class="text-danger" *ngIf="contactUsForm.get('phone_Number')?.errors?.minlength">Manimum
                      length is 10.</span>
                  </div>
                </div>
                <div class="single-input">
                  <label for="message">Message<span class="text-danger">*</span></label>
                  <textarea id="message" placeholder="Enter your message" formControlName="message"></textarea>
                  <div class="text-danger"
                    *ngIf="contactUsForm.controls['message'].invalid && contactUsForm.controls['message'].touched">
                    Message is required
                  </div>
                </div>
                <button style="font-size: medium;" type="submit" class="rts-btn btn-primary radious-0">Send
                  Message</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal modal-dialog-scrollable" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document" style="max-width: 650px;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"><b>डॉ. ॐकार हरी माळी</b> </h5>
        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close" (click)="closePopup()">
        </button>
      </div>
      <div class="modal-body">
        <p>“जीवनात ठरवलेले ध्येय साध्य करण्यासाठी कौशल्य आणि संयम असावा लागतो. या दोहोंचा संगम करून त्याला अपार मेहनतीची
          साथ देत उद्योग क्षेत्रात एक यशस्वी मराठी व्यावसायिक म्हणून ज्यांनी आपल्या कक्षा रुंदावल्या असे प्रोत्साहनात्मक
          व्यक्तिमत्व म्हणजे ठाणे येथील डॉ. ओमकार हरी माळी होय.
          ठाणे येथे जन्मलेल्या-वाढलेल्या डॉ. ॐकार हरी माळी यांनी आपले उच्चशिक्षण लंडन येथून पूर्ण केले. लंडन येथील
          (IHMES International Business School) ‘एमबीए’ केल्यानंतर त्यांनी ‘इंटरनॅशनल बिझनेस’ या विषयातून डॉक्टरेट पदवी
          प्राप्त केली. उच्चविद्याविभूषित असूनही परदेशातच न थांबता ते आपल्या भूमीत परतले. मात्र महाराष्ट्रात येताना
          त्यांनी एकच ध्येय उराशी बाळगले होते.
          ते म्हणजे “आपल्या महाराष्ट्रातील नागरिकांना स्वतःचा व्यवसाय करण्यासाठी प्रोत्साहित करणं.” इम्पोर्ट एक्स्पोर्ट
          उद्योगात आपला किर्तीदायक जम बसवलेले डॉ. ॐकार हरी माळी हे मेडिकल कोलॅबोरेटर म्हणून आज जगातील दुबई, बांगलादेश,
          उझबेकिस्तान, कझाकिस्तान आणि युक्रेन यासारख्या इतर एकूण सात देशांमध्ये उद्योग करत आहेत. तर महाराष्ट्रातील विविध
          शहरांमध्ये ते ‘ई-कॉमर्स’ क्षेत्रात कार्यरत आहेत. कॉर्पोरेट जगतासह नाशिक जिल्ह्यातील शेतकऱ्यांच्या शेतमालाला
          योग्य भाव मिळावा यासाठी आपल्या इम्पोर्ट एक्सपोर्टच्या व्यवसाय व ज्ञानाची साथ देत ते शेतकऱ्यांना सहकार्य करत
          आहेत. इतकेच नव्हे तर ‘इव्हेन्ट मॅनेजमेंट’ क्षेत्रातही ते यशस्वी उद्योजक म्हणून कार्यरत आहेत. केवळ एका
          व्यवसायापुरतेच मर्यादित न राहता निरनिराळ्या क्षेत्रात आपल्या उद्योजकतेच्या कक्षा रुंदावून एक मराठी यशस्वी
          उद्योजक म्हणून डॉ. ॐकार हरी माळी यांनी आपला ठसा उमटवलेला आहे. मात्र इतक्या साऱ्या वर्षांचा विविध व्यवसाय
          क्षेत्रातील अनुभव त्यांनी स्वतःजवळच ठेवला नाही. तर त्याचा उपयोग हा मराठी तरुण तरुणींना उद्योगासाठी आवश्यक
          प्रशिक्षण आणि व्यवसाय यांचे मार्गदर्शन करून महाराष्ट्राच्या सर्वांगीण वाढीसाठी करण्याचे ठरवले आणि याच
          उद्देशाने डॉ. ॐकार हरी माळी यांनी १ मे २०२० रोजी ‘उद्यमी महाराष्ट्र’ या उपक्रमाची निर्मिती केली.
          राष्ट्रवादी काँग्रेस पक्ष प्रमुख आदरणीय शरदचंद्रजी पवार साहेबांच्या आदेशानुसार आणि मार्गदर्शनाखाली राष्ट्रवादी
          युवक काँग्रेस महाराष्ट्र प्रदेश सरचिटणीस व रोजगार/स्वयंरोजगार महाराष्ट्र प्रदेशाध्यक्ष पदावर आपली सक्रिय
          भूमिका बजावणारे डॉ. ओमकार हरी माळी हे ‘मराठी माणूस व्यवसाय करू शकत नाही’ या प्रचलित वाक्याला खोटे ठरवून
          ‘उद्यमी महाराष्ट्र’ या मोहिमेअंतर्गत सध्या अनेक यशस्वी मराठी उद्योजक घडवत आहेत. पुढच्या वर्षभरात ५०००
          उद्यमींना प्रशिक्षण देऊन त्यांना व्यवसायाच्या मुख्य प्रवाहात आणण्याचे ध्येय डॉ. ओमकार हरी माळी यांनी उराशी
          बाळगलेले आहे.
        </p>
      </div>

    </div>
  </div>
</div>
<div class="bubbles_wrap">
  <div class="bubble x1"></div>
  <div class="bubble x2"></div>
  <div class="bubble x3"></div>
  <div class="bubble x4"></div>
  <div class="bubble x5"></div>
  <div class="bubble x6"></div>
  <div class="bubble x7"></div>
  <div class="bubble x8"></div>
  <div class="bubble x9"></div>
  <div class="bubble x10"></div>
</div>
<a (click)="onActivate()" class="scroll-top" id="scroll-to-top">
  <i class="fa fa-arrow-up" aria-hidden="true"></i>
</a>
<a (click)="handleClick()" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#admission-form-modal" [hidden]="true" id="openAdmissionForm">Admission Form</a>



<div class="modal fade" id="admission-form-modal" tabindex="-1" aria-labelledby="admissionExampleModalLabel"
aria-hidden="true" data-bs-backdrop="static">
<div class="modal-dialog modal-xl">
  <div class="modal-content">
    <span class="close-btn btn-close" data-bs-dismiss="modal" aria-label="Close" id="admissionFormCloseId"></span>
    <h2>Admission Form</h2>
    <form [formGroup]="admissionForm" (ngSubmit)="onSubmitAdmission()">
      <div class="form-container">
        <div class="form-group">
          <label for="name">Name<span class="text-danger">*</span></label>
          <input class="addmision-border" type="text" id="name" formControlName="name" placeholder="Enter your name">
          <span class="text-danger" *ngIf="admissionForm.get('name')?.invalid && admissionForm.get('name')?.touched">
            Please enter a valid name (2-50 characters).
          </span>
        </div>

        <div class="form-group">
          <label for="father_name">Father's Name<span class="text-danger">*</span></label>
          <input class="addmision-border" type="text" id="father_name" formControlName="fathername" placeholder="Enter your father's name">
          <span class="text-danger"
            *ngIf="admissionForm.get('fathername')?.invalid && admissionForm.get('fathername')?.touched">
            Please enter a valid father's name (2-50 characters).
          </span>
        </div>

        <div class="form-group">
          <label for="surname">Surname<span class="text-danger">*</span></label>
          <input class="addmision-border" type="text" id="surname" formControlName="surname" placeholder="Enter your surname">
          <span class="text-danger"
            *ngIf="admissionForm.get('surname')?.invalid && admissionForm.get('surname')?.touched">
            Please enter a valid surname (2-50 characters).
          </span>
        </div>

        <div class="form-group">
          <label for="birth_date">Birth Date<span class="text-danger">*</span></label>
          <input class="addmision-border" type="date" id="birth_date" formControlName="birthdate">
          <span class="text-danger"
            *ngIf="admissionForm.get('birthdate')?.invalid && admissionForm.get('birthdate')?.touched">
            Please enter a valid birth date.
          </span>
        </div>

        <div class="form-group">
          <label for="contact_no">Guardian Mobile No<span class="text-danger">*</span></label>
          <input class="addmision-border" type="tel" id="contact_no" formControlName="guardianmobileno"
            placeholder="Enter your contact number">
          <span class="text-danger"
            *ngIf="admissionForm.get('guardianmobileno')?.invalid && admissionForm.get('guardianmobileno')?.touched">
            Please enter a valid 10-digit mobile number.
          </span>
        </div>

        <div class="form-group">
          <label for="jee_year">Year of Appearing for JEE<span class="text-danger">*</span></label>
          <select class="addmision-border" style="padding: 12px !important; font-size: 17px;" id="jee_year" formControlName="yearofappearingforjee">
            <option value="">Select year</option>
            <option value="2000">2000</option>
            <option value="2001">2001</option>
            <option value="2002">2002</option>
            <option value="2003">2003</option>
            <option value="2004">2004</option>
            <option value="2005">2005</option>
            <option value="2006">2006</option>
            <option value="2007">2007</option>
            <option value="2008">2008</option>
            <option value="2009">2009</option>
            <option value="2010">2010</option>
            <option value="2011">2011</option>
            <option value="2012">2012</option>
            <option value="2013">2013</option>
            <option value="2014">2014</option>
            <option value="2015">2015</option>
            <option value="2016">2016</option>
            <option value="2017">2017</option>
            <option value="2018">2018</option>
            <option value="2019">2019</option>
            <option value="2020">2020</option>
            <option value="2021">2021</option>
            <option value="2022">2022</option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
            <option value="2026">2026</option>
            <option value="2027">2027</option>
            <option value="2028">2028</option>
            <option value="2029">2029</option>
            <option value="2030">2030</option>
          </select>
          <span class="text-danger"
            *ngIf="admissionForm.get('yearofappearingforjee')?.invalid && admissionForm.get('yearofappearingforjee')?.touched">
            Please select a valid year.
          </span>
        </div>

        <div class="form-group">
          <label for="student_type">Student Type (New/Renewal)<span class="text-danger">*</span></label>
          <select class="addmision-border" style="padding: 12px !important; font-size: 17px;" id="student_type" formControlName="studenttype">
            <option value="">Select</option>
            <option value="new">New</option>
            <option value="renewal">Renewal</option>
          </select>
          <span class="text-danger"
            *ngIf="admissionForm.get('studenttype')?.invalid && admissionForm.get('studenttype')?.touched">
            Please select a student type.
          </span>
        </div>

        <div class="form-group">
          <label for="course_source">How did you come to know about this course?<span
              class="text-danger">*</span></label>
          <input class="addmision-border" type="text" id="course_source" formControlName="howdidyoucometoknowaboutthiscourse"
            placeholder="Enter how you found out about the course">
          <span class="text-danger"
            *ngIf="admissionForm.get('howdidyoucometoknowaboutthiscourse')?.invalid && admissionForm.get('howdidyoucometoknowaboutthiscourse')?.touched">
            Please provide how you found out about the course (min. 3 characters).
          </span>
        </div>
      </div>
      <div class="float-end">
        <button type="submit" class="rts-btn btn-primary right-align">Submit</button>

      </div>

   
    </form>



  </div>
</div>

</div>
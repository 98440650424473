<div class="mb-8 p-3" id="accordionCurriculum">
  <ng-container *ngIf="lessondata">
  <ng-container *ngFor="let lesson of lessondata[0].lessons let i=index">
    <!-- <div class="border rounded shadow mb-6 overflow-hidden">
      <div class="d-flex align-items-center" id="curriculumheading{{i}}">
        <h5 class="mb-0 w-100">
          <button
            class="d-flex align-items-center p-5 min-height-60 text-dark fw-medium collapse-accordion-toggle line-height-one"
            type="button" data-bs-toggle="collapse" attr.data-bs-target="#Curriculumcollapse{{i}}" aria-expanded="false"
            attr.aria-controls="Curriculumcollapse{{i}}">
            <span class="me-4 text-dark d-flex">
          
              <i class="fas fa-minus"></i>
              <i class="fas fa-plus"></i>
            </span>
            {{lesson.lessonName}}
          </button>
        </h5>
      </div>
      <div id="Curriculumcollapse{{i}}" class="collapse" attr.aria-labelledby="curriculumheading{{i}}" data-parent="#accordionCurriculum">
        <app-topic [lessonData]="lesson" (newVideoEvent)="playVideo($event)"></app-topic>
      </div>
    </div> -->

    <div class="border rounded shadow mb-3 overflow-hidden" data-aos="fade-up">
      <div class="d-flex align-items-center" id="curriculumheading{{i}}">
          <h5 class="mb-0 w-100" style="background-color: #f78900 !important;">
              <button
                  class="d-flex align-items-center text-left p-4 min-height-60  fw-medium collapse-accordion-toggle line-height-one"
                  type="button" data-bs-toggle="collapse"
                  attr.data-bs-target="#Curriculumcollapse{{i}}" aria-expanded="false"
                  attr.aria-controls="Curriculumcollapse{{i}}" style="color: #fff;font-weight: 600 !important;">
                  <span class="me-3 text-dark d-flex">
                      <!-- Icon -->
                      <i class="fas fa-minus" style="color: #fff;font-weight: 600 !important;"></i>
                      <i class="fas fa-plus" style="color: #fff;font-weight: 600 !important;"></i>    
                  </span>
                  {{lesson.lessonName}}
              </button>
          </h5>
      </div>
      <div id="Curriculumcollapse{{i}}" class="collapse" attr.aria-labelledby="curriculumheading{{i}}" data-parent="#accordionCurriculum">
        <app-topic [lessonData]="lesson" (newVideoEvent)="playVideo($event)"></app-topic>
      </div>
    </div>
  </ng-container>
</ng-container>
</div>

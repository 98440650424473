<ng-container *ngFor="let v of lessonData.videoRow">
  <!-- <div class="border-top px-5 py-4 min-height-60 d-md-flex align-items-center"> -->
    <!-- <div class="d-flex align-items-center me-auto mb-4 mb-md-0">
      <div class="text-secondary d-flex">
        <i class="far fa-file-alt fa-gray fa-lg"></i>
      </div>
      <div class="ms-4">
        {{v.videoName }}
      </div>
    </div> -->

    <!-- <div class="d-flex align-items-center overflow-auto overflow-md-visible flex-shrink-all"> -->
      <!-- <div class="badge text-dark-70 bg-orange-40 me-5 font-size-sm fw-normal py-2">3 question</div> -->

      <!-- |timeConvertor -->
      <!-- <div class="badge btn-blue-soft me-5 font-size-sm fw-normal py-2">{{v.video_Duration }}</div> -->
      <!-- <button (click)='PlayVideo(v.id)' class="badge btn-blue-soft me-5 font-size-sm fw-normal py-2">Play</button> -->
      <!-- <a href="https://www.youtube.com/{{v.videoURL}}"  class="text-secondary d-flex" (click)='PlayVideo(v.id)' target="_blank"> -->
      <!-- <a class="text-secondary d-flex" (click)='PlayVideo(v.id)' target="_blank">
        <i class="fas fa-play" style="cursor: pointer;"></i>
      </a> -->
    <!-- </div> -->
  <!-- </div> -->
  <div class="p-3 border-top">
    <div class="min-height-70 d-md-flex align-items-center">
        <div class="d-flex align-items-center me-auto mb-4 mb-md-0">
            <div class="text-secondary d-flex">
                <img width="30%" class="d-block sk-thumbnail rounded pb-0"  src="{{v.thumbnail}}">


                <div class="ms-2 w-100">
                    {{v.videoName }}
                    <br>
                    <div class="badge btn-blue-soft me-5 font-size-sm fw-normal py-2">{{(v.video_Duration).slice(0,8) }}</div>
                </div>
            </div>
        </div>

        <div class="d-flex align-items-center overflow-auto overflow-md-visible flex-shrink-all">
            <a class="text-dark d-flex" (click)='PlayVideo(v.id)'>              
                <i *ngIf="this.url[1] == 'lesson-details' && this.enabled"  class="fas fa-play" style="cursor: pointer;"></i>
            </a>
        </div>
    </div>
</div>
</ng-container>
